import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  get accessToken(): string | null {
    return sessionStorage.getItem('access_token');
  }

  set accessToken(token: string | null) {
    if (token === null) {
      this.clearToken();
    } else {
      sessionStorage.setItem('access_token', token);
    }
  }

  clearToken(): void {
    sessionStorage.removeItem('access_token');
  }
}
