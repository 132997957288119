import {Injectable} from '@angular/core';
import {IResponse, IToken} from '@user/core/interfaces';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {TokenService} from '@user/core/services/token.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ApiBase} from '@user/core/services/api.service';
import {apiEndpoints} from '@user/config/global-vars';

@Injectable({
  providedIn: 'root'
})
export class SocialAuthService {
  constructor(
    private http: HttpClient,
    private apiBase: ApiBase,
    private tokenService: TokenService,
  ) { }

  authFromCallback(callbackUrl: string, params?: HttpParams): Observable<IToken> {
    return this.http
      .get<IResponse<IToken>>(`${this.apiBase.baseDomain}${callbackUrl}`, {params})
      .pipe(map((response) => {
        this.tokenService.accessToken = response.data.token;
        return response.data;
      }));
  }
  sendLinkToMail(query: string, body: { [key : string]: string }): Observable<any> {
    return this.apiBase.post<any>(`${apiEndpoints.mail_auth}${query}`, body)
  }
  verifyTokenMail (token: { [key: string]: string } ): Observable<any> {
    return this.apiBase.post<any>(apiEndpoints.verify_token_mail, token)
  }
}
