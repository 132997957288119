import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-field-segmented',
  template: `
    <div class="box_inner">
      <label *ngFor="let option of to?.options| formlySelectOptions:field| async">
        <p><input type="radio"
                  [name]="to?.name + '_' + to?.data?.randomNumber"
                  [autocomplete]="'none'"
                  [formControl]="formControl"
                  [formlyAttributes]="field"
                  [value]="option.value"
                  [id]="'segmented' + option.value"
        ><span class="radio_segmented">{{option.label}}</span></p>
      </label>
    </div>
  `,
})
export class FormlyFieldSegmentedComponent extends FieldType<FieldTypeConfig> {
}
