<div [id]="customizeSetting?.id" [class]="customizeSetting?.class">
  <div class="auth_call_form--domestic" *ngIf="currentMode === OstiariesAspMethod.DOMESTIC">
    <h4>{{ customizeSetting?.domestic_label || '電話番号を入力する' }}</h4>
    <input type="tel"
           [formControl]="phoneNumberControl"
           minlength="10"
           maxlength="12"
           [placeholder]="customizeSetting?.domestic_placeholder || '0900000000'"
    />

    <div class="auth_call_form--domestic_switch_mode">
      <button (click)="switchMode()"
              *ngIf="setting?.ostiaries_asp_method === OstiariesAspMethod.BOTH">
        {{customizeSetting?.domestic_change_form_text || '日本以外の電話番号をご利用の方はこちら'}}
      </button>
    </div>
    <!--button-->
    <input type="button"
           [disabled]="phoneNumberControl.invalid || isLoading"
           (click)="submit()"
           [value]="customizeSetting?.domestic_button_text || 'ご本人様確認に進む'"
    />
  </div>
  <div class="auth_call_form--overseas" *ngIf="currentMode === OstiariesAspMethod.OVERSEAS">
    <h4>{{ customizeSetting?.overseas_label || '電話番号を入力する' }}</h4>
    <div class="auth_call_form--overseas_select_country">
      <label>
        国・地域
      </label>
      <select [(ngModel)]="selectedCountryCode">
        <option *ngFor="let country of countryCode; let i = index" [ngValue]="country">{{ country.name }}</option>
      </select>
    </div>
    <div class="auth_call_form--overseas_phone_number">
      <label>
        電話番号
      </label>
      <div class="auth_call_form--overseas_phone_number--input_group">
        <input type="text" [value]="selectedCountryCode?.dial_code" readonly>
        <input type="tel"
               minlength="1"
               maxlength="14"
               [formControl]="phoneNumberControl"
               [placeholder]="customizeSetting?.overseas_placeholder || '0900000000'"
        />
      </div>
    </div>
    <div class="auth_call_form--overseas_switch_mode">
      <button (click)="switchMode()"
              *ngIf="setting?.ostiaries_asp_method === OstiariesAspMethod.BOTH">
        {{customizeSetting?.overseas_change_form_text || '日本の電話番号をご利用の方はこちら'}}
      </button>
    </div>
    <!--button-->
    <input type="button"
           [disabled]="phoneNumberControl.invalid || isLoading"
           (click)="submit()"
           [value]="customizeSetting?.overseas_button_text || 'ご本人様確認に進む'"
    />
  </div>

  <p class="error_s" *ngIf="phoneNumberControl.hasError('taken')">
    {{phoneNumberControl.errors?.message}}
  </p>
  <p class="error_s"
     *ngIf="
       phoneNumberControl.hasError('pattern') ||
       phoneNumberControl.hasError('minlength') ||
       phoneNumberControl.hasError('maxlength')
    ">
    有効な電話番号を入力してください。
  </p>
</div>
