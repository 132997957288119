import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as jQuery from 'jquery';
import { catchError } from 'rxjs/operators';
import { IBlockIDPWForm } from '@user/core/interfaces/block';
import { throwError } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorCodeEnum } from '@app-shared/enums';
import { IIDPWSetting } from '@user/core/interfaces/setting/id-pw-setting.interface';
import { IDPWMethod } from '@user/core/enums/id-pw';
import { IDPWService, SecretPathService, TokenService } from '@user/core/services';
import { ActivatedRoute, Router } from '@angular/router';
import { query } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';

declare var window: Window & typeof globalThis & {
  $?: JQueryStatic;
  call_auth_run(tel: string): void;
};

@Component({
  selector: 'lib-id-pw-form',
  templateUrl: './id-pw-form.component.html',
  styleUrls: ['./id-pw-form.component.scss']
})
export class IDPWFormComponent implements OnInit, OnDestroy {
  @Input() setting?: IIDPWSetting;
  @Input() customizeSetting: IBlockIDPWForm | null = null;
  IDPWMethod = IDPWMethod;
  formGroup = new FormGroup ({
    user_id: new FormControl ('', [Validators.required]),
    user_pw: new FormControl ('', [Validators.required]),
  });
  returnUrl?: string;
  messageToUser: string[] = []
  isShowModal = false
  constructor(
     private idpwService: IDPWService,
     private tokenService: TokenService,
     private secretPathService: SecretPathService,
     public router: Router,
     public activatedRoute: ActivatedRoute,
     public translateService: TranslateService
     ) {}

  ngOnInit(): void {
    window.$ = jQuery;
    if (this.setting?.id_pw_method === IDPWMethod.THREE_KEYS) {
      this.formGroup.addControl('organization_code', new FormControl ('', [Validators.required]))
    }
    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.returnUrl = queryParams.return_url || ''
    })

  }

  submit(): void {
    if (this.formGroup.valid) {
      const secretPath = this.secretPathService.isSecretPath() ? this.secretPathService.secretPath : ''
      this.idpwService.verify(this.formGroup.value).pipe(catchError(err => {
        if (err.status === HttpErrorCodeEnum.UNPROCESSABLE_CONTENT) {
          this.isShowModal = true
          this.messageToUser = [this.translateService.instant('ERROR.LOGIN_INFO')]
        }
        return throwError(err);
      })).subscribe(res => {
        this.tokenService.accessToken = res.token;
          if (this.returnUrl && !this.returnUrl.includes('form')) {
            return window.location.assign(this.returnUrl)
          }
          return this.router.navigate([secretPath, 'form']);
      })
    }
  }

  ngOnDestroy(): void {
  }
}
