import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {first, mergeMap} from 'rxjs/operators';
import {CampaignDataService} from '@user/core/services';

@Injectable({
  providedIn: 'root'
})
export class CampaignFunctionGuard implements CanActivate {
  constructor(
    private campaignDataService: CampaignDataService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.campaignDataService.campaignInitialized.pipe(
      first(),
      mergeMap((campaign) => {
        // Redirect to Not Found page if campaign not exist
        if (!campaign) {
          this.router.navigate(['static', 'not-found']);
          return of(false);
        }

        if (campaign.functions && Object.keys(campaign.functions).length > 0) {
          for (const key of Object.keys(campaign.functions)) {
            if (!campaign.functions[key]) {
              this.router.navigate(['static', 'setting-error']);
              return of(false);
            }
          }
        }
        return of(true);
      })
    );
  }
}
