import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, NavigationExtras, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {AuthService, CampaignDataService, SecretCodeService, SecretPathService, TokenService} from '@user/core/services';
import {UserAuthMethodEnum} from '@user/core/enums';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private campaignDataService: CampaignDataService,
    private tokenService: TokenService,
    private authService: AuthService,
    private secretPathService: SecretPathService,
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    let secretPath = '';
    if (
      this.secretPathService.isSecretPath()
      || state.url.startsWith('/' + this.secretPathService.secretPath)
    ) {
      secretPath = this.secretPathService.secretPath;
    }
    return this.campaignDataService.campaignInitialized.pipe(
      mergeMap((campaign) => {
        // Check auth

        if (
          !this.tokenService.accessToken
        ) {
          if ([UserAuthMethodEnum.ONE_TIME, UserAuthMethodEnum.NO_AUTH].includes(campaign.user_auth_method)) {
            // register
            return this.authService.register().pipe(
              map((token) => {
                this.tokenService.accessToken = token.token;
                return true;
              }),
              catchError(() => {
                this.router.navigate([secretPath, '/']);
                return of(false);
              })
            );
          } else if ([UserAuthMethodEnum.SOCIAL_LOGIN, UserAuthMethodEnum.ID_PW].includes(campaign.user_auth_method)) {
            // redirect to auth page and return false
            const returnUrl = state?.url || null;
            if(returnUrl?.includes("/zalo-pay/transfer")){
              return of(true);
            }
            const extras: NavigationExtras = {};
            if (returnUrl) {
              extras.queryParams = { return_url: returnUrl };
            }

            if (sessionStorage.getItem('mail_token')) {
              return of(true);
            }
            this.router.navigate([secretPath, 'auth'], extras);
            return of(false);
          }
        }
        return of(true);
      }),
    );
  }
}
