import { Injectable } from '@angular/core';
import {ApiBase} from '@user/core/services/api.service';
import {apiEndpoints} from '@user/config/global-vars';
import {ICampaign, IResponse} from '@user/core/interfaces';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  constructor(private apiBase: ApiBase) { }

  detail(params?: any): Observable<IResponse<ICampaign>> {
    return this.apiBase.get<IResponse<ICampaign>>(apiEndpoints.campaign_detail, {
      ...params,
    });
  }
}
