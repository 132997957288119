import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  NavigationExtras,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { CampaignTypeEnum, FormUsingTypeEnum } from '@user/core/interfaces';
import { AuthService, CampaignDataService, SecretPathService } from '@user/core/services';
import { combineLatest, Observable, of } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MileageGuard implements CanActivate {
  constructor(
    private router: Router,
    private campaignDataService: CampaignDataService,
    private authService: AuthService,
    private secretPathService: SecretPathService,
  ) {
  }

  redirectAndReturnFalse(urls = ['static', 'not-found'], extras = {}): Observable<boolean> {
    this.router.navigate(urls, extras);
    return of(false);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return combineLatest([
      this.campaignDataService.campaignInitialized,
      this.authService.profile()
    ]).pipe(
      first(),
      mergeMap(([campaign, user]) => {
        if (!campaign) {
          return this.redirectAndReturnFalse();
        }

        if (campaign.campaign_type === CampaignTypeEnum.MILEAGE && campaign?.form_using_type === FormUsingTypeEnum.FORM &&
          user && user.total_apply <= 0) {
          const secretPath = this.secretPathService.isSecretPath() ? this.secretPathService.secretPath : '';
          const returnUrl = state?.url || null;
          const extras: NavigationExtras = {};
          if (returnUrl) {
            extras.queryParams = { return_url: returnUrl };
          }
          return this.redirectAndReturnFalse([secretPath, 'form'], extras);
        }

        return of(campaign.campaign_type === CampaignTypeEnum.MILEAGE);
      })
    );
  }
}
