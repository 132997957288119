import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public isOpenNotification$: Observable<boolean>;
  public message = new BehaviorSubject<any>([]);
  public isReloadPage = new BehaviorSubject<any>(true);

  private isOpenNotificationSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    this.isOpenNotification$ = this.isOpenNotificationSubject.asObservable();
  }

  set isOpenNotification(value: boolean) {
    this.isOpenNotificationSubject.next(value);
  }
}
