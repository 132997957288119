import { Component } from '@angular/core';
import { FieldTypeEnum } from '@app-shared/enums';
import { FieldWrapper } from '@ngx-formly/core';
import { isLanguageVietNam } from '@app-shared/config/language';

@Component({
  selector: 'app-formly-box-single',
  template: `
    <div
      [ngClass]="{
      'radio_box': to?.data?.type == FieldTypeEnum.RADIO
      }"
      [className]="'box ' + (field?.templateOptions?.data?.html_class || '')"
      [id]="field?.templateOptions?.data?.html_id || ''"
    >
      <div class="db-f-box-above-html" [innerHTML]="field?.templateOptions?.data?.display_before | safeHtml"></div>

      <div class="element_flex">
        <span class="option" *ngIf="!!!to?.data?.is_required && !isLanguageVietNam">{{'OPTION'|translate}}</span>
        <span class="required" *ngIf="!!to?.data?.is_required && !isLanguageVietNam">{{'REQUIRED'|translate}}</span>
        <h4 class="title_formly">{{to?.data?.title}}</h4>
        <span class="required" *ngIf="!!to?.data?.is_required && isLanguageVietNam">*</span>
      </div>
      <p class="subtitle">{{to?.data?.sub_title}}</p>
      <div
        *ngIf="to?.data?.html"
        [class]="(to?.data?.html.class || '')"
        [id]="(to?.data?.html.id || '')"
        [style.background-color]="to?.data?.html.background_color">
        <div [innerHTML]="(to?.data?.html.content || '') | safeHtml"></div>
      </div>
      <ng-container #fieldComponent></ng-container>
      <p class="help">{{to?.data?.annotation}}</p>
      <lib-control-errors
        *ngIf="![FieldTypeEnum.FILE].includes(to?.data?.type)"
        [nameForm]="to?.data?.type === 'address' ? field.type :(to?.data?.type | fieldTypePipe)"
        [fieldName]="field?.key">
      </lib-control-errors>
      <div class="db-f-box-below-html" [innerHTML]="field?.templateOptions?.data?.display_after | safeHtml"></div>
    </div>
  `,
})
export class BoxSingleFormlyWrapperComponent extends FieldWrapper {
  isLanguageVietNam = isLanguageVietNam();
  FieldTypeEnum = FieldTypeEnum;
}
