import { Injectable } from '@angular/core';
import { GivePointMethodTypeEnum } from '@app-shared/enums';
import { ICampaign } from '@user/core/interfaces';
import { StorageService } from '@user/core/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class MileageSecretCodeService {
  constructor(
    private storageService: StorageService,
  ) { }

  parseCodeFromLocation(campaign: ICampaign): void {
    const searchParams = location.search && location.search.length > 1 ? location.search.substring(1) : '';
    let queryParams: { [key: string]: string } = {};
    if (searchParams) {
      queryParams = JSON.parse('{"'
        + searchParams.replace(/&/g, '","').replace(/=/g, '":"')
        + '"}', (key, value) => key === '' ? value : decodeURIComponent(value)
      );

      if (campaign?.settings?.common?.mileage_give_point_method === GivePointMethodTypeEnum.SERIAL && queryParams?.s) {
        this.storageService.save('mileage_code', queryParams?.s);
      } else if (localStorage.getItem('mileage_code')) {
        localStorage.removeItem('mileage_code')
      }

      if (campaign?.settings?.common?.mileage_give_point_method === GivePointMethodTypeEnum.JAN_CODE && queryParams?.p) {
        this.storageService.save('mileage_code', queryParams?.p);
      }
    }
  }
}
