import { FormlyFieldInputComponent } from '../templates/field-input.component';
import { FormlyFieldRadioComponent } from '../templates/field-radio.component';
import { FormlyFieldCheckboxComponent } from '../templates/field-checkbox.component';
import { FormlyFieldMultipleInputComponent } from '../templates/field-multiple-input.component';
import { FormlyFieldSelectComponent } from '../templates/field-select.component';
import { FormlyFieldTextareaComponent } from '../templates/field-textarea.component';
import { FormlyFieldFileComponent } from '../templates/field-file.component';
import { FormlyFieldSegmentedComponent } from '../templates/field-segmented.component';
import { FormlyFieldTargetProductComponent } from '../templates/file-ocr/field-target-product.component';
import { FormlyFieldFileOcrComponent } from '../templates/file-ocr/field-file-ocr.component';
import { FormlyFieldInputOCRComponent } from '../templates/file-ocr/field-input-ocr.component';
import { FormlyFieldEmailComponent } from '../templates/field-mail.component';

export const TypesComponent = [
  {
    name: 'input',
    component: FormlyFieldInputComponent
  },
  {
    name: 'multiple-input-ocr',
    component: FormlyFieldInputOCRComponent
  },
  {
    name: 'radio',
    component: FormlyFieldRadioComponent
  },
  {
    name: 'segmented',
    component: FormlyFieldSegmentedComponent
  },
  {
    name: 'checkbox',
    component: FormlyFieldCheckboxComponent
  },
  {
    name: 'select',
    component: FormlyFieldSelectComponent
  },
  {
    name: 'multiple_input',
    component: FormlyFieldMultipleInputComponent
  },
  {
    name: 'textarea',
    component: FormlyFieldTextareaComponent
  },
  {
    name: 'file',
    component: FormlyFieldFileComponent
  },
  {
    name: 'target_product',
    component: FormlyFieldTargetProductComponent
  },
  {
    name: 'file-ocr',
    component: FormlyFieldFileOcrComponent
  },
  {
    name: 'email',
    component: FormlyFieldEmailComponent
  }
];
