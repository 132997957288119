export * from './block-button.interface';
export * from './block-html.interface';
export * from './block-image.interface';
export * from './block-input-password.interface';
export * from './block-gift.interface';
export * from './block-ostiaries-asp-form.interface';
export * from './block.interface';
export * from './block-lottery-result.interface';
export * from './block-display-point.interface';
export * from './block-request-point-serial.interface';
export * from './block-request-point-receipt-declaration.interface';
export * from './block-request-point-receipt-approval.interface';
export * from './block-request-point-jan-code.interface';
export * from './block-course.interface';
export * from './block-inactivate.interface';
export * from './block-id-pw-form.interface';
