import { Injectable } from '@angular/core';
import { ApiBase } from '@user/core/services/api.service';
import { Observable } from 'rxjs';
import { apiEndpoints } from '@user/config/global-vars';
import { IHistory } from '@user/core/interfaces/mileage';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(
    private apiBase: ApiBase,
  ) {
  }


  getApplyHistory(params: {}): Observable<IHistory> {
    return this.apiBase.get(apiEndpoints.history_apply, {
      per_page: 5,
      ...params
    });
  }

  getPointRequestHistory(params: {}): Observable<IHistory> {
    return this.apiBase.get(apiEndpoints.history_point_request, {
      per_page: 5,
      with: 'serial',
      ...params
    });
  }
}
