import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CampaignDataService, SecretPathService } from '@user/core/services';

@Component({
  selector: 'app-end-page',
  templateUrl: './lottery-end-page.component.html',
  styleUrls: ['./lottery-end-page.component.scss']
})
export class LotteryEndPageComponent implements OnInit {
  customMessage: string | undefined = '';

  constructor(
    private campaignDataService: CampaignDataService,
    private router: Router,
    private secretPathService: SecretPathService
  ) {}

  ngOnInit(): void {
    this.campaignDataService.campaignInitialized.subscribe((campaign) => {
      const isEndLottery =  !!(campaign.settings?.lottery_setting?.campaign_lottery_end_comparison?.less_than_now) && campaign.open_end_comparison.greater_than_or_equal_to_now
      if (!isEndLottery) {
        const secretPath = this.secretPathService.isSecretPath() ? this.secretPathService.secretPath : ''
        this.router.navigate([secretPath])
        return
      }
      this.customMessage = campaign?.settings?.error?.page_lottery_end;
    });
  }

}
