import {Injectable} from '@angular/core';
import {StorageService} from "@user/core/services/storage.service";

type QueryParameter = {
    [key: string]: string | null;
};

@Injectable({
    providedIn: 'root'
})
export class UrlService {
    constructor(
        private storageService: StorageService,
    ) {
    }

    public saveParameters(queryParameters: QueryParameter, hashParameters: string): void {
        this.storageService.save('temporary_save_query_parameters', JSON.stringify(queryParameters));
        this.storageService.save('temporary_save_hash_parameters', hashParameters);
    }

    public getQueryParameters(): string {
        let queryParameters = this.storageService.get('temporary_save_query_parameters');

        if (!queryParameters) {
            queryParameters = window.location.search;
        } else {
            queryParameters = '?' + new URLSearchParams(JSON.parse(queryParameters)).toString();
            this.storageService.remove('temporary_save_query_parameters');
        }

        return queryParameters;
    }

    public getHashParameters(): string {
        let hashParameters = this.storageService.get('temporary_save_hash_parameters');

        if (!hashParameters) {
            hashParameters = window.location.hash;
        } else {
            this.storageService.remove('temporary_save_hash_parameters');
        }

        return hashParameters;
    }
}
