import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { CampaignDataService } from '@user/core/services';
import { ISection } from '@user/core/interfaces';
import { IPageSetting } from '@user/core/interfaces/page-setting.interface';
import * as $ from 'jquery';

@Component({
  selector: 'app-campaign-closed-page',
  templateUrl: './campaign-closed-page.component.html',
  styleUrls: ['./campaign-closed-page.component.scss']
})
export class CampaignClosedPageComponent implements OnInit, OnDestroy {
  isScreenDesktop = true;
  private pageKey = 'closed';
  desktopImage?: string;
  mobileImage?: string;
  sections?: ISection[] = [];

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isScreenDesktop = window.innerWidth > 767;
  }

  constructor(private campaignDataService: CampaignDataService) {
    this.onResize();
  }

  ngOnInit(): void {
    this.campaignDataService.campaignInitialized.subscribe((campaign) => {
      this.applyStyle({
        head: campaign.settings.close?.close_head,
        before_body: campaign.settings.close?.close_before_close_body_tag,
        after_body: campaign.settings.close?.close_after_open_body_tag,
        desktop_image: campaign.settings.close?.close_desktop,
        mobile_image: campaign.settings.close?.close_mobile,
        sections: campaign.settings.close?.close_sections,
      });
    });
  }

  applyStyle(closedCampaignPageSetting?: IPageSetting) {
    const commonBeforeBody = $('body div[data-setting=common_before_close_body_tag]');
    const commonAfterBody = $('body div[data-setting=common_after_open_body_tag]');

    const beforeBody = commonBeforeBody.length ? commonBeforeBody : $('body');
    const afterBody = commonAfterBody.length ? commonAfterBody : $('body');

    if (closedCampaignPageSetting?.head) {
      $('head').append(`<span data-setting="${this.pageKey}">${closedCampaignPageSetting?.head}</span>`);
    }

    if (closedCampaignPageSetting?.after_body) {
      this.campaignDataService.appendElement(
        afterBody,
        `<div data-setting="${this.pageKey}">${closedCampaignPageSetting?.after_body}</div>`,
        commonBeforeBody.length ? 'after' : 'before'
      );
    }

    if (closedCampaignPageSetting?.before_body) {
      this.campaignDataService.appendElement(
        beforeBody,
        `<div data-setting="${this.pageKey}">${closedCampaignPageSetting?.before_body}</div>`,
        commonBeforeBody.length ? 'before' : 'after'
      );
    }

    this.desktopImage = closedCampaignPageSetting?.desktop_image;
    this.mobileImage = closedCampaignPageSetting?.mobile_image;
    this.sections = closedCampaignPageSetting?.sections;
  }

  removeStyle() {
    const items = document.querySelectorAll(`[data-setting="${this.pageKey}"]`);
    items.forEach((item) => {
      item.remove();
    });
  }

  ngOnDestroy(): void {
    this.removeStyle();
  }

  handleError(e: any) {
    if (this.desktopImage === e.target.src || this.mobileImage === e.target.src) {
      e.target.src = '';
    }
  }
}
