import { Pipe, PipeTransform } from '@angular/core';
import { FileTypeEnum } from '@app-shared/enums';


@Pipe({
  name: 'fileType'
})
export class FileTypePipe implements PipeTransform {
  transform(types = []): string[] {
    return types?.map((t: string) => {
      return this.replace(t);
    });
  }

  replace(type: string) {
    switch (type.toUpperCase()) {
      case 'JPG':
        type = FileTypeEnum.JPG;
        break;
      case 'PNG':
        type = FileTypeEnum.PNG;
        break;
      case 'GIF':
        type = FileTypeEnum.GIF;
        break;
      case 'WORD':
        type = FileTypeEnum.WORD;
        break;
      case 'EXCEL':
        type = FileTypeEnum.EXCEL;
        break;
      case 'POWERPOINT':
        type = FileTypeEnum.POWERPOINT;
        break;
      case 'CSV':
        type = FileTypeEnum.CSV;
        break;
      default:
        type = FileTypeEnum.PDF;
    }
    return type;
  }
}
