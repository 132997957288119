import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenService } from '@user/core/services/token.service';
import { DEFAULT_APP_LANGUAGE } from '@app-shared/config/app-language';

// Core Module

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    public tokenService: TokenService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const token = this.tokenService?.accessToken;
    let headers = {}
    if (request.url.includes('/api')) {
      headers = {
        ...headers,
        ['x-localization']: DEFAULT_APP_LANGUAGE
      }
    }
    if (token) {
      headers = {
        ...headers,
        Authorization: 'Bearer ' + token
      }
    }
    request = request.clone({
      setHeaders: headers
    })
    return next.handle(request);
  }
}
