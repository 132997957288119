import { Component, OnInit } from '@angular/core';
import {CampaignDataService} from '@user/core/services';

@Component({
  selector: 'app-bad-request-page',
  templateUrl: './bad-request-page.component.html',
  styleUrls: ['./bad-request-page.component.scss']
})
export class BadRequestPageComponent implements OnInit {

  customMessage: string | undefined = '';
  constructor(
    private campaignDataService: CampaignDataService,
  ) { }

  ngOnInit(): void {
    this.campaignDataService.campaignInitialized.subscribe((campaign) => {
      this.customMessage = campaign?.settings?.error?.page_400_message;
    });
  }

}
