import { FieldTypeEnum } from 'projects/app-shared/src/lib/enums';

export function handleTemplateOption(item: any) {
  const minLength = +item.options.min_length || item.min_length;
  const maxLength = +item.options.max_length || item.max_length;

  let option: any = {
    required: !!item.is_required
  };
  if (item.type == FieldTypeEnum.INPUT || item.type == FieldTypeEnum.TEXTAREA) {
    option = {
      ...option,
      placeholder: item.options.place_holder || item.place_holder,
      minLength: minLength,
      maxLength: maxLength,
    };
  }
  if (item.type == FieldTypeEnum.RADIO
    || item.type == FieldTypeEnum.SEGMENTED
    || item.type == FieldTypeEnum.CHECKBOX
    || item.type == FieldTypeEnum.SELECT) {
    option = {
      ...option,
      minLength: minLength,
      maxLength: maxLength,
      placeholder: item.options.place_holder || item.place_holder,
      name: item.id,
      options: item?.options?.data.map((d: any) => {
        return {
          value: d.item_name,
          label: d.item_name
        };
      })
    };
  }
  return option;
}
