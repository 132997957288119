<div id="bad-request">
  <div *ngIf="! customMessage; else elseBlock" class="db-error-section">
    <p>{{'ERROR.ACCESS_INVALID'|translate}}</p>
    <p>{{'ERROR.PLEASE_TRY_AGAIN'|translate}}</p>
  </div>
  <ng-template #elseBlock>
    <div [innerHTML]="(customMessage || '') | safeHtml" class="db-error-section">
      {{ customMessage }}
    </div>
  </ng-template>
</div>
