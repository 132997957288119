import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { ApiBase } from '@user/core/services/api.service';
import { IResponse, IToken } from '@user/core/interfaces';
import { Observable } from 'rxjs';
import { IPhoneNumber } from '@user/core/interfaces/phone-number';
import { map } from 'rxjs/operators';
import { TokenService } from '@user/core/services/token.service';

@Injectable({
  providedIn: 'root'
})
export class OstiariesAspService {
  readonly scriptId = 'ostiaries-asp-script';
  private renderer: Renderer2;

  constructor(
    rendererFactory: RendererFactory2,
    private apiBase: ApiBase,
    private tokenService: TokenService,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public initScript(serviceId: string, okUrl: string, backUrl: string): void {
    // ensure ostiaries asp script unload before initialize new
    this.destroyScript();
    const script = this.renderer.createElement('script');
    script.id = this.scriptId;
    script.src = `https://asp.ostiaries.jp/api/2.0/s-callauth?id=${serviceId}&ok_url=${okUrl}&back_url=${backUrl}`;
    this.renderer.appendChild(document.head, script);
  }

  public destroyScript(): void {
    const script = document.getElementById(this.scriptId);
    if (script) {
      this.renderer.removeChild(document.head, script);
    }
  }

  public initializePhoneNumber(body: { area_code: string | undefined; phone_number: string }): Observable<IResponse<IPhoneNumber>> {
    return this.apiBase.post<IResponse<IPhoneNumber>>(`/user/auth/phone-numbers/init-auth`, body);
  }

  public verify(verifyToken: string): Observable<IToken> {
    return this.apiBase.post<IResponse<IToken>>(`/user/auth/phone-numbers/verify-token`, {
      verify_token: verifyToken,
    }).pipe(map((response) => {
      this.tokenService.accessToken = response.data.token;
      return response.data;
    }));
  }
}
