import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-field-multiple-input',
  template: `
    <input
      class="w-full"
      [formControl]="formControl"
      type="text"
      [placeholder]="field.templateOptions.place_holder||field.templateOptions.placeholder || ''"
      [name]="field?.key + '_' + to?.randomNumber"
      [autocomplete]="'none'"
      [formlyAttributes]="field"
    >
    <lib-control-errors
      [nameForm]="field?.type"
      [fieldName]="to?.question_id +'.'+field?.key"
    >
    </lib-control-errors>
  `,
})
export class FormlyFieldMultipleInputComponent extends FieldType<FieldTypeConfig> {
}
