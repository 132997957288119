export enum BlockTypeEnum {
  BUTTON = 'button',
  IMAGE = 'image',
  HTML = 'html',
  GIFT = 'gift',
  SERIAL_PASSWORD = 'serial_password',
  LINE_PAY_AGREE_BUTTON = 'line_pay_agree_button',
  OSTIARIES_ASP_FORM = 'ostiaries_asp_form',
  DISPLAY_POINT = 'mileage_display_point',
  REQUEST_POINT_SERIAL = 'mileage_serial',
  REQUEST_POINT_RECEIPT_DECLARATION = 'mileage_receipt_declaration',
  REQUEST_POINT_RECEIPT_APPROVAL = 'mileage_receipt_approval',
  REQUEST_POINT_JAN_CODE = 'mileage_jan_code',
  DISPLAY_COURSE_BLOCK = 'mileage_course',
  LOTTERY = 'lottery',
  LOTTERY_RESULT = 'lottery_result',
  CODE_INACTIVATION = 'code_inactivation',
  ID_PW_FORM = 'id_pw_form',
  DEFAULT = 'default'
}
