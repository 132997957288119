import { Component, ElementRef, ViewChild } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-field-mail',
  template: `
    <label>
      <input
        #inputBase
        [formControl]="formControl"
        [placeholder]="field.templateOptions.place_holder||field.templateOptions.placeholder || ''"
        [name]="field?.key"
        [autocomplete]="'none'"
        [formlyAttributes]="field"
        [className]="to?.class"
        [type]="'email'">
    </label>
  `,
})
export class FormlyFieldEmailComponent extends FieldType<FieldTypeConfig> {
  @ViewChild('inputBase') inputBase!: ElementRef;
}
