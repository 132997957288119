import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { HttpErrorCodeEnum } from '@app-shared/enums';
import { CampaignOpenGuard } from '@user/core/guards/campaign-open.guard';
import { ApplyAuthMethodEnum, CampaignTypeEnum, FormUsingTypeEnum, ICampaign, IUser } from '@user/core/interfaces';
import { AuthService, LotteryService, SecretCodeService, SecretPathService } from '@user/core/services';
import { CampaignDataService } from '@user/core/services/campaign-data.service';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, first, map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SecretCodeVerifyGuard implements CanActivate {

  campaign!: ICampaign;
  user!: IUser;
  constructor(
    private campaignDataService: CampaignDataService,
    private secretCodeService: SecretCodeService,
    private route: Router,
    private campaignOpenGuard: CampaignOpenGuard,
    private secretPathService: SecretPathService,
    private authService: AuthService,
    private lotteryService: LotteryService
  ) {
  }

  private navigateTo(routeArray: string[] = ['/']): Promise<boolean> {
    if (this.secretPathService.isSecretPath()) {
      return this.route.navigate(['/', this.secretPathService.secretPath, ...routeArray]);
    }
    return this.route.navigate(routeArray);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    return combineLatest([
      this.campaignOpenGuard.canActivate(route, state),
      this.campaignDataService.campaignInitialized,
      this.authService.profile()
    ]).pipe(
      first(),
      mergeMap(([result, campaign, profile]) => {
        if (!result) {
          return of(false);
        }
        this.campaign = campaign;
        const service = this.secretCodeService.getFormProtectionServiceByApplyAuthMethod(campaign.apply_auth_method);
        if (!service
          || !this.secretCodeService.checkApplyAuthMethod(campaign.apply_auth_method)) {
          this.user = profile
          return of(true);
        }
        this.secretCodeService.parseCodeFromQueryParams(campaign, route.queryParams, route.url);

        // Allow access form and don't check code exist if Campaign Lottery or Mileage and user not yet input survey
        const user = this.authService.currentUser;
        this.user = profile
        if (state.url.includes('form') && (campaign?.campaign_type === CampaignTypeEnum.LOTTERY || campaign?.campaign_type === CampaignTypeEnum.MILEAGE)
          && campaign?.form_using_type === FormUsingTypeEnum.FORM && user && user.total_apply <= 0) {
          return of(true);
        }

        // access Common URL and redirect to Form when Campaign No_FORM + Lottery end
        const isLotteryEnd = !!(this.campaign.settings?.lottery_setting?.campaign_lottery_end_comparison?.less_than_now) && this.campaign.open_end_comparison.greater_than_or_equal_to_now
        if ((isLotteryEnd || campaign.reached_submission_form_limit) && campaign.campaign_type === CampaignTypeEnum.LOTTERY &&
          campaign.form_using_type === FormUsingTypeEnum.NO_FORM && !this.user.total_lottery_apply) {
            return of(true)
        }
        // Check Code
        const token = service.getToken();
        if (token === null) {
          if (campaign.apply_auth_method === ApplyAuthMethodEnum.HITAP
            || campaign.apply_auth_method === ApplyAuthMethodEnum.UNIQUE) {
              return this.route.navigate(['static', 'bad-request']);
            }
          return this.navigateTo(['gate']);
        }
        return this.secretCodeService.checkCodeByApplyAuthMethod(campaign.apply_auth_method, token, true);
      }),
    ).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === HttpErrorCodeEnum.CONFLICT) {
          this.navigateTo(['static', 'already-applied']);
        }
        this.secretCodeService.removeStoreKey(this.campaign.apply_auth_method);
        return of(false);
      }),
      map((result) => {
        if (!result) {
          if (this.campaign.apply_auth_method === ApplyAuthMethodEnum.HITAP
            || this.campaign.apply_auth_method === ApplyAuthMethodEnum.UNIQUE) {
              this.route.navigate(['static', 'bad-request']);
              return false;
            } else {
              this.navigateTo(['gate']);
              return false;
            }
          }

        const isPublicLottery = !!(this.campaign.settings?.lottery_setting?.campaign_lottery_start_comparison?.less_than_or_equal_to_now)
          && !!(this.campaign.settings?.lottery_setting?.campaign_lottery_end_comparison?.greater_than_or_equal_to_now);
        const isLotteryEnd = !!(this.campaign.settings?.lottery_setting?.campaign_lottery_end_comparison?.less_than_now) && this.campaign.open_end_comparison.greater_than_or_equal_to_now

        if (this.campaign?.campaign_type === CampaignTypeEnum.LOTTERY) {
          // setting Max Apply
          if (this.campaign.reached_submission_form_limit) {
            if ((this.user.total_lottery_apply || this.user.total_apply)) {
              if ((isPublicLottery && this.campaign.apply_auth_method === ApplyAuthMethodEnum.NORMAL && (this.campaign.user_day_limit === 1 || this.campaign.user_limit === 1))) {
                return this.lotteryService.checkLotteryDuration(null)
              }
              if (isLotteryEnd && this.campaign.apply_auth_method !== ApplyAuthMethodEnum.UNIQUE) {
                if (this.campaign.apply_auth_method === ApplyAuthMethodEnum.NORMAL && this.campaign.user_limit === 1) {
                  return this.lotteryService.checkLotteryDuration(null)
                }
                this.navigateTo(['static', 'lottery-ended']);
                return false;
              }
            }
            this.navigateTo(['static', 'closed']);
            return false;
          }
          // without setting Max Apply
          if (isLotteryEnd) {
            if ((this.user.total_lottery_apply || this.user.total_apply)) {
              if ((this.campaign.apply_auth_method === ApplyAuthMethodEnum.NORMAL && this.campaign.user_limit === 1)) {
                return this.lotteryService.checkLotteryDuration(null)
              }
            }
            this.navigateTo(['static', 'lottery-ended']);
            return false;
          }
        }

        return result;
      })
    );
  }
}
