import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-home-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  isScreenDesktop = true;
  @Input() id = '';
  @Input() desktopImage?: string;
  @Input() mobileImage?: string;
  @Input() class?: string[] | string | Record<string, boolean | undefined | null> = [];
  @Input() classContent = 'wrap';

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isScreenDesktop = window.innerWidth > 767;
  }

  constructor() {
    this.onResize();
  }

  ngOnInit(): void {
  }

  handleError(e: any) {
    if (this.desktopImage === e.target.src || this.mobileImage === e.target.src) {
      e.target.src = '';
    }
  }
}
