import { Component, OnInit } from '@angular/core';
import {CampaignDataService} from '@user/core/services';

@Component({
  selector: 'lib-already-sent-gift-page',
  templateUrl: './already-sent-gift-page.component.html',
  styleUrls: ['./already-sent-gift-page.component.scss']
})
export class AlreadySentGiftPageComponent implements OnInit {
  customMessage: string | undefined = '';

  constructor(
    private campaignDataService: CampaignDataService,
  ) { }

  ngOnInit(): void {
    // this.campaignDataService.campaign.subscribe((campaign) => {
    //   this.customMessage = campaign?.settings?.error?.page_409_message;
    // });
  }
}
