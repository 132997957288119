import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { WINDOW } from '@user/shared/utils/window';
import { CameraImage, CameraUtil } from '@user/shared/utils/camera.util';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-camera-modal',
  templateUrl: './camera-modal.component.html',
  styleUrls: ['./camera-modal.component.scss']
})
export class CameraModalComponent implements OnInit, OnDestroy {
  @Input() isShowCamera = false;
  @Output() cameraError = new EventEmitter<void>();
  @Output() toggleCameraModal = new EventEmitter<boolean>();
  @Output() submitCamera = new EventEmitter<CameraImage>();
  private trigger: Subject<void> = new Subject<void>();
  private nextCamera: Subject<boolean | string> = new Subject<boolean | string>();

  facingMode = 'environment'; // Set rear camera
  // facingMode: 'user' | undefined;  // Set front camera
  width = 0;
  height = 0;
  errors: any;
  multipleCamerasAvailable = false;
  deviceId!: string;
  allowCameraSwitch = false;

  @ViewChild('camera') camera: any;
  @HostListener('window:resize', ['$event'])
  onResize(event?: Event): void {
    const win = !!event ? (event.target as Window) : window;
    this.width = win.innerWidth;
    this.height = win.innerHeight;
  }

  constructor(@Inject(WINDOW) private window: Window) {
    this.onResize();
  }

  public get videoOptions(): MediaTrackConstraints {
    // const result: MediaTrackConstraints = { aspectRatio: this.window.innerWidth / this.window.innerHeight };
    const result: MediaTrackConstraints = { width: { min: 640, ideal: 1920 }, height: { min: 480, ideal: 1080 } };
    if (this.facingMode) {
      result.facingMode = { ideal: this.facingMode };
    }
    return result;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  ngOnInit(): void {
    CameraUtil.getAvailableVideoInputs().then(
      (mediaDevices: MediaDeviceInfo[]) => {
        this.multipleCamerasAvailable = mediaDevices && mediaDevices.length > 1;
      }
    );
  }

  cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }

  // Switch to next camera device if available
  showNextCamera(directionOrDeviceId: boolean | string): void {
    this.nextCamera.next(directionOrDeviceId);
  }

  get nextCameraObservable(): Observable<boolean | string> {
    return this.nextCamera.asObservable();
  }

  triggerSnapshot(): void {
    this.trigger.next();
  }

  handleInitError(error: any): void {
    this.cameraError.emit(error);
    this.errors = error;
  }

  handleImage(cameraImage: CameraImage): void {
    this.submitCamera.emit(cameraImage);
    this.closeCamera();
  }

  closeCamera(): void {
    this.toggleCameraModal.emit(false);
  }

  ngOnDestroy(): void {
    this.trigger.complete();
  }
}
