export class CameraUtil {

  /**
   * Lists available videoInput devices
   * @returns a list of media device info.
   */
  public static getAvailableVideoInputs(): Promise<MediaDeviceInfo[]> {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      return Promise.reject('enumerateDevices() not supported.');
    }

    return new Promise((resolve, reject) => {
      navigator.mediaDevices.enumerateDevices()
        .then((devices: MediaDeviceInfo[]) => {
          resolve(devices.filter((device: MediaDeviceInfo) => device.kind === 'videoinput'));
        })
        .catch(err => {
          reject(err.message || err);
        });
    });
  }
}

/**
 * Container class for a captured camera image
 * @author basst314, davidshen84
 */
export class CameraImage {

  public constructor(imageAsDataUrl: string, mimeType: string, imageData: ImageData) {
    this.$mimeType = mimeType;
    this.$imageAsDataUrl = imageAsDataUrl;
    this.$imageData = imageData;
  }

  private readonly $mimeType: string = '';
  private $imageAsBase64 = '';
  private readonly $imageAsDataUrl: string = '';
  private readonly $imageData: ImageData;


  /**
   * Extracts the Base64 data out of the given dataUrl.
   * @param dataUrl the given dataUrl
   * @param mimeType the mimeType of the data
   */
  private static getDataFromDataUrl(dataUrl: string, mimeType: string): string {
    return dataUrl.replace(`data:${mimeType};base64,`, '');
  }

  /**
   * Get the base64 encoded image data
   * @returns base64 data of the image
   */
  public get imageAsBase64(): string {
    return this.$imageAsBase64 ? this.$imageAsBase64
      : this.$imageAsBase64 = CameraImage.getDataFromDataUrl(this.$imageAsDataUrl, this.$mimeType);
  }

  /**
   * Get the encoded image as dataUrl
   * @returns the dataUrl of the image
   */
  public get imageAsDataUrl(): string {
    return this.$imageAsDataUrl;
  }

  /**
   * Get the ImageData object associated with the canvas' 2d context.
   * @returns the ImageData of the canvas's 2d context.
   */
  public get imageData(): ImageData {
    return this.$imageData;
  }

}
