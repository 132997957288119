import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@user/core/services';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  @Input() isShow = false;
  @Output() onClose$ = new EventEmitter<any>();

  constructor(
    public notificationService: NotificationService,
    public router: Router
  ) {
  }

  ngOnInit(): void {
  }

  onClose(): void {
    if(this.notificationService.isReloadPage.value) {
      location.reload();
    }
    this.notificationService.isOpenNotification = false;
  }

}
