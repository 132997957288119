export enum QuestionTypeEnum {
  RE_EMAIL = 1,
  ADDRESS = 2,
  NAME = 3,
  UPLOAD_IMAGE = 4,
  TEXTBOX = 5,
  TEXTAREA = 6,
  CHECKBOX = 7,
  PULLDOWN = 8,
  RADIO = 9,
  SEGMENTED_CONTROL = 10,
  UPLOAD_FILE = 11,
}
