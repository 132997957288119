<!-- Modal_Window -->
<div class="shared-modal-wrapper">
  <a
    href="javascript:;"
    class="modal-overlay">
  </a>

  <div class="modal-window">
    <div class="modal-content">
      <div class="modal-cancel" (click)="onCloseModal()"></div>

      <h2 *ngIf="!(messageToUser && messageToUser?.messageType === 'success')">{{ 'MILEAGE.MD_OCR_POINT_TEXT' | translate }} {{ resultOCR || 0 }}</h2>

      <p class="text-message" *ngIf="message" [innerHTML]="message"></p>

      <div class="btn-group">
        <input
          [type]="messageToUser && messageToUser?.messageType === 'success' ? 'hidden' : 'button'"
          class="abtn"
          [disabled]="isSubmitted"
          [value]="'MILEAGE.LABEL_REQUEST_POINT' | translate"
          (click)="onSubmitMileage()">

        <button
          type="button"
          class="abtn back_btn"
          (click)="onCloseModal()">
          <p>{{ 'MILEAGE.MD_OCR_BTN_TRY_AGAIN' | translate }}</p>
        </button>
      </div>
    </div>
  </div>
</div>
<!-- //Modal_Window -->
