import {Injectable} from '@angular/core';
import {ActivatedRoute, Params, Router, UrlSegment} from '@angular/router';
import {ApplyAuthMethodEnum, ICampaign, IGiftInformation} from '@user/core/interfaces';
import {Observable, of} from 'rxjs';
import {IGiftAdditionalData} from '@user/core/interfaces/form';
import {CustomParameterService} from '@user/core/services/custom-parameter.service';
import {
  HitapProtectionService,
  PasswordFormProtectionService,
  SerialFormProtectionService,
  UrlFormProtectionService
} from '@user/core/services/form-protection';
import {UrlService} from "@user/core/services/url.service";

@Injectable({
  providedIn: 'root'
})
export class SecretCodeService {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private serialFormProtectionService: SerialFormProtectionService,
    private passwordFormProtectionService: PasswordFormProtectionService,
    private urlFormProtectionService: UrlFormProtectionService,
    private hitapProtectionService: HitapProtectionService,
    private customParameterService: CustomParameterService,
    private urlService: UrlService,
  ) {}

  //#region Page check
  parseCodeFromQueryParams(campaign: ICampaign, queryParams: Params, redirectBackUrl: any[] = []): void {
    const formProtectionService = this.getFormProtectionServiceByApplyAuthMethod(campaign.apply_auth_method);
    if (campaign.apply_auth_method === ApplyAuthMethodEnum.HITAP
      && campaign.settings.hitap_param_unique
      && formProtectionService) {
      formProtectionService.queryKey = campaign.settings.hitap_param_unique;
      formProtectionService.parameterName = campaign.settings.hitap_param_unique;
    }
    let queryKey = null;

    if (formProtectionService && formProtectionService.queryKey in queryParams) {
      queryKey = formProtectionService.queryKey;
      formProtectionService.saveToken(queryParams[queryKey]);
    }
    const customParams = [];
    if (this.customParameterService.checkCustomParamExists(campaign.settings.custom_params || [], queryParams)) {
      customParams.push(...this.customParameterService.getCustomParamsFromQueryParams(campaign.settings.custom_params || [], queryParams));
      // save custom parameters
      this.customParameterService.saveCustomParameters(campaign.settings.custom_params || [], queryParams);
    }
    let paramList: string[] = [];
    if (queryKey) {
      paramList = [...paramList, queryKey];
    }
    if (customParams.length > 0) {
      paramList = [...paramList, ...customParams];
    }
    if (paramList.length > 0) {
      this.removeQueryParam(paramList, redirectBackUrl, queryParams);
    }
  }
  parseCodeFromLocation(campaign: ICampaign): void {
    const redirectBackUrl = location.pathname ? [new UrlSegment(location.pathname, {})] : [];
    const searchParams = location.search && location.search.length > 1 ? location.search.substring(1) : '';
    let queryParams = {};
    if (searchParams) {
      queryParams = JSON.parse('{"'
        + searchParams.replace(/&/g, '","').replace(/=/g, '":"')
        + '"}', (key, value) => key === '' ? value : decodeURIComponent(value)
      );
    }
    return this.parseCodeFromQueryParams(campaign, queryParams, redirectBackUrl);
  }

  checkApplyAuthMethod(applyAuthMethod: ApplyAuthMethodEnum): boolean {
    return (applyAuthMethod === ApplyAuthMethodEnum.PASSWORD
      || applyAuthMethod === ApplyAuthMethodEnum.SERIAL
      || applyAuthMethod === ApplyAuthMethodEnum.UNIQUE
      || applyAuthMethod === ApplyAuthMethodEnum.HITAP
    );
  }

  getFormProtectionServiceByApplyAuthMethod(applyAuthMethod: ApplyAuthMethodEnum): SerialFormProtectionService
    | PasswordFormProtectionService
    | UrlFormProtectionService
    | HitapProtectionService
    | null {
    switch (applyAuthMethod) {
      case ApplyAuthMethodEnum.SERIAL:
        return this.serialFormProtectionService;
      case ApplyAuthMethodEnum.PASSWORD:
        return this.passwordFormProtectionService;
      case ApplyAuthMethodEnum.UNIQUE:
        return this.urlFormProtectionService;
      case ApplyAuthMethodEnum.HITAP:
        return this.hitapProtectionService;
    }
    return null;
  }

  checkCodeByApplyAuthMethod(applyAuthMethod: ApplyAuthMethodEnum, code: string, onScreenError = false): Observable<boolean> {
    const formProtectionService = this.getFormProtectionServiceByApplyAuthMethod(applyAuthMethod);
    const queryParams: { [key: string]: string } = {};
    if (onScreenError) {
      queryParams.on_screen_error = '1';
    }
    if (formProtectionService) {
      return formProtectionService.check(code, queryParams);
    }
    return of(false);
  }

  getStoreValue(applyAuthMethod: ApplyAuthMethodEnum): string | null {
    const formProtectionService = this.getFormProtectionServiceByApplyAuthMethod(applyAuthMethod);
    if (formProtectionService) {
      return formProtectionService.getToken();
    }
    return null;
  }

  saveStoreValue(applyAuthMethod: ApplyAuthMethodEnum, value: string): void {
    const formProtectionService = this.getFormProtectionServiceByApplyAuthMethod(applyAuthMethod);
    if (formProtectionService) {
      formProtectionService.saveToken(value);
    }
  }

  removeStoreKey(applyAuthMethod: ApplyAuthMethodEnum): void {
    const formProtectionService = this.getFormProtectionServiceByApplyAuthMethod(applyAuthMethod);
    if (formProtectionService) {
      formProtectionService.removeToken();
      this.customParameterService.removeCustomParameters();
    }
  }

  saveGiftInformation(giftInformation: IGiftInformation): void {
    if (giftInformation && giftInformation.gift_code) {
      sessionStorage.setItem('gift_code', giftInformation.gift_code);
      if (giftInformation.additional_data) {
        sessionStorage.setItem('gift_additional_data', JSON.stringify(giftInformation.additional_data));
      }
    }
  }

  getGiftInformation(): IGiftInformation | null {
    let giftInformation: IGiftInformation | null = null;
    const giftCode = sessionStorage.getItem('gift_code');
    const additionalDataGiftRaw = sessionStorage.getItem('gift_additional_data');
    if (giftCode) {
      giftInformation = {};
      giftInformation.gift_code = giftCode;
    }
    if (additionalDataGiftRaw) {
      if (!giftInformation) {
        giftInformation = {};
      }
      giftInformation.additional_data = JSON.parse(additionalDataGiftRaw) as IGiftAdditionalData;
    }
    return giftInformation;
  }

  removeGiftInformation(): void {
    sessionStorage.removeItem('gift_code');
    sessionStorage.removeItem('gift_additional_data');
  }
  //#endregion

  //#region Private functions
  private removeQueryParam(paramList: string[], redirectBackUrl: any[], oldQueryParams: { [key: string]: string } = {}): void {
    let url: string[] = [];
    if (redirectBackUrl
      && redirectBackUrl.length > 0
      && redirectBackUrl[0] instanceof UrlSegment) {
      url = [redirectBackUrl[0].path];
    }
    const newParamObj: { [key: string]: string | null } = {...oldQueryParams};
    if (paramList.length > 0) {
      paramList.forEach((paramName) => {
        delete newParamObj[paramName];
      });
      this.urlService.saveParameters(newParamObj, window.location.hash);
    }
    this.router.navigate(url, {
      // relativeTo: this.route,
      queryParams: newParamObj,
      replaceUrl: true,
      // queryParamsHandling: 'merge'
    });
  }
  //#endregion
}
