import { Injectable } from '@angular/core';
import {BaseFormProtectionService} from '@user/core/services/form-protection/base-form-protection.service';
import {StorageService} from '@user/core/services/storage.service';
import {ApiBase} from '@user/core/services/api.service';
import {CustomParameterService} from '@user/core/services/custom-parameter.service';

@Injectable({
  providedIn: 'root'
})
export class HitapProtectionService extends BaseFormProtectionService {
  queryKey = '';
  storeKey = 'hitap_code';
  baseUrl = '/hitap/check';
  parameterName = '';

  constructor(
    protected apiBase: ApiBase,
    protected storageService: StorageService,
    protected customParameterService: CustomParameterService,
  ) {
    super(apiBase, storageService, customParameterService);
  }
}
