import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-message-confirm-modal',
  templateUrl: './message-confirm-modal.component.html',
  styleUrls: ['./message-confirm-modal.component.scss']
})
export class MessageConfirmModalComponent implements OnInit {
  @Input() isShowModal = false;
  @Input() isSubmitted = false;
  @Input() confirmText = 'CONFIRM_MODAL.CONFIRMATION_TEXT';

  @Input() labelApply = 'CONFIRM_MODAL.AGREE_BUTTON_LABEL';
  @Input() labelCancel = 'CONFIRM_MODAL.CANCEL_BUTTON_LABEL';
  @Output() closeModal = new EventEmitter<boolean>();
  @Output() applyModal = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  onCloseModal(): void {
    this.closeModal.emit(false);
  }

  onApply(): void {
    this.applyModal.emit();
  }
}
