import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  NavigationExtras,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { AuthService, CampaignDataService, LotteryService, SecretPathService } from '@user/core/services';
import { first, mergeMap } from 'rxjs/operators';
import { ApplyAuthMethodEnum, CampaignTypeEnum, FormUsingTypeEnum, ICampaign, IUser } from '@user/core/interfaces';

@Injectable({
  providedIn: 'root'
})
export class LotteryGuard implements CanActivate {
  constructor(
    private router: Router,
    private campaignDataService: CampaignDataService,
    private authService: AuthService,
    private secretPathService: SecretPathService,
    private lotteryService: LotteryService
  ) {
  }

  redirectAndReturnFalse(urls = ['static', 'not-found'], extras = {}): Observable<boolean> {
    this.router.navigate(urls, extras);
    return of(false);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return combineLatest([
      this.campaignDataService.campaignInitialized,
      this.authService.profile()
    ]).pipe(
      first(),
      mergeMap(([campaign, user]) => {
        if (!campaign) {
          return this.redirectAndReturnFalse();
        }
        const isPublicLottery = !!(campaign.settings?.lottery_setting?.campaign_lottery_start_comparison?.less_than_or_equal_to_now)
        && !!(campaign.settings?.lottery_setting?.campaign_lottery_end_comparison?.greater_than_or_equal_to_now);

        if (campaign.campaign_type === CampaignTypeEnum.LOTTERY && campaign?.form_using_type === FormUsingTypeEnum.FORM &&
          user && user.total_apply <= 0 && (isPublicLottery || this.secretPathService.isSecretPath())) {
          const secretPath = this.secretPathService.isSecretPath() ? this.secretPathService.secretPath : '';
          const returnUrl = state?.url || null;
          const extras: NavigationExtras = {};
          if (returnUrl) {
            extras.queryParams = { return_url: returnUrl };
          }
          return this.redirectAndReturnFalse([secretPath, 'form'], extras);
        }

        if (this.secretPathService.isSecretPath()) {
          if (campaign.campaign_type === CampaignTypeEnum.LOTTERY) {
            if (this.getConditionRedirectReceipt(campaign, user)) {
                return this.redirectAndReturnFalse([this.secretPathService.secretPath, 'receipt']);
            }
          }
          return of(true);
        }


        if (campaign.campaign_type === CampaignTypeEnum.LOTTERY) {

          const isEndLottery =  !!(campaign.settings?.lottery_setting?.campaign_lottery_end_comparison?.less_than_now) && campaign.open_end_comparison.greater_than_or_equal_to_now
          if (isEndLottery) {
            if (user && (user.total_apply || user.total_lottery_apply)) {
              if (campaign.apply_auth_method === ApplyAuthMethodEnum.SERIAL) {
                return of(true)
              } else if(campaign.apply_auth_method === ApplyAuthMethodEnum.NORMAL) {
                if (campaign.user_limit === 1 || campaign.reached_submission_form_limit) {
                  return of(true)
                }
              }
            }
            if (campaign.reached_submission_form_limit && campaign.apply_auth_method === ApplyAuthMethodEnum.UNIQUE) {
              return this.redirectAndReturnFalse(['static', 'closed']);
            }
            return this.redirectAndReturnFalse(['static', 'lottery-ended']);
          }

          if (campaign.reached_submission_form_limit && isPublicLottery && user) {
            // check if current user is old user (answered survey or participate in lottery)
            if (user.total_lottery_apply || user.total_apply) {
              if ([ApplyAuthMethodEnum.SERIAL, ApplyAuthMethodEnum.UNIQUE].includes(campaign.apply_auth_method) || (campaign.apply_auth_method === ApplyAuthMethodEnum.NORMAL && (campaign.user_day_limit === 1 || campaign.user_limit === 1)))
                return of (isPublicLottery)
            }
            return this.redirectAndReturnFalse(['static', 'closed']);
          }

          if (this.getConditionRedirectReceipt(campaign, user)) {
              return this.redirectAndReturnFalse(['receipt']);
          }
          if (campaign.apply_auth_method === ApplyAuthMethodEnum.NORMAL && (campaign.user_day_limit === 1 || campaign.user_limit === 1) &&
           (this.authService.currentUser.total_apply > 0 ||
           this.authService.currentUser.total_lottery_apply) &&
           !sessionStorage.getItem('check_apply_lottery')) {
               return this.getResultAndRedirect()
           }

          return of(isPublicLottery);
        }

        return of(false);
      })
    );
  }

  getResultAndRedirect () {
    sessionStorage.setItem('check_apply_lottery', 'yes')
    return this.lotteryService.checkLottery(null)
  }

  getConditionRedirectReceipt (campaign: ICampaign, user: IUser) {
    const isUploaded = sessionStorage.getItem('upload_receipt') || ''
      if ((campaign?.form_using_type === FormUsingTypeEnum.NO_FORM || (campaign?.form_using_type === FormUsingTypeEnum.FORM &&
        user && user.total_apply > 0))) {
          if (campaign.apply_auth_method === ApplyAuthMethodEnum.RECEIPT  && !isUploaded) {
            return true
          }
      }
      return false
  }
}
