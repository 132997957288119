import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {CoreModule} from '@user/core/core.module';
import {ApiBase, LoadingOverlayService} from '@user/core/services';
import {APP_BASE_HREF} from '@angular/common';
import {FormlyModule} from '@ngx-formly/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from '@user/shared/shared.module';
import {ComponentModule} from '@app-shared/component/component.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {registerLocaleData} from '@angular/common';
import ja from '@angular/common/locales/ja';
import {AppSharedModule} from '@app-shared/app-shared.module';
import {DEFAULT_APP_LANGUAGE} from '@app-shared/config/app-language';

registerLocaleData(ja);

const PROVIDERS = [
  {provide: APP_BASE_HREF, useValue: '/'},
  ApiBase,
  HttpClient,
  LoadingOverlayService
];

const MODULE_SHARED = [
  CoreModule,
  SharedModule,
  FormlyModule.forRoot(),
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ComponentModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient): TranslateHttpLoader => {
          return new TranslateHttpLoader(http, './assets/i18n/', '.json');
        },
        deps: [HttpClient]
      },
      defaultLanguage: DEFAULT_APP_LANGUAGE
    }),
    ...MODULE_SHARED,
    AppSharedModule,
  ],
  providers: [...PROVIDERS],
  bootstrap: [AppComponent]
})
export class AppModule {
}
