<div [id]="id" [class]="class">
  <div class="banner" *ngIf="mobileImage || desktopImage">
    <picture id="banner_img">
      <source media="(min-width:768px)" [srcset]="desktopImage">
      <source media="(max-width:767px)" [srcset]="mobileImage">
      <img
        (error)="handleError($event)"
        [src]="desktopImage || mobileImage">
    </picture>
  </div>
  <div
    [class]="'home-wrap ' + classContent" id="home-wrap">
    <ng-content select="[content]"></ng-content>
  </div>
</div>
