import {Injectable} from '@angular/core';
import {ApiBase} from '@user/core/services/api.service';
import {Observable, of} from 'rxjs';
import {apiEndpoints} from '@user/config/global-vars';
import {IResponse} from '@user/core/interfaces';
import {ILotteryResponse} from '@user/core/interfaces/lottery';
import {CustomParameterService} from '@user/core/services/custom-parameter.service';
import { catchError, map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class LotteryService {

  constructor(
    private apiBase: ApiBase,
    private customParameterService: CustomParameterService,
  ) {
  }

  lottery(code: string | null): Observable<IResponse<ILotteryResponse>> {
    const formData = new FormData();
    const customParameters = this.customParameterService.getCustomParameters();
    if (code !== null && code !== '') {
      formData.append('code', code);
    }

    for (const key of Object.keys(customParameters)) {
      formData.append(`custom_parameters[${key}]`, customParameters[key]);
    }

    return this.apiBase.post(apiEndpoints.lottery, formData);
  }

  checkLottery(code: string | null): Observable<boolean> {
    const formData = new FormData();
    const customParameters = this.customParameterService.getCustomParameters();
    if (code !== null && code !== '') {
      formData.append('code', code);
    }

    for (const key of Object.keys(customParameters)) {
      formData.append(`custom_parameters[${key}]`, customParameters[key]);
    }

    return this.apiBase.post(apiEndpoints.check_lottery, formData).pipe(map(_ => {
      return true
    }),
    catchError(_ => {
      return of(false)
    })
    );
  }

  uploadImage (file: any): Observable<any> {
    const formData = new FormData();
    formData.append('image', file);
    return this.apiBase.post(apiEndpoints.upload_receipt, formData);
  }

  checkLotteryDuration(code: string | null): boolean {
    const formData = new FormData();
    const customParameters = this.customParameterService.getCustomParameters();
    if (code !== null && code !== '') {
      formData.append('code', code);
    }

    for (const key of Object.keys(customParameters)) {
      formData.append(`custom_parameters[${key}]`, customParameters[key]);
    }
    let result = false
    this.apiBase.post(apiEndpoints.check_lottery, formData).subscribe(_ => {
      result = true
    })
    return result
  }
}


