export enum GiftTypeEnum {
  NO_GIFT = 'no-gift',
  GIFTEE = 'giftee',
  LINE_PAY = 'line-pay',
  QUO_PAY = 'quo-pay',
  GPOINT = 'gpoint',
  FREE_CODE = 'free-code',
  PAYPAY = 'pay-pay',
  ZALO_PAY = 'zalo-pay',
}
