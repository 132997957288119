import {FormControl, ValidationErrors} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {FileTypePipe} from '../pipes/file-type.pipe';
import {FieldTypeEnum} from '../enums';
import {DataTypeEnum} from '@app-shared/enums';
import {
  checkboxValidation,
  inputValidation,
  multipleInputValidation,
  radioValidation, segmentedValidation,
  selectValidation,
  textareaValidation
} from '@app-shared/validator';

export function patternValidator(
  control: FormControl,
  field: FormlyFieldConfig | any
): ValidationErrors | null {
  if (!control.value) {
    return null;
  }

  const reg = new RegExp(
    (field?.templateOptions?.data?.regex || field.templateOptions.regex)
      ?.replace('/u', '')
      ?.replace('/^', '^')
  );
  const output = !reg?.test(control?.value);
  return output ? {pattern: output} : null;
}

export function spaceValidator(
  control: FormControl,
  field: FormlyFieldConfig | any
): ValidationErrors | null {
  if (!control.value) {
    return null;
  }

  const reg = new RegExp(/^\s+|\s+$/);
  const output = reg?.test(control?.value);
  return output ? {space: output} : null;
}

export function fileValidator(
  control: FormControl,
  field: FormlyFieldConfig | any
): ValidationErrors | any {
  if (!control.value || typeof control?.value == DataTypeEnum.STRING) {
    return null;
  }
  const file =
    Object.values(control.value).length > 1
      ? control.value[field.key]
      : control.value;

  const sizeLimit = +field.templateOptions.data.options.size_limit;
  const file_type = new FileTypePipe().transform(
    field.templateOptions.data?.options?.file_type_limit ||
    field.templateOptions.data?.options?.file_format
  );
  if (file && file.length) {
    const fileSize = file[0].size;
    const fileSizeInKB = Math.ceil(fileSize / 1024 / 1024);
    const type = file[0].type;

    const isFileError =
      !!field.templateOptions.data.options?.file_format?.length;
    const listFileType =
      field.templateOptions.data.options?.file_type_limit ||
      field.templateOptions.data.options?.file_format;
    const extensionFileType = listFileType.map((item: string) => {
      return item
        .replace('word', ' .docx')
        .replace('excel', ' .xlsx')
        .replace('powerpoint', '.pptx')
        .replace('csv', ' .csv')
        .replace('pdf', ' .pdf');
    });

    return !file_type.includes(type)
      ? {
        fileTypeValidator: {
          requiredInput: {
            type: isFileError
              ? extensionFileType
              : listFileType.join(field.templateOptions.data.options?.separator ?? ',').toUpperCase(),
          },
          message: 'ERROR.FILE_TYPE',
        },
      }
      : fileSizeInKB > sizeLimit
        ? {
          fileSizeValidator: {
            requiredInput: {
              size: sizeLimit,
            },
            message: 'ERROR.FILE_SIZE',
          },
        }
        : null;
  }
}

export function validateQuestion(
  control: FormControl,
  field: FormlyFieldConfig | any
): Record<any, any> | string | null {
  if (!control.value || !control.value.length) {
    if (!field.templateOptions.required) {
      return null;
    }
  }

  if (field.type === FieldTypeEnum.INPUT || field.type === FieldTypeEnum.EMAIL) {
    return inputValidation(control, field);
  }

  if (field.type === FieldTypeEnum.MULTIPLE_INPUT) {
    return multipleInputValidation(control, field);
  }

  if (field.type === FieldTypeEnum.TEXTAREA) {
    return textareaValidation(control, field);
  }

  if (field.type === FieldTypeEnum.CHECKBOX) {
    return checkboxValidation(control, field);
  }

  if (field.type === FieldTypeEnum.SELECT) {
    return selectValidation(control, field);
  }

  if (field.type === FieldTypeEnum.RADIO) {
    return radioValidation(control, field);
  }

  if (field.type === FieldTypeEnum.SEGMENTED) {
    return segmentedValidation(control, field);
  }

  return null;
}
