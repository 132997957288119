<div id="wrapper">
  <div class="banner" *ngIf="mobileImage || desktopImage">
    <picture id="banner_img">
      <source media="(min-width:768px)" [srcset]="desktopImage">
      <source media="(max-width:767px)" [srcset]="mobileImage">
      <img
        (error)="handleError($event)"
        [src]="desktopImage || mobileImage">
    </picture>
  </div>


  <div class="wrap">
    <div id="closed-page">
      <ng-template [ngIf]="sections && sections.length > 0">
        <ng-container *ngFor="let section of sections; let i = index">
          <lib-section [section]="section" [sectionId]="i.toString()">
          </lib-section>
        </ng-container>
      </ng-template>
    </div>
  </div>
</div>
