import { DEFAULT_APP_LANGUAGE } from '@app-shared/config/app-language';
import { AppLanguage } from '@app-shared/enums';

export function isLanguageVietNam(): boolean {
  return DEFAULT_APP_LANGUAGE === AppLanguage.VIETNAMESE;
}

export function isLanguageJapanese(): boolean {
  return DEFAULT_APP_LANGUAGE === AppLanguage.JAPANESE;
}
