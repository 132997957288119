import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BarcodeFormat } from '@zxing/library';
// import { ZXingScannerComponent } from '@zxing/ngx-scanner'

@Component({
  selector: 'app-scanner-modal',
  templateUrl: './scanner-modal.component.html',
  styleUrls: ['./scanner-modal.component.scss']
})
export class ScannerModalComponent implements OnInit {
  @Input() isShowScanner = false;
  @Output() cameraError = new EventEmitter<any>();
  @Output() toggleScannerModal = new EventEmitter<boolean>();
  @Output() submitScanner = new EventEmitter<string | null>();
  enabledFormats: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.CODE_39,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];

  // @ViewChild('scanner') scanner: ZXingScannerComponent;
  hasDevices = false;
  hasPermission = false;
  availableDevices: MediaDeviceInfo[] = [];

  constructor() { }

  ngOnInit(): void {

  }

  onHasPermission(has: boolean): void {
    this.hasPermission = has;
    if (!has) {
      this.cameraError.emit({
        mediaStreamError: {
          name: 'NotAllowedError'
        }
      });
      this.closeScanner();
    }
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  onCamerasNotFound(value: any): void {
    this.hasDevices = false;
    this.cameraError.emit({
      mediaStreamError: {
        name: 'NotFoundError'
      }
    });
    this.closeScanner();
  }

  isValidUrl(text: string): boolean {
    try { return Boolean(new URL(text)); }
    catch (e) { return false; }
  }

  scanSuccessHandler(result: string): void {
    let code;
    if (this.isValidUrl(result)) {
      const url = new URL(result);
      const params = new URLSearchParams(url?.search);
      code = params.get('s') ? params.get('s') : '';
    } else {
      code = result;
    }

    this.submitScanner.emit(code);
    this.closeScanner();
  }

  closeScanner(): void {
    this.toggleScannerModal.emit(false);
    this.hasDevices = false;
    this.hasPermission = false;
  }
}
