import { Component, Input } from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { ERROR_MESSAGE } from '../../config/error-message';
import { TranslateService } from "@ngx-translate/core";
import { ValidatorTemplateInterface } from "@app-shared/interfaces/error-message-template.interface";

@Component({
  selector: 'lib-control-errors',
  templateUrl: './control-errors.component.html',
  styleUrls: ['./control-errors.component.scss'],
})

export class ControlErrorsComponent {
  @Input() fieldName!: string | number | string[] | undefined;
  @Input() nameForm!: string | undefined;
  @Input() type!: 'text' | 'email' | 'password' | 'number' | 'select' | null;
  errorMessage: string = '';

  constructor(
    private rootFormGroup: FormGroupDirective,
    public translateService: TranslateService
  ) {
  }

  getErrorMessagesByFormType(nameForm: string | undefined, type: string, data: string | boolean | Record<string, string | number>) {
    if (nameForm && type && data) return ERROR_MESSAGE[nameForm][type](this.formField.getError(data));
  }

  interpolateErrorMessages(template: string, values: Record<string, string | number>) {

    return template.replace(/(\d:)?{([^}]+)}/g, function (match: string, param: string, placeholder: string) {

      let result = '';
      // Handles extended object params format
      if (placeholder && !Array.isArray(values)) {
        result = (placeholder in values) ? values[placeholder] as string : `{${placeholder}}`;
      }

      return result;
    });
  }

  mapErrorMessages(data: ValidatorTemplateInterface) {
    let errorTranslate;

    for (const [key, value] of Object.entries(data)) {

      if (key === 'messages' && value) errorTranslate = this.translateService.instant(value);

      if (key === 'params' && Object.keys(value).length) {
        errorTranslate = this.interpolateErrorMessages(errorTranslate, value);
      }
    }

    return errorTranslate;
  }

  transformKeyError(type: string, data: string | boolean | Record<string, string | number>) {
    let newKeyError: ValidatorTemplateInterface = {
      messages: '',
      params: {}
    }

    if (typeof data === 'string') {
      newKeyError.messages = data;

    } else if (typeof data === 'boolean') {
      newKeyError.messages = this.getErrorMessagesByFormType(this.nameForm, type, data);

    } else {
      for (const [key, value] of Object.entries(data)) {
        if (key === 'message' && value) newKeyError.messages = value as string;

        if (key === 'requiredInput' && value) newKeyError.params = {...value as Object}
      }
    }

    return newKeyError;
  }

  listOfErrors(): string | string[] {
    if (this.formField?.errors?.taken) {
      return [this.formField?.errors?.message];
    } else {
      for (const [key, value] of Object.entries(this.formField?.errors)) {
        let errorTransform: ValidatorTemplateInterface = {messages: ''};
        let valuePressed = value as string | boolean | Record<string, string | number>;

        errorTransform = this.transformKeyError(key, valuePressed);
        this.errorMessage = this.mapErrorMessages(errorTransform);
      }
      return this.errorMessage;
    }
  }

  get formField(): FormControl | any {
    return (this.rootFormGroup && this.rootFormGroup.control ? this.rootFormGroup.control.get(this.fieldName?.toString() as string) : undefined) as FormControl;
  }
}
