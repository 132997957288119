<!-- Notification -->
<div *ngIf="notificationService.isOpenNotification$ | async" class="notification-wrapper">
  <div class="notification" id="notification-one">
    <div class="notification-dialog">
      <div class="notification-header">
        <a (click)="onClose()"
           aria-hidden="true"
           class="btn-close" href="javascript:">×</a>

      </div>
      <div class="notification-body">
        <p *ngFor="let mess of (notificationService.message | async)">
          {{ mess | translate }}
        </p>
      </div>
    </div>
  </div>
</div>

