import { Component, OnInit } from '@angular/core';
import { CampaignDataService } from '@user/core/services';
import { isLanguageJapanese } from '@app-shared/config/language';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-already-applied-page',
  templateUrl: './already-applied-page.component.html',
  styleUrls: ['./already-applied-page.component.scss']
})
export class AlreadyAppliedPageComponent implements OnInit {
  isLanguageJapanese = isLanguageJapanese();
  customMessage: string | undefined = '';

  constructor(
    private campaignDataService: CampaignDataService,
    public router: Router,
  ) {
    this.customMessage = this.router.getCurrentNavigation()?.extras?.state?.error;
  }

  ngOnInit(): void {
    if (!this.customMessage) {
      this.campaignDataService.campaignInitialized.subscribe((campaign) => {
        this.customMessage = campaign?.settings?.error?.page_409_message;
      });
    }
  }

}
