<!-- Modal_Window -->
<div class="shared-modal-wrapper" *ngIf="isShowModal">
  <a
    href="javascript:;"
    class="modal-overlay">
  </a>

  <div class="modal-window">
    <div class="modal-content">
      <div class="modal-cancel" (click)="onCloseModal()"></div>

      <h2>{{ (confirmText || 'MILEAGE.MD_EXCHANGE_CONFIRM_MESSAGE') | translate }}</h2>

      <div class="btn-group">
        <input
          type="button"
          class="abtn"
          [disabled]="isSubmitted"
          [value]="(labelApply || 'MILEAGE.MD_EXCHANGE_BTN_APPLY') | translate"
          (click)="onApplyExchange()">

        <button
          type="button"
          class="abtn back_btn"
          (click)="onCloseModal()">
          <p>{{ (labelCancel || 'MILEAGE.MD_EXCHANGE_BTN_CLOSE') | translate }}</p>
        </button>
      </div>
    </div>
  </div>
</div>
<!-- //Modal_Window -->
