import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IOption } from '@app-shared/interfaces/option.interface';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-field-radio',
  template: `
    <!--Checkbox-->
    <div class="box_inner_lt">
      <div class="box_inner_bx" *ngFor="let option of to?.options | formlySelectOptions:field|async">
        <label>
          <p><input class="checkbox-input"
                    type="checkbox"
                    [name]="'q' + option.value + '_' + to?.data?.randomNumber"
                    [autocomplete]="'none'"
                    [value]="option.value"
                    [checked]="formControl?.value?.includes(option.value)"
                    [formlyAttributes]="field"
                    (change)="onChange(option.value, $event.target)"><span></span></p>
          <div class="list" [innerHTML]="option.label | safeHtml"></div>
          <p></p>
        </label>
      </div>
    </div>
  `,
})
export class FormlyFieldCheckboxComponent extends FieldType implements OnInit, AfterViewInit {
  constructor(
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  valueField: string[] = [];

  onChange(value: any, event: any) {
    this.formControl.patchValue((this.to?.options as []).map((op: IOption) => {
      if (op.value === value) {
        op.checked = event.checked;
      }
      return op;
    }).filter((v: IOption) => v.checked).map(_v => {
      return _v.value;
    }));
  }

  ngOnInit(): void {
    this.valueField = this.formControl.value;
  }

  ngAfterViewInit() {
    if (this.valueField?.length) {
      this.to.options = (this.to?.options as []).map((op: IOption) => {
        return Object.assign(op, {
          checked: this.valueField.includes(op?.value)
        });
      });
    }
    this.cdr.detectChanges();
  }
}

