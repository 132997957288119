export enum FileTypeEnum {
  JPG = 'image/jpeg',
  PNG = 'image/png',
  GIF = 'image/gif',
  PDF = 'application/pdf',
  WORD = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  POWERPOINT = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  CSV = 'text/csv',
}
