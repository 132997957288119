import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { GiftInactivatedPageComponent } from '@app-shared/static-page/gift-inactivated-page/gift-inactivated-page.component';
import {
  AuthGuard,
  CampaignFunctionGuard,
  CampaignOpenGuard,
  CampaignPublicGuard,
  CampaignReachedApplyLimitGuard,
  GateGuard,
  ReceiptGuard,
  CampaignStatusGuard
} from '@user/core/guards';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    canActivate: [
      CampaignFunctionGuard,
      CampaignPublicGuard,
    ],
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
    canActivate: [
      CampaignFunctionGuard,
      CampaignPublicGuard,
    ]
  },
  {
    path: 'form',
    loadChildren: () => import('./modules/form/form.module').then(m => m.FormModule),
    canActivate: [
      CampaignFunctionGuard,
      CampaignReachedApplyLimitGuard,
      CampaignStatusGuard
      // SecretCodeVerifyGuard works in FormGuard
    ],
  },
  {
    path: 'line-pay',
    loadChildren: () => import('./modules/line-pay/line-pay.module').then(m => m.LinePayModule),
    canActivate: [
      AuthGuard,
    ],
  },
  {
    path: 'zalo-pay',
    loadChildren: () => import('./modules/zalo-pay/zalo-pay.module').then(m => m.ZaloPayModule),
    canActivate: [
      AuthGuard,
    ],
  },
  {
    path: 'gate',
    loadChildren: () => import('./modules/secret-code/secret-code.module').then(m => m.SecretCodeModule),
    canActivate: [
      CampaignFunctionGuard,
      CampaignPublicGuard,
      CampaignOpenGuard,
      AuthGuard,
      GateGuard
    ],
  },
  {
    path: 'receipt',
    loadChildren: () => import('./modules/receipt/receipt.module').then(m => m.ReceiptModule),
    canActivate: [
      CampaignFunctionGuard,
      CampaignPublicGuard,
      CampaignOpenGuard,
      AuthGuard,
      ReceiptGuard
    ],
  },
  {
    path: 'lottery',
    loadChildren: () => import('./modules/lottery/lottery.module').then(m => m.LotteryModule),
    canActivate: [
      CampaignFunctionGuard,
      CampaignPublicGuard,
      CampaignOpenGuard,
      AuthGuard,
      // LotteryGuard, in canActivate Child Routing
    ],
  },
  {
    path: 'mileage',
    loadChildren: () => import('./modules/mileage/mileage.module').then(m => m.MileageModule),
    canActivate: [
      CampaignFunctionGuard,
      CampaignPublicGuard,
      CampaignOpenGuard,
      AuthGuard,
      // MileageGuard, in canActivate Child Routing
    ],
  },
  {
    path: 'history',
    loadChildren: () => import('./modules/history/history.module').then(m => m.HistoryModule),
    canActivate: [
      CampaignFunctionGuard,
      CampaignPublicGuard,
      CampaignOpenGuard,
      AuthGuard,
      // HistoryPublicGuard, in canActivate Child Routing
    ],
  },
  {
    path: 'end-page',
    loadChildren: () => import('./modules/end-page/end-page.module').then(m => m.EndPageModule),
  },
  {
    path: 'yM7SrnQg0p4l3N8z6qjc',
    loadChildren: () => import('./modules/secret-path/secret-path.module').then(m => m.SecretPathModule),
    canActivate: [
      CampaignFunctionGuard,
    ],
  },
  {
    path: 'gift-inactivated',
    component: GiftInactivatedPageComponent,
    canActivate: [
      CampaignPublicGuard,
      CampaignOpenGuard,
    ],
  },
  {
    path: 'static',
    loadChildren: () => import('projects/app-shared/src/lib/static-page/static-page.module').then(m => m.StaticPageModule),
  },
  {
    path: '**',
    redirectTo: '/static/not-found',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
