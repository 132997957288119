export enum ValidationEnum {
  'REQUIRED_NAME' = '入力内容に誤りがあります。',
  'REQUIRED_POSTCODE' = '入力内容に誤りがあります。',
  'REQUIRED_CITY' = '入力内容に誤りがあります。',
  'REQUIRED_ADDRESS' = '入力内容に誤りがあります。',
  'REQUIRED_BUILDING' = '入力内容に誤りがあります。',
  'REQUIRED_EMAIL' = '入力内容に誤りがあります。',
  'REQUIRED_PHONE' = '入力内容に誤りがあります。',
  'REQUIRED_PREFECTURE' = '都道府県を選択してください。',
  'LENGTH_NAME' = '20文字以内でご入力ください。',
  'LENGTH_POSTCODE' = '7文字以内でご入力ください。',
  'LENGTH_CITY' = '60文字以内でご入力ください。',
  'LENGTH_ADDRESS' = '60文字以内でご入力ください。',
  'LENGTH_BUILDING' = '60文字以内でご入力ください。',
  'LENGTH_PHONE' = '11文字以内でご入力ください。',
  'LENGTH_EMAIL' = '60文字以内でご入力ください。',
}

