import { Component, OnInit } from '@angular/core';
import { isLanguageJapanese } from '@app-shared/config/language';

@Component({
  selector: 'lib-user-reached-apply-limit-page',
  templateUrl: './user-reached-apply-limit-page.component.html',
  styleUrls: ['./user-reached-apply-limit-page.component.scss']
})
export class UserReachedApplyLimitPageComponent implements OnInit {
  isLanguageJapanese = isLanguageJapanese();

  constructor() {
  }

  ngOnInit(): void {
  }

}
