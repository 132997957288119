import { Injectable } from '@angular/core';
import { CampaignService } from '@user/core/services/campaign.service';
import { ICampaign } from '@user/core/interfaces';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as $ from 'jquery';
import { ReplaceNullWithTextPipe } from 'projects/app-shared/src/lib/pipes/replace-null-with-text.pipe';

@Injectable({
  providedIn: 'root'
})
export class CampaignDataService {
  private campaignObj?: ICampaign;
  private $campaign: ReplaySubject<ICampaign> = new ReplaySubject<ICampaign>();
  private $campaignInitialized: ReplaySubject<ICampaign> = new ReplaySubject<ICampaign>();

  get campaignInitialized(): Observable<ICampaign> {
    return this.$campaignInitialized.asObservable();
  }

  get campaign(): Observable<ICampaign> {
    return this.$campaign.asObservable();
  }

  constructor(
    private campaignService: CampaignService,
  ) {
    campaignService.detail().subscribe((resp) => {
      this.campaignObj = resp.data;
      this.$campaign.next(resp.data);
    });
  }

  removeCommonStyle(): void {
    const items = document.querySelectorAll('[data-setting="common"]');
    items.forEach((item) => {
      item.remove();
    });
  }

  applyCommonStyle(): Observable<boolean> {
    return this.campaign.pipe(
      map((campaign) => {
        this.removeCommonStyle();
        document.head.innerHTML += `
            <link data-setting="common" rel="stylesheet"
            href="${new ReplaceNullWithTextPipe().transform(campaign.settings?.common?.common_css)}"
            type="text/css"/>
        `;
        $('head').append(`<meta data-setting="common">
            ${new ReplaceNullWithTextPipe().transform(campaign.settings?.common?.common_head)}
        </meta>`);
        $('body').append(`<div data-setting="common_before_close_body_tag">
         ${new ReplaceNullWithTextPipe().transform(campaign.settings?.common?.common_before_close_body_tag)}
        </div>`);
        $('body').prepend(`<div data-setting="common_after_open_body_tag">
            ${new ReplaceNullWithTextPipe().transform(campaign.settings?.common?.common_after_open_body_tag)}
        </div>`);
        return true;
      }),
    );
  }

  setBodyClass(classes: string): void {
    $('body').addClass(classes);
  }

  appendElement(elFirst: JQuery<HTMLElement>, elInsert: string, functionHtml: any): void {
    /*function_html : after, before*/
    switch (functionHtml) {
      case 'after': {
        elFirst.after(elInsert);
        break;
      }
      case 'before': {
        elFirst.before(elInsert);
        break;
      }
    }
  }

  settingLayout(pageName: string, layout: any): void {
    const commonBeforeBody = $('body div[data-setting=common_before_close_body_tag]');
    const commonAfterBody = $('body div[data-setting=common_after_open_body_tag]');
    const beforeBody = commonBeforeBody.length ? commonBeforeBody : $('body');
    const afterBody = commonAfterBody.length ? commonAfterBody : $('body');
    if (layout?.[`${pageName}_head`]) {
      $('head').append(`<span data-setting="${pageName}">${layout?.[`${pageName}_head`]}</span>`);
    }
    if (layout?.[`${pageName}_after_open_body_tag`]) {
      this.appendElement(
        afterBody,
        `<div data-setting="${pageName}">${layout?.[`${pageName}_after_open_body_tag`]}</div>`,
        commonBeforeBody.length ? 'after' : 'before'
      );
    }
    if (layout?.[`${pageName}_before_close_body_tag`]) {
      this.appendElement(
        beforeBody,
        `<div data-setting="${pageName}">${layout?.[`${pageName}_before_close_body_tag`]}</div>`,
        commonBeforeBody.length ? 'before' : 'after'
      );
    }
  }

  setCampaignInitialized(): void {
    this.$campaignInitialized.next(this.campaignObj);
  }

}
