import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IOstiariesAspSetting } from '@user/core/interfaces/setting/ostiaries-asp-setting.interface';
import * as CountryCode from '@app-shared/CountryCodes.json';
import { OstiariesAspService } from '@user/core/services/ostiaries-asp.service';
import { OstiariesAspMethod } from '@user/core/enums/ostiaries-asp';
import * as jQuery from 'jquery';
import { catchError } from 'rxjs/operators';
import { IBlockOstiariesAspForm } from '@user/core/interfaces/block';
import { throwError } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { HttpErrorCodeEnum } from '@app-shared/enums';

declare var window: Window & typeof globalThis & {
  $?: JQueryStatic;
  call_auth_run(tel: string): void;
};

interface ICountryCode {
  name: string;
  dial_code: string;
  code: string;
}

@Component({
  selector: 'lib-ostiaries-asp-form',
  templateUrl: './ostiaries-asp-form.component.html',
  styleUrls: ['./ostiaries-asp-form.component.scss']
})
export class OstiariesAspFormComponent implements OnInit, OnDestroy {
  @Input() setting?: IOstiariesAspSetting;
  @Input() customizeSetting: IBlockOstiariesAspForm | null = null;
  currentMode: OstiariesAspMethod = OstiariesAspMethod.DOMESTIC;
  OstiariesAspMethod = OstiariesAspMethod;
  countryCode: ICountryCode[] = (CountryCode as any).default;
  selectedCountryCode?: ICountryCode;
  phoneNumberControl = new FormControl('');
  isLoading = false;

  get phoneNumber(): string {
    return this.phoneNumberControl.value;
  }

  constructor(private ostiariesAspService: OstiariesAspService) {
  }

  ngOnInit(): void {
    window.$ = jQuery;
    if (this.countryCode.length > 0) {
      this.selectedCountryCode = this.countryCode[0];
    }

    if (this.setting?.ostiaries_asp_method === OstiariesAspMethod.OVERSEAS) {
      this.currentMode = OstiariesAspMethod.OVERSEAS;
    }

    this.validatePhoneNumber();
  }

  initScript(mode: OstiariesAspMethod, phoneNumber: string, paramToken: string, return_url: string): void {
    if (!this.setting) {
      throw new Error('Missing setting!');
    }
    let okUrl = `${window.location.origin}/web/user/auth/phone-numbers/verify-param?param_token=${paramToken + '?' + return_url}`;
    const backUrl = `${window.location.origin}/auth/call`;
    if (mode === OstiariesAspMethod.DOMESTIC) {
      okUrl += `&method=${OstiariesAspMethod.DOMESTIC}`;
      this.ostiariesAspService.initScript(this.setting.ostiaries_asp_domestic_id, okUrl, backUrl);
    } else if (mode === OstiariesAspMethod.OVERSEAS) {
      okUrl += `&method=${OstiariesAspMethod.OVERSEAS}`;
      this.ostiariesAspService.initScript(this.setting.ostiaries_asp_overseas_id, okUrl, backUrl);
    }
  }

  switchMode(): void {
    this.phoneNumberControl.reset();
    if (this.currentMode === OstiariesAspMethod.DOMESTIC) {
      this.currentMode = OstiariesAspMethod.OVERSEAS;
    } else {
      this.currentMode = OstiariesAspMethod.DOMESTIC;
    }
    this.validatePhoneNumber();
  }

  ngOnDestroy(): void {
    this.ostiariesAspService.destroyScript();
  }

  submit(): void {
    this.isLoading = true;
    let phoneNumberSerialized = this.phoneNumber;
    if (this.currentMode === OstiariesAspMethod.OVERSEAS) {
      phoneNumberSerialized = this.selectedCountryCode?.dial_code + this.phoneNumber;
    }
    this.ostiariesAspService
      .initializePhoneNumber({
        area_code: this.currentMode === OstiariesAspMethod.OVERSEAS ? this.selectedCountryCode?.dial_code : '',
        phone_number: this.phoneNumber
      })
      .pipe(
        catchError(err => {
          if (err.status === HttpErrorCodeEnum.UNPROCESSABLE_CONTENT) {
            this.phoneNumberControl.setErrors({
              taken: true,
              message: err.error?.errors[0]?.message[0]
            });
          }
          this.isLoading = false;
          return throwError(err);
        })
      )
      .subscribe(resp => {
        const paramToken = resp.data.param_token;
        if (this.setting) {
          this.initScript(this.currentMode, phoneNumberSerialized, paramToken, sessionStorage.getItem('return_url') || '');
          setTimeout(() => {
            window.call_auth_run(phoneNumberSerialized);
          }, 1000);
        }
      });
  }

  validatePhoneNumber() {
    this.phoneNumberControl.clearValidators();
    if (this.currentMode === OstiariesAspMethod.DOMESTIC) {
      this.phoneNumberControl.setValidators([
        Validators.required,
        Validators.pattern('^[0-9]+$'),
        Validators.minLength(10),
        Validators.maxLength(12),
      ]);
    } else {
      this.phoneNumberControl.setValidators([
        Validators.required,
        Validators.pattern('^[0-9]+$'),
        Validators.minLength(1),
        Validators.maxLength(14),
      ]);
    }
    this.phoneNumberControl.updateValueAndValidity();
  }
}
