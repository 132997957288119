import { Injectable } from '@angular/core';
import { ApiBase } from '@user/core/services/api.service';
import { apiEndpoints } from '@user/config/global-vars';
import { Observable } from 'rxjs';
import { IInactiveCode } from '@user/core/interfaces';

@Injectable({
  providedIn: 'root'
})
export class InactiveCodeService {

  constructor(
    private apiBase: ApiBase,
  ) {
  }

  saveInactiveCode(body: IInactiveCode): Observable<any> {
    return this.apiBase.post(apiEndpoints.inactive_code + '?on_screen_error=1', body);
  }
}
