import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, NavigationExtras, Router, RouterStateSnapshot } from '@angular/router';
import { HttpErrorCodeEnum } from '@app-shared/enums';
import { CampaignOpenGuard } from '@user/core/guards/campaign-open.guard';
import { ApplyAuthMethodEnum, CampaignTypeEnum, FormUsingTypeEnum, ICampaign } from '@user/core/interfaces';
import { AuthService, SecretCodeService, SecretPathService } from '@user/core/services';
import { CampaignDataService } from '@user/core/services/campaign-data.service';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, first, map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GateGuard implements CanActivate {

  campaign!: ICampaign;

  constructor(
    private campaignDataService: CampaignDataService,
    private secretCodeService: SecretCodeService,
    private router: Router,
    private campaignOpenGuard: CampaignOpenGuard,
    private secretPathService: SecretPathService,
    private authService: AuthService,
  ) {
  }

  redirectAndReturnFalse(urls = ['static', 'not-found'], extras = {}): Observable<boolean> {
    this.router.navigate(urls, extras);
    return of(false);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    const obsList = <Observable<any>[]>[this.campaignOpenGuard.canActivate(route, state), this.campaignDataService.campaignInitialized]
    const user = this.authService.currentUser;
    if (!user) obsList.push(this.authService.profile())
    return combineLatest([
      ...obsList
    ]).pipe(
      first(),
      mergeMap(([result, campaign]) => {
        if (!result) {
          return of(false);
        }
        this.campaign = campaign;

        // Allow access form and don't check code exist if Campaign Lottery or Mileage and user not yet input survey
        const user = this.authService.currentUser;
        if ((campaign?.campaign_type === CampaignTypeEnum.LOTTERY || campaign?.campaign_type === CampaignTypeEnum.MILEAGE)
          && campaign?.form_using_type === FormUsingTypeEnum.FORM && user && user.total_apply <= 0) {
            const secretPath = this.secretPathService.isSecretPath() ? this.secretPathService.secretPath : '';
            const returnUrl = state?.url || null;
            const extras: NavigationExtras = {};
            if (returnUrl && !returnUrl.includes('gate')) {
              extras.queryParams = { return_url: returnUrl };
            }
          this.redirectAndReturnFalse([secretPath, 'form'], extras)
        }
        return of(true);
      }),
    )
  }
}
