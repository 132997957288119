import { Component, OnInit } from '@angular/core';
import { CampaignDataService } from '@user/core/services';
import { isLanguageJapanese } from '@app-shared/config/language';

@Component({
  selector: 'lib-expired',
  templateUrl: './expired.component.html',
  styleUrls: ['./expired.component.scss']
})
export class ExpiredComponent implements OnInit {
  isLanguageJapanese = isLanguageJapanese();
  customMessage: string | undefined = '';

  constructor(
    private campaignDataService: CampaignDataService,
  ) {
  }

  ngOnInit(): void {
    this.campaignDataService.campaignInitialized.subscribe((campaign) => {
      this.customMessage = campaign?.settings?.error?.page_401_message;
    });
  }
}
