import {Injectable} from '@angular/core';
import {ApiBase} from '@user/core/services/api.service';
import {ApplyAuthMethodEnum, CampaignTypeEnum, ICampaign, IResponse} from '@user/core/interfaces';
import {apiEndpoints} from '@user/config/global-vars';
import {AttributeData, AttributeOption, CommonQuestionInterface} from '@user/core/interfaces/question';
import {map} from 'rxjs/operators';
import {combineLatest, Observable, Subject} from 'rxjs';
import {FieldTypeEnum} from '@app-shared/enums';
import {decodeEntity as htmlEntityDecode, encode as htmlEntityEncode} from 'html-entities';
import {ISubmitDataResponse} from '@user/core/interfaces/form';
import {SecretCodeService} from '@user/core/services/secret-code.service';
import {CampaignDataService} from '@user/core/services/campaign-data.service';
import {QuestionAddressType, QuestionFileType, QuestionMultipleValueType, QuestionValueType} from '@user/config/question';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {CustomParameterService} from '@user/core/services';
import { StorageService } from '../storage.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {
  closeCampaign = new Subject();
  targetProduct = new Subject();

  constructor(
    private apiBase: ApiBase,
    private secretCodeService: SecretCodeService,
    private campaignDataService: CampaignDataService,
    private customParameterService: CustomParameterService,
    private storageService: StorageService
  ) {
  }

  getAllQuestion() {
    return this.apiBase.get<IResponse<CommonQuestionInterface<AttributeData<AttributeOption>
      | AttributeOption>>>(apiEndpoints.questions, {
      with: 'children.children'
    }).pipe(
      map(({data}) => {
        return data;
      })
    );
  }

  confirmQuestion(body: any) {
    return this.apiBase.post(apiEndpoints.form_confirm, body).pipe(
      map((resp: any) => {
        return resp.data;
      })
    );
  }

  applyForm(body: any): Observable<ISubmitDataResponse> {
    return combineLatest([
      this.apiBase.post<IResponse<ISubmitDataResponse>>(apiEndpoints.apply_form, body),
      this.campaignDataService.campaignInitialized,
    ]).pipe(
      map(([resp, campaign]) => {
        if (body.get('code')) {
          this.storageService.save('temp_apply_code', body.get('code'));
        }
        this.removeSecretCode(campaign);
        if (resp.data && resp.data.gift) {
          this.secretCodeService.saveGiftInformation(resp.data.gift);
        }
        return resp.data;
      }),
    );
  }

  getOCR(file: FormData, question_id: string | number | string[] | undefined) {
    return this.apiBase.post(apiEndpoints.ocr_image + question_id + '/target-product', file).pipe(
      map((resp: any) => {
        return resp.data;
      })
    );
  }

  getFormDataQuestion(questions: any, campaign: ICampaign | null): FormData {
    const formData = new FormData();
    questions.forEach(({id, type, value, options}: never | any) => {
      switch (type) {
        /*MULTIPLE_INPUT, CHECKBOX*/
        case FieldTypeEnum.MULTIPLE_INPUT:
        case FieldTypeEnum.CHECKBOX: {
          QuestionMultipleValueType(formData, id, value);
          break;
        }
        /*ADDRESS*/
        case FieldTypeEnum.ADDRESS: {
          QuestionAddressType(formData, id, value);
          break;
        }

        /*FILE*/
        case FieldTypeEnum.FILE: {
          QuestionFileType(formData, id, value, options?.file_format ? 'file' : 'image');
          break;
        }

        /*INPUT, TEXTAREA, RADIO, SEGMENTED, SELECT*/
        default: {
          QuestionValueType(formData, id, value);
        }
      }
    });

    const code = this.getStoreCode(campaign);

    if (code !== '') {
      formData.append('code', code);
    }

    const customParameters = this.customParameterService.getCustomParameters();

    for (const key of Object.keys(customParameters)) {
      formData.append(`custom_parameters[${key}]`, customParameters[key]);
    }

    return formData;
  }

  getStoreCode(campaign: ICampaign | null): string {
    if (campaign !== null
      && campaign.campaign_type === CampaignTypeEnum.NORMAL
      && this.secretCodeService.checkApplyAuthMethod(campaign?.apply_auth_method)) {
      return this.secretCodeService.getStoreValue(campaign?.apply_auth_method) || '';
    }
    return '';
  }

  getKeyQuestionBasic(id: string | number, isArray = false): string {
    return isArray ? `question[${id}][value][]` : `question[${id}][value]`;
  }

  isEmpty(value: any) {
    return (typeof value == 'undefined' || value == null || !value || value == 'null');
  }

  htmlEntityEncode(str: string): string {
    return htmlEntityEncode(str);
  }

  htmlEntityDecode(str: string): string {
    return htmlEntityDecode(str);
  }

  htmlEntityDecodeFromArray(strArray: string[]): string[] {
    if (strArray.length === 0) {
      return strArray;
    }
    return strArray.map((val) => {
      return this.htmlEntityDecode(val);
    });
  }

  removeSecretCode(campaign: ICampaign): void {
    if (!campaign
        || campaign.campaign_type !== CampaignTypeEnum.NORMAL
        || !(campaign.apply_auth_method === ApplyAuthMethodEnum.SERIAL
        || campaign.apply_auth_method === ApplyAuthMethodEnum.HITAP
        || campaign.apply_auth_method === ApplyAuthMethodEnum.PASSWORD
        || campaign.apply_auth_method === ApplyAuthMethodEnum.UNIQUE)
    ) {
      return;
    }

    this.secretCodeService.removeStoreKey(campaign.apply_auth_method);
  }

  getField(key: string, fields: FormlyFieldConfig[]): FormlyFieldConfig | null {
    for (let i = 0, len = fields.length; i < len; i++) {
      const f = fields[i];
      if (f.key === key) {
        return f;
      }

      if (f.fieldGroup && !f.key) {
        const cf = this.getField(key, f.fieldGroup);
        if (cf) {
          return cf;
        }
      }
    }
    return null;
  }
}
