import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';
import { CampaignDataService } from '@user/core/services';
import { GiftTypeEnum } from '../enums/gift';

@Injectable({
  providedIn: 'root'
})
export class ZaloPayAuthGuard implements CanActivate {

  constructor(
    private campaignDataService: CampaignDataService,
    private router: Router,

  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.campaignDataService.campaignInitialized.pipe(
      first(),
      mergeMap((campaign) => {
        // Redirect to Not Found page if campaign not exist
        if (!campaign) {
          this.router.navigate(['static', 'not-found']);
          return of(false);
        }

        if (campaign.gift_type === GiftTypeEnum.ZALO_PAY) {
          return of(true);
        }
        return of(false);
      }
      ))
  }
}
