import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxQrcodeElementTypes } from '@techiediaries/ngx-qrcode';
import { GiftDisplayTypeEnum, GiftTypeEnum } from '@user/core/enums/gift';
import { LotteryResultBlockTypeEnum } from '@user/core/enums/lottery';
import { ApplyAuthMethodEnum, CampaignTypeEnum, ICampaign, IInactiveCode, ISection, IUser } from '@user/core/interfaces';
import { BlockButtonStyleEnum, IBlock } from '@user/core/interfaces/block';
import { IGiftAdditionalData } from '@user/core/interfaces/form';
import { IOstiariesAspSetting } from '@user/core/interfaces/setting/ostiaries-asp-setting.interface';
import { CameraImage } from '@user/shared/utils/camera.util';
import { BlockButtonTypeEnum, BlockPageToLinkEnum, BlockTypeEnum, GivePointMethodTypeEnum } from '../../enums';
import { ApiBase, SecretCodeService } from '@user/core/services';
import { map } from 'rxjs/operators';
import { IIDPWSetting } from '@user/core/interfaces/setting/id-pw-setting.interface';
import { apiEndpoints } from '@user/config/global-vars';
import { StorageService } from '@user/core/services/storage.service';

@Component({
  selector: 'lib-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})
export class SectionComponent implements OnInit {
  // TODO: Should group section attributes by object or something better
  @Input() sectionId = '';
  @Input() section!: ISection;
  @Input() isSubmitted = false;

  // Block Serial Password
  @Input() errorMessage = '';
  @Input() secretCode = '';
  @Output() secretCodeChange = new EventEmitter<string>();
  @Output() submitGateForm = new EventEmitter<void>();

  // Block input serial / password
  @Input() defaultGateInputLabel?: string;
  @Input() defaultGateButtonTitle?: string;

  // Block Gift
  @Input() giftCode = '';
  @Input() giftDisplayType: GiftDisplayTypeEnum = GiftDisplayTypeEnum.TEXT;
  @Input() giftAdditionalData?: IGiftAdditionalData;
  @Input() giftType ?: GiftTypeEnum;

  // QR Code Gift
  qrElementType: NgxQrcodeElementTypes = NgxQrcodeElementTypes.URL;

  // Line Pay Member Provide Consent
  @Output() submitAgreeLinePayMemberProvide: EventEmitter<void> = new EventEmitter();

  // Ostiaries ASP
  @Input() ostiariesAspSetting?: IOstiariesAspSetting;

  // ID/PW Form
  @Input() idAuthSetting?: IIDPWSetting

  // Lottery
  @Output() submitLottery = new EventEmitter<void>();
  @Input() isDisabledLotterySubmit = false;
  @Output() isDisabledLotterySubmitChange = new EventEmitter<boolean>();

  // Lottery result
  @Input() lotteryResult = '';

  // Mileage
  @Input() campaign?: ICampaign;
  @Input() profile?: IUser;
  @Input() cameraImage: CameraImage | undefined;
  @Input() errorCamera: any;
  @Input() errorFile: any;
  @Output() submitMileage = new EventEmitter<any>();
  @Output() handleShowScanner = new EventEmitter<boolean>();
  @Output() handleShowCamera = new EventEmitter<boolean>();
  @Output() handleShowExchangeConfirm = new EventEmitter<any>();
  @Output() handleCheckOCR = new EventEmitter<void>();
  @Output() handleInactiveCode = new EventEmitter<any>();
  @Output() handleCloseModal = new EventEmitter<boolean>();

  // Inactive code
  @Input() errorMessageInactiveCode = '';

  //Complete section
  @Input() codeApply = '';

  //Inactivation Code Modal
  @Input() isShowModalInactivate = false;

  buttonBlockSystemType: any = {
    [BlockPageToLinkEnum.HISTORY]: '/history',
    [BlockPageToLinkEnum.LANDING_PAGE]: '/',
    [BlockPageToLinkEnum.MY_PAGE]: '/mileage/my-page',
    [BlockPageToLinkEnum.FORM]: '/form',
    [BlockPageToLinkEnum.LOTTERY]: '/lottery',
    'no-link': ''
  };
  errorMessagesCamera: any = {
    NotAllowedError: 'MILEAGE.ERROR_CAMERA.NOT_ALLOWED_ERROR',
    NotFoundError: 'MILEAGE.ERROR_CAMERA.NOT_FOUND_ERROR',
    NotReadableError: 'MILEAGE.ERROR_CAMERA.NOT_READABLE_ERROR',
    OverconstrainedError: 'MILEAGE.ERROR_CAMERA.OVERCONSTRAINED_ERROR',
    StreamApiNotSupportedError: 'MILEAGE.ERROR_CAMERA.STREAM_API_NOT_SUPPORTED_ERROR',
    Default: 'MILEAGE.ERROR_CAMERA.DEFAULT'
  };
  secretPath = '';
  requestPoints = new FormControl('');
  requestCode = new FormControl('');
  requestInactivationCode = new FormControl('', [Validators.required]);

  readonly BlockTypeEnum = BlockTypeEnum;
  readonly BlockButtonStyleEnum = BlockButtonStyleEnum;
  readonly GiftDisplayTypeEnum = GiftDisplayTypeEnum;
  readonly LotteryResultBlockTypeEnum = LotteryResultBlockTypeEnum;
  readonly BlockButtonTypeEnum = BlockButtonTypeEnum;
  readonly GivePointMethodTypeEnum = GivePointMethodTypeEnum;
  readonly GiftTypeEnum = GiftTypeEnum;

  get mileagePoints(): string {
    return this.requestPoints.value;
  }

  get mileageCode(): string {
    return this.requestCode.value;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private apiBase: ApiBase,
    private secretCodeService: SecretCodeService
  ) {
    this.secretPath = this.activatedRoute.snapshot.data.secretPath || '';
  }

  ngOnInit(): void {
    if (this.campaign?.campaign_type === CampaignTypeEnum.MILEAGE) {
      if ([
        GivePointMethodTypeEnum.RECEIPT_DECLARATION,
        GivePointMethodTypeEnum.RECEIPT_APPROVAL
      ].includes(this.campaign?.settings?.common?.mileage_give_point_method as GivePointMethodTypeEnum)) {
        this.validateRequestPoints();
      }

      if ([
        GivePointMethodTypeEnum.SERIAL,
        GivePointMethodTypeEnum.JAN_CODE
      ].includes(this.campaign?.settings?.common?.mileage_give_point_method as GivePointMethodTypeEnum)) {
        this.requestCode.setValue(this.secretCode);
        this.validateRequestCode();
      }
    }
    if (this.router.url && this.router.url.includes('/form/complete')) {
      this.section.forEach((item) => {
        if (item.type === BlockButtonTypeEnum.CHIBI_JOB || item.type === BlockButtonTypeEnum.OTHER_SITES)
          this.apiBase.post(apiEndpoints.user_generate_url, { ...item, code: this.codeApply }).pipe(
            map((resp: any) => {
              return resp.data;
            })
          ).subscribe((result) => {
            if (result) {
              item.redirect_link = result.url
            }
          });
      });
    }
  }

  onSecretCodeChange(): void {
    this.secretCodeChange.emit(this.secretCode);
  }

  onSubmitGateForm(): void {
    this.submitGateForm.emit();
  }

  onSubmitAgreeLinePayMemberProvide(): void {
    this.submitAgreeLinePayMemberProvide.emit();
  }

  onSubmitLottery(): void {
    if (! this.isDisabledLotterySubmit) {
      this.submitLottery.emit();
    }
  }

  onIsDisabledLotterySubmitChange(val: boolean): void {
    this.isDisabledLotterySubmit = val;
    this.isDisabledLotterySubmitChange.emit(val);
  }

  /**
   * Functions for Campaign Mileage
   */
  enableScanner(): void {
    this.handleShowScanner.emit(true);
  }

  enableCamera(): void {
    this.handleShowCamera.emit(true);
  }

  enableExchangeConfirm(campaign: any): void {
    this.handleShowExchangeConfirm.emit({ value: true, ...campaign });
  }

  replaceTextInString(text: string = ''): string {
    return text.replace(/##available_points##/g, String(this.profile?.available_points || 0))
      .replace(/##used_points##/g,  String(this.profile?.used_points || 0))
      .replace(/##total_points##/g, String(this.profile?.total_points || 0));
  }

  onSubmitMileage(earnMethod: string): void {
    const code = [
      GivePointMethodTypeEnum.JAN_CODE, GivePointMethodTypeEnum.SERIAL
    ].includes(earnMethod as GivePointMethodTypeEnum) ? this.mileageCode : this.mileagePoints;
    this.secretCodeChange.emit(code);

    if (earnMethod === GivePointMethodTypeEnum.RECEIPT_APPROVAL && this.campaign?.settings?.common?.mileage_ocr_using) {
      this.handleCheckOCR.emit();
    } else {
      this.submitMileage.emit(earnMethod);
    }
  }

  validateRequestCode(): void {
    this.requestCode.clearValidators();
    this.requestCode.setValidators([
      Validators.required,
      Validators.pattern('^[^\\;\\<\\=\\>\\/\\[\\]\\{\\|\\}\\\\]+$'),
      Validators.maxLength(1000),
    ]);
    this.requestCode.updateValueAndValidity();
  }

  validateRequestPoints(): void {
    this.requestPoints.clearValidators();
    if (this.campaign?.settings?.common?.mileage_give_point_method === GivePointMethodTypeEnum.RECEIPT_DECLARATION) {
      this.requestPoints.setValidators([
        Validators.required,
        Validators.pattern('^[0-9]+$'),
        Validators.maxLength(5),
      ]);
    }
    if (this.campaign?.settings?.common?.mileage_give_point_method === GivePointMethodTypeEnum.RECEIPT_APPROVAL) {
      this.requestPoints.setValidators([
        Validators.pattern('^[0-9]+$'),
        Validators.maxLength(5),
      ]);
    }
    this.requestPoints.updateValueAndValidity();
  }

  /**
   * END - Functions for Campaign Mileage
   */

  onInactiveCode(type: any, confirmed: boolean): void {
    const body: IInactiveCode = {
      confirmed: confirmed
    };
    if (type !== BlockTypeEnum.BUTTON) {
      body.inactive_code = this.requestInactivationCode.value;
    }
    this.handleInactiveCode.emit({ body, isShowModal: confirmed });
  }

  getTextLabel () {
    return this.giftType && this.giftType === GiftTypeEnum.PAYPAY ? 'RECEIVING_GIFT_PAYPAY' : 'RECEIVING_GIFT';
  }

  getConditionDisabled (block: IBlock): boolean {
    if (!block.hasOwnProperty('is_disabled')) {
      return true
    }
    return block && block.hasOwnProperty('is_disabled') && !!block?.is_disabled
  }

  closeModal() {
    this.handleCloseModal.emit(false);
  }
}
