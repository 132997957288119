import { Injectable } from '@angular/core';
import {StorageService} from '@user/core/services/storage.service';
import {Params} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CustomParameterService {
  protected customParameterStoreKey = 'custom_params_raw';
  protected customParameters: string[] = [];
  constructor(
    protected storageService: StorageService,
  ) { }

  public getCustomParameters(): { [key: string]: string } {
    const rawCustomParameters = this.storageService.get(this.customParameterStoreKey);

    return JSON.parse(rawCustomParameters || '{}');
  }

  public removeCustomParameters(): void {
    this.storageService.remove(this.customParameterStoreKey);
  }

  public saveCustomParameters(customParameters: string[], queryParams: Params & { [key: string]: unknown }): void {
    const paramObj = this.parseCustomParametersFromQueryParams(customParameters, queryParams);
    this.storageService.save(this.customParameterStoreKey, JSON.stringify(paramObj));
  }

  public parseCustomParametersFromQueryParams(
    customParameters: string[],
    queryParams: Params & { [key: string]: unknown }
  ): { [key: string]: unknown } {
    // Check parameters
    this.customParameters = customParameters;
    const paramObj: { [key: string]: unknown } = {};
    this.customParameters.forEach((key) => {
      if (queryParams && typeof queryParams[key] !== 'undefined') {
        paramObj[key] = queryParams[key];
      }
    });
    return paramObj;
  }

  public checkCustomParamExists(customParamList: string[], queryParams: Params & { [key: string]: unknown }): boolean {
    return Object.keys(queryParams).some((p: string) => customParamList.includes(p));
  }

  public getCustomParamsFromQueryParams(
    customParamList: string[], queryParams: Params & { [key: string]: unknown }
  ): string[] {
    return Object.keys(queryParams).filter((p: string) => customParamList.includes(p));
  }
}
