import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AuthInterceptor } from './intercepters/auth.interceptor';
import { LoadingOverlayInterceptor } from './intercepters/loading-overlay-interceptor.service';
import { ErrorInterceptor } from '@user/core/intercepters/error.interceptor';
import { ApiBase, CampaignService } from '@user/core/services';
import { environment } from '@user/environment';

const SERVICES = [
  ApiBase,
  CampaignService,
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  exports: [HttpClientModule, RouterModule],
  providers: [
    ...SERVICES,
    {
      provide: 'env', // you can also use InjectionToken
      useValue: environment
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingOverlayInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
  ]
})
export class CoreModule {
}
