import {Injectable} from '@angular/core';
import {ApiBase} from '@user/core/services/api.service';
import {Observable} from 'rxjs';
import {apiEndpoints} from '@user/config/global-vars';
import {IResponse} from '@user/core/interfaces';

@Injectable({
  providedIn: 'root'
})
export class MileageService {

  constructor(
    private apiBase: ApiBase,
  ) {
  }

  earnPoints(path: string, formData: FormData, params: any): Observable<IResponse<any>> {
    return this.apiBase.post(path, formData, { params });
  }

  checkOCR(formData: FormData, params: any): Observable<IResponse<any>> {
    return this.apiBase.post(apiEndpoints.check_ocr, formData, { params });
  }

  exchangePoints(data: any, params: any): Observable<IResponse<any>> {
    return this.apiBase.post(apiEndpoints.exchange_points, data, { params });
  }

  getSectionBlock ():  Observable<any> {
    return this.apiBase.get(apiEndpoints.section_block);
  }

}
