import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CameraImage, CameraUtil } from '@user/shared/utils/camera.util';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements OnInit, AfterViewInit, OnDestroy {

  /**
   * If the given Observable emits, an image will be captured and emitted through 'imageCapture' EventEmitter
   */
  @Input()
  public set trigger(trigger: Observable<void>) {
    if (this.triggerSubscription) {
      this.triggerSubscription.unsubscribe();
    }

    // Subscribe to events from this Observable to take snapshots
    this.triggerSubscription = trigger.subscribe(() => {
      this.takeSnapshot();
    });
  }

  /**
   * If the given Observable emits, the active camera will be switched to the one indicated by the emitted value.
   * @param switchCamera Indicates which camera to switch to
   *   true: cycle forwards through available cameras
   *   false: cycle backwards through available cameras
   *   string: activate the camera with the given id
   */
  @Input()
  public set switchCamera(switchCamera: Observable<boolean | string>) {
    if (this.switchCameraSubscription) {
      this.switchCameraSubscription.unsubscribe();
    }

    // Subscribe to events from this Observable to switch video device
    this.switchCameraSubscription = switchCamera.subscribe((value: boolean | string) => {
      if (typeof value === 'string') {
        // deviceId was specified
        this.switchToVideoInput(value);
      } else {
        // direction was specified
        this.rotateVideoInput(value !== false);
      }
    });
  }

  public get videoWidth(): number {
    const videoRatio = this.getVideoAspectRatio();
    return Math.min(this.width, this.height * videoRatio);
  }

  public get videoHeight(): number {
    const videoRatio = this.getVideoAspectRatio();
    return Math.min(this.height, this.width / videoRatio);
  }

  public get videoStyleClasses(): string {
    let classes = '';

    if (this.isMirrorImage()) {
      classes += 'mirrored ';
    }

    return classes.trim();
  }

  public get nativeVideoElement(): any {
    return this.video.nativeElement;
  }

  private static DEFAULT_VIDEO_OPTIONS: MediaTrackConstraints = {facingMode: 'environment'};
  private static DEFAULT_IMAGE_TYPE = 'image/jpeg';
  private static DEFAULT_IMAGE_QUALITY = 0.92;

  @Input() isShowCamera = false;

  /** Defines the max width of the camera area in px */
  @Input() public width = 640;
  /** Defines the max height of the camera area in px */
  @Input() public height = 480;
  /** Defines base constraints to apply when requesting video track from UserMedia */
  @Input() public videoOptions: MediaTrackConstraints = CameraComponent.DEFAULT_VIDEO_OPTIONS;
  /** Flag to enable/disable camera switch. If enabled, a switch icon will be displayed if multiple cameras were found */
  @Input() public allowCameraSwitch = true;
  /** Parameter to control image mirroring (i.e. for user-facing camera). ["auto", "always", "never"] */
  @Input() public mirrorImage: any;
  /** Flag to control whether an ImageData object is stored into the WebcamImage object. */
  @Input() public captureImageData = false;
  /** The image type to use when capturing snapshots */
  @Input() public imageType: string = CameraComponent.DEFAULT_IMAGE_TYPE;
  /** The image quality to use when capturing snapshots (number between 0 and 1) */
  @Input() public imageQuality: number = CameraComponent.DEFAULT_IMAGE_QUALITY;

  /** EventEmitter which fires when an image has been captured */
  @Output() public imageCapture: EventEmitter<any> = new EventEmitter<any>();
  /** Emits a mediaError if camera cannot be initialized (e.g. missing user permissions) */
  @Output() public initError: EventEmitter<any> = new EventEmitter<any>();
  /** Emits when the camera video was clicked */
  @Output() public imageClick: EventEmitter<void> = new EventEmitter<void>();
  /** Emits the active deviceId after the active video device was switched */
  @Output() public cameraSwitched: EventEmitter<string> = new EventEmitter<string>();

  /** available video devices */
  public availableVideoInputs: MediaDeviceInfo[] = [];

  /** Indicates whether the video device is ready to be switched */
  public videoInitialized = false;

  /** If the Observable represented by this subscription emits */
  /** an image will be captured and emitted through the 'imageCapture' EventEmitter */
  private triggerSubscription: Subscription | undefined;
  /** Index of active video in availableVideoInputs */
  private activeVideoInputIndex = -1;
  /** Subscription to switchCamera events */
  private switchCameraSubscription: Subscription | undefined;
  /** MediaStream object in use for streaming UserMedia data */
  private mediaStream: MediaStream | null = null;
  @ViewChild('video', { static: true }) private video: any;
  /** Canvas for Video Snapshots */
  @ViewChild('canvas', { static: true }) private canvas: any;

  /** width and height of the active video stream */
  private activeVideoSettings: MediaTrackSettings | null = null;

  /**
   * Get MediaTrackConstraints to request streaming the given device
   * @param baseMediaTrackConstraints base constraints to merge deviceId-constraint into
   */
  private static getMediaConstraintsForDevice(deviceId: string, baseMediaTrackConstraints: MediaTrackConstraints): MediaTrackConstraints {
    const result: MediaTrackConstraints = baseMediaTrackConstraints ? baseMediaTrackConstraints : this.DEFAULT_VIDEO_OPTIONS;
    if (deviceId) {
      result.deviceId = {exact: deviceId};
    }

    return result;
  }

  /**
   * Tries to harvest the deviceId from the given mediaStreamTrack object.
   * Browsers populate this object differently; this method tries some different approaches
   * to read the id.
   * @returns deviceId if found in the mediaStreamTrack
   */
  private static getDeviceIdFromMediaStreamTrack(mediaStreamTrack: MediaStreamTrack): string | any {
    if (mediaStreamTrack.getSettings && mediaStreamTrack.getSettings() && mediaStreamTrack.getSettings().deviceId) {
      return mediaStreamTrack.getSettings().deviceId;
    } else if (mediaStreamTrack.getConstraints && mediaStreamTrack.getConstraints() && mediaStreamTrack.getConstraints().deviceId) {
      const deviceIdObj: ConstrainDOMString | any = mediaStreamTrack.getConstraints().deviceId;
      return CameraComponent.getValueFromConstrainDOMString(deviceIdObj);
    }
  }

  /**
   * Tries to harvest the facingMode from the given mediaStreamTrack object.
   * Browsers populate this object differently; this method tries some different approaches
   * to read the value.
   */
  private static getFacingModeFromMediaStreamTrack(mediaStreamTrack: MediaStreamTrack): string | any {
    if (mediaStreamTrack) {
      if (mediaStreamTrack.getSettings && mediaStreamTrack.getSettings() && mediaStreamTrack.getSettings().facingMode) {
        return mediaStreamTrack.getSettings().facingMode;
      } else if (mediaStreamTrack.getConstraints && mediaStreamTrack.getConstraints() && mediaStreamTrack.getConstraints().facingMode) {
        const facingModeConstraint: ConstrainDOMString | any = mediaStreamTrack.getConstraints().facingMode;
        return CameraComponent.getValueFromConstrainDOMString(facingModeConstraint);
      }
    }
  }

  /**
   * Determines whether the given mediaStreamTrack claims itself as user facing
   */
  private static isUserFacing(mediaStreamTrack: MediaStreamTrack): boolean {
    const facingMode: string = CameraComponent.getFacingModeFromMediaStreamTrack(mediaStreamTrack);
    return facingMode ? 'user' === facingMode.toLowerCase() : false;
  }

  /**
   * Extracts the value from the given ConstrainDOMString
   */
  private static getValueFromConstrainDOMString(constrainDOMString: ConstrainDOMString): string | null {
    if (constrainDOMString) {
      if (constrainDOMString instanceof String) {
        return String(constrainDOMString);
      } else if (Array.isArray(constrainDOMString) && Array(constrainDOMString).length > 0) {
        return String(constrainDOMString[0]);
      } else if (typeof constrainDOMString === 'object') {
        if ('exact' in constrainDOMString && constrainDOMString.exact) {
          return String(constrainDOMString.exact);
        } else if ('ideal' in constrainDOMString && constrainDOMString.ideal) {
          return String(constrainDOMString.ideal);
        }
      }
    }

    return null;
  }

  public ngOnInit(): void {

  }

  public ngAfterViewInit(): void {
    this.detectAvailableDevices()
      .then(() => {
        // start video
        this.switchToVideoInput(null);
      })
      .catch((err: string) => {
        this.initError.next({message: err} as any);
        // fallback: still try to load camera, even if device enumeration failed
        this.switchToVideoInput(null);
      });
  }

  public ngOnDestroy(): void {
    this.stopMediaTracks();
    this.unsubscribeFromSubscriptions();
  }

  /**
   * Takes a snapshot of the current camera's view and emits the image as an event
   */
  public takeSnapshot(): void {
    // set canvas size to actual video size
    const $video = this.nativeVideoElement;
    const dimensions = {width: this.width, height: this.height};
    if ($video.videoWidth) {
      dimensions.width = $video.videoWidth;
      dimensions.height = $video.videoHeight;
    }

    const $canvas = this.canvas.nativeElement;
    $canvas.width = dimensions.width;
    $canvas.height = dimensions.height;

    // paint snapshot image to canvas
    const context2d = $canvas.getContext('2d');
    context2d.drawImage($video, 0, 0);

    // read canvas content as image
    const mimeType: string = this.imageType ? this.imageType : CameraComponent.DEFAULT_IMAGE_TYPE;
    const quality: number = this.imageQuality ? this.imageQuality : CameraComponent.DEFAULT_IMAGE_QUALITY;
    const dataUrl: string = $canvas.toDataURL(mimeType, quality);

    // get the ImageData object from the canvas' context.
    let imageData = null;

    if (this.captureImageData) {
      imageData = context2d.getImageData(0, 0, $canvas.width, $canvas.height);
    }

    this.imageCapture.next(new CameraImage(dataUrl, mimeType, imageData));
  }

  /**
   * Switches to the next/previous video device
   */
  public rotateVideoInput(forward: boolean): void {
    if (this.availableVideoInputs && this.availableVideoInputs.length > 1) {
      const increment: number = forward ? 1 : (this.availableVideoInputs.length - 1);
      const nextInputIndex = (this.activeVideoInputIndex + increment) % this.availableVideoInputs.length;
      this.switchToVideoInput(this.availableVideoInputs[nextInputIndex].deviceId);
    }
  }

  /**
   * Switches the camera-view to the specified video device
   */
  public switchToVideoInput(deviceId: string | any): void {
    this.videoInitialized = false;
    this.stopMediaTracks();
    this.initCamera(deviceId, this.videoOptions);
  }

  /**
   * Event-handler for video resize event.
   * Triggers Angular change detection so that new video dimensions get applied
   */
  public videoResize(): void {
    // here to trigger Angular change detection
  }

  /**
   * Returns the video aspect ratio of the active video stream
   */
  private getVideoAspectRatio(): number {
    // calculate ratio from video element dimensions if present
    const videoElement = this.nativeVideoElement;
    if (videoElement?.videoWidth && videoElement?.videoWidth > 0 &&
      videoElement?.videoHeight && videoElement?.videoHeight > 0) {

      return videoElement?.videoWidth / videoElement?.videoHeight;
    }

    // nothing present - calculate ratio based on width/height params
    return this.width / this.height;
  }

  /**
   * Init camera live view
   */
  private initCamera(deviceId: string, userVideoTrackConstraints: MediaTrackConstraints): void {
    const $video = this.nativeVideoElement;
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {

      // merge deviceId -> userVideoTrackConstraints
      const videoTrackConstraints = CameraComponent.getMediaConstraintsForDevice(deviceId, userVideoTrackConstraints);

      navigator.mediaDevices.getUserMedia({video: videoTrackConstraints} as MediaStreamConstraints)
        .then((stream: MediaStream) => {
          this.mediaStream = stream;
          $video.srcObject = stream;
          $video.play();

          this.activeVideoSettings = stream.getVideoTracks()[0].getSettings();
          const activeDeviceId: string = CameraComponent.getDeviceIdFromMediaStreamTrack(stream.getVideoTracks()[0]);

          this.cameraSwitched.next(activeDeviceId);

          // Initial detect may run before user gave permissions, returning no deviceIds. This prevents later camera switches. (#47)
          // Run detect once again within getUserMedia callback, to make sure this time we have permissions and get deviceIds.
          this.detectAvailableDevices()
            .then(() => {
              this.activeVideoInputIndex = activeDeviceId ? this.availableVideoInputs
                .findIndex((mediaDeviceInfo: MediaDeviceInfo) => mediaDeviceInfo.deviceId === activeDeviceId) : -1;
              this.videoInitialized = true;
            })
            .catch(() => {
              this.activeVideoInputIndex = -1;
              this.videoInitialized = true;
            });
        })
        .catch((err: MediaStreamError) => {
          this.initError.next({message: err.message, mediaStreamError: err});
        });
    } else {
      this.initError.next({message: 'Cannot read UserMedia from MediaDevices.'});
    }
  }

  private getActiveVideoTrack(): MediaStreamTrack | any {
    return this.mediaStream ? this.mediaStream.getVideoTracks()[0] : null;
  }

  private isMirrorImage(): boolean {
    if (!this.getActiveVideoTrack()) {
      return false;
    }

    // check for explicit mirror override parameter
    {
      let mirror = 'auto';
      if (this.mirrorImage) {
        if (typeof this.mirrorImage === 'string') {
          mirror = String(this.mirrorImage).toLowerCase();
        } else {
          // WebcamMirrorProperties
          if (this.mirrorImage.x) {
            mirror = this.mirrorImage.x.toLowerCase();
          }
        }
      }

      switch (mirror) {
        case 'always':
          return true;
        case 'never':
          return false;
      }
    }

    // default: enable mirroring if camera is user facing
    return CameraComponent.isUserFacing(this.getActiveVideoTrack());
  }

  /**
   * Stops all active media tracks.
   * This prevents the camera from being indicated as active,
   * even if it is no longer used by this component.
   */
  private stopMediaTracks(): void {
    if (this.mediaStream && this.mediaStream.getTracks) {
      // getTracks() returns all media tracks (video+audio)
      this.mediaStream.getTracks()
        .forEach((track: MediaStreamTrack) => track.stop());
    }
  }

  /**
   * Unsubscribe from all open subscriptions
   */
  private unsubscribeFromSubscriptions(): void {
    if (this.triggerSubscription) {
      this.triggerSubscription.unsubscribe();
    }
    if (this.switchCameraSubscription) {
      this.switchCameraSubscription.unsubscribe();
    }
  }

  /**
   * Reads available input devices
   */
  private detectAvailableDevices(): Promise<MediaDeviceInfo[]> {
    return new Promise((resolve, reject) => {
      CameraUtil.getAvailableVideoInputs()
        .then((devices: MediaDeviceInfo[]) => {
          this.availableVideoInputs = devices;
          resolve(devices);
        })
        .catch(err => {
          this.availableVideoInputs = [];
          reject(err);
        });
    });
  }
}
