import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-text-modal',
  templateUrl: './text-modal.component.html',
  styleUrls: ['./text-modal.component.scss']
})
export class TextModalComponent implements OnInit {
  @Input() isShowModal = false;
  @Input() messageToUser: string[] = [];
  @Input() isAlignLeft = false
  @Output() closeModal = new EventEmitter<boolean>();

  get message() {
    return this.messageToUser
  }

  constructor() { }

  ngOnInit(): void {
  }

  onCloseModal(): void {
    this.closeModal.emit(false);
  }
}
