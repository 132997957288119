import { Injectable } from '@angular/core';
import {StorageService} from '@user/core/services/storage.service';
import {ApiBase} from '@user/core/services/api.service';
import {BaseFormProtectionService} from '@user/core/services/form-protection/base-form-protection.service';
import {CustomParameterService} from '@user/core/services/custom-parameter.service';

@Injectable({
  providedIn: 'root'
})
export class PasswordFormProtectionService extends BaseFormProtectionService{
  queryKey = 'p';
  storeKey = 'password';
  baseUrl = '/password/check';
  parameterName = 'password';

  constructor(
    protected apiBase: ApiBase,
    protected storageService: StorageService,
    protected customParameterService: CustomParameterService,
  ) {
    super(apiBase, storageService, customParameterService);
  }
}
