import {FormControl} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {DataTypeEnum} from '@app-shared/enums';

export function textareaValidation(control: FormControl, field: FormlyFieldConfig | any): (Record<any, any> | string | null) {
  const minLength = +field?.templateOptions?.minLength;
  const maxLength = +field?.templateOptions?.maxLength;
  let currentValueLength = +control?.value?.length;
  let address = '';

  if ((!control.value || !control.value.length)) {
    return {required: 'ERROR.TEXTAREA_REQUIRED'};

  } else {
    if (typeof field?.key === DataTypeEnum.STRING && (field?.key as string)?.indexOf('address') > -1) {
      address = control.value.toString();
      currentValueLength = +address.length;
    }

    if (minLength !== maxLength) {
      if (currentValueLength < minLength) {
        return {
          minlength: {
            requiredInput: {
              minlength: minLength
            },
            message: 'ERROR.TEXTAREA_MIN_LEN'
          }
        };
      }

      if (currentValueLength > maxLength) {
        return {
          maxlength: {
            requiredInput: {
              maxlength: maxLength
            },
            message: 'ERROR.TEXTAREA_MAX_LEN'
          }
        };
      }
    }

    if (minLength === maxLength) {
      if (currentValueLength < minLength || currentValueLength > maxLength) {
        return {
          minlength: {
            requiredInput: {
              length: minLength
            },
            message: 'ERROR.TEXTAREA_EQUALS_LEN'
          },
          maxlength: {
            requiredInput: {
              length: maxLength
            },
            message: 'ERROR.TEXTAREA_EQUALS_LEN'
          }
        };
      }
    }
  }

  return null;
}
