import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { QuestionService } from '@user/core/services/question/question.service';

export abstract class BaseForm<T> {
  record !: T;
  form !: FormGroup;
  model: any = {};
  options: FormlyFormOptions = {
    formState: {
      model: this.model,
    }
  };

  fields: FormlyFieldConfig[] = [];

  protected constructor(
    protected questionService: QuestionService,
  ) {
  }

  validationForm(): void {
    for (const item in this.form.controls) {
      this.form.controls[item].markAsDirty();
      this.form.controls[item].updateValueAndValidity();
    }

    if (this.form.invalid) {
      // tslint:disable-next-line:forin
      this.form.markAllAsTouched();
      this.form.setErrors({taken: true});
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      return;
    }
    this.submitForm();
  }

  protected submitForm() {
  }

  protected patchValueForm(data: any) {
    if (data) {
      this.model = Object.entries(data).reduce((accum, ele: any) => {
        let eleValue = ele[1].value;
        if (eleValue) {
          accum = {...accum, [ele[0]]: eleValue};
        } else {
          Object.keys(ele[1]).forEach((key) => {
            accum = {...accum, [`${key}_${+ele[0]}`]: key === 'address' ? ele[1][key].toString() : ele[1][key]};
          });
        }
        return accum;
      }, {});
      /* Convert data to value true for Model of Formly : Image OCR, Image no OCR, File PDF */
      Object.keys(this.model).forEach(key => {
        /* File image */
        if (key.includes('image_')) {
          this.model[key.replace('image_', '')] = {
            ...this.model[key.replace('image_', '')],
            [key.replace('image_', '')]: this.model[key]
          };
          delete this.model[key];
        }

        /* OCR: Target product */
        if (key.includes('target_product_')) {
          this.model[key.replace('target_product_', '')] = {
            ...this.model[key.replace('target_product_', '')],
            'target_product': {
              'input_array': this.model[key].map((v: string) => {
                return {
                  'input': v
                };
              })
            }
          };
          delete this.model[key];
        }

        /* File FILE */
        if (key.includes('file_')) {
          this.model[key.replace('file_', '')] = {
            [key.replace('file_', '')]: this.model[key]
          };
          delete this.model[key];
        }
      });
    }
  }

  groupByField(arr = []) {
    let groupByField: any = {};
    arr.forEach((res: any) => {
      const type = res.field.split('.')[2] + (res.field.split('.')[3] ? '[]' : '');
      groupByField[type] = groupByField[type] || [];
      groupByField[type].push(res.field);
    });
    return groupByField;
  }
}
