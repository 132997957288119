import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss']
})
export class MessageModalComponent implements OnInit {
  @Input() isShowModal = false;
  @Input() messageToUser: any = '';
  @Input() messageClose: any = 'MILEAGE.MD_MESSAGE_BTN_LABEL';
  @Output() closeModal = new EventEmitter<boolean>();

  get message(): string {
    return this.messageToUser?.message || this.messageToUser?.[0]?.message?.[0] || '';
  }

  constructor() { }

  ngOnInit(): void {
  }

  onCloseModal(): void {
    this.closeModal.emit(false);
  }

}
