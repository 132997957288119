import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ScannerModalComponent } from '@user/shared/components/scanner-modal/scanner-modal.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { LoaderComponent } from './components/loader/loader.component';
import { CameraModalComponent } from './components/camera-modal/camera-modal.component';
import { CameraComponent } from './components/camera/camera.component';
import { MessageModalComponent } from './components/message-modal/message-modal.component';
import { ExchangeConfirmModalComponent } from './components/exchange-confirm-modal/exchange-confirm-modal.component';
import { OcrPointModalComponent } from './components/ocr-point-modal/ocr-point-modal.component';
import { TextModalComponent } from './components/text-modal/text-modal.component';
import { MessageCustomModalComponent } from './components/message-custom-modal/message-custom-modal.component';
import { MessageConfirmModalComponent } from './components/message-confirm-modal/message-confirm-modal.component';

const COMPONENT: any[] = [
  LoaderComponent,
  ScannerModalComponent,
  CameraModalComponent,
  CameraComponent,
  MessageModalComponent,
  ExchangeConfirmModalComponent,
  OcrPointModalComponent,
  TextModalComponent,
  MessageCustomModalComponent,
  MessageConfirmModalComponent
];
const PIPE: any [] = [];
const MODULES = [
  CommonModule,
  TranslateModule,
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  TranslateModule,
  ZXingScannerModule,
];

@NgModule({
  declarations: [
    ...COMPONENT,
    ...PIPE,
  ],
  imports: [
    ...MODULES
  ],
  exports: [
    ...COMPONENT,
    ...PIPE,
    ...MODULES
  ]
})

export class SharedModule {
}
