import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GivePointMethodTypeEnum } from '@app-shared/enums';

@Component({
  selector: 'app-ocr-point-modal',
  templateUrl: './ocr-point-modal.component.html',
  styleUrls: ['./ocr-point-modal.component.scss']
})
export class OcrPointModalComponent implements OnInit {
  @Input() isShowModal = false;
  @Input() resultOCR = 0;
  @Input() isSubmitted = false;
  @Input() messageToUser: any = '';
  @Output() closeModal = new EventEmitter<boolean>();
  @Output() submitMileage = new EventEmitter<any>();

  get message(): string {
    return this.messageToUser?.message || this.messageToUser?.[0]?.message?.[0] || '';
  }

  constructor() { }

  ngOnInit(): void {
  }

  onCloseModal(): void {
    this.closeModal.emit(false);
  }

  onSubmitMileage(): void {
    this.submitMileage.emit(GivePointMethodTypeEnum.RECEIPT_APPROVAL);
  }
}
