import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {first, mergeMap} from 'rxjs/operators';
import {CampaignDataService} from '@user/core/services';
import { CampaignTypeEnum } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class CampaignReachedApplyLimitGuard implements CanActivate {
  constructor(
    private campaignDataService: CampaignDataService,
    private router: Router,
  ) {
  }

  redirectAndReturnFalse(urls = ['static', 'not-found']): Observable<boolean> {
    this.router.navigate(urls);
    return of(false);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.campaignDataService.campaignInitialized.pipe(
      first(),
      mergeMap((campaign) => {
        if (!campaign) { return this.redirectAndReturnFalse(); }
        if (campaign.reached_submission_form_limit) {
          if((campaign.campaign_type === CampaignTypeEnum.LOTTERY || campaign.campaign_type === CampaignTypeEnum.NORMAL) && !campaign.open_end_comparison.less_than_now) {
            return of (true)
          }
          return this.redirectAndReturnFalse(['static', 'closed']);
        }

        return of(true);
      })
    );
  }
}
