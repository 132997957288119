<!-- Modal_Window -->
<div class="shared-modal-wrapper" *ngIf="isShowModal">
  <a
    href="javascript:;"
    class="modal-overlay">
  </a>

  <div class="modal-window">
    <div class="modal-content text-modal-content">
      <a (click)="onCloseModal()"
      aria-hidden="true"
      class="btn-close" href="javascript:">×</a>
      <div class="block-text-center">
        <h2 class="text-message" [class.text-left]="isAlignLeft" *ngFor="let mes of message" [innerHTML]="mes"></h2>
      </div>
    </div>
  </div>
</div>
<!-- //Modal_Window -->
