import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {first, mergeMap} from 'rxjs/operators';
import {CampaignDataService, SecretPathService} from '@user/core/services';
import {LandingPageTypeEnum} from '@app-shared/enums';
import {UrlService} from "@user/core/services/url.service";
import {ICampaign} from '@user/core/interfaces';
@Injectable({
  providedIn: 'root'
})
export class CampaignOpenGuard implements CanActivate {
  constructor(
    @Inject('env') private env: any,
    private campaignDataService: CampaignDataService,
    private router: Router,
    private secretPathService: SecretPathService,
    private urlService: UrlService,
  ) {}

  redirectAndReturnFalse(commands = ['/']): Observable<boolean> {
    this.router.navigate(commands);
    return of(false);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.campaignDataService.campaignInitialized.pipe(
      first(),
      mergeMap((campaign) => {
        if (!campaign) { return this.redirectAndReturnFalse(['static', 'not-found']); }

        if (this.secretPathService.isSecretPath() || state.url.includes(this.secretPathService.secretPath)) {
          return of(true);
        }

        if (this.checkBadRequest(campaign)) {
          return this.redirectAndReturnFalse(['static', 'bad-request'])
        }

        if (campaign.public_end_comparison.less_than_now || campaign.public_start_comparison.greater_than_now) {
          return this.redirectAndReturnFalse(['static', 'not-found']);
        }

        const queryParameters = this.urlService.getQueryParameters();
        const hashParameters = this.urlService.getHashParameters();

        if (campaign.settings.common.teaser_status && campaign.settings.common.is_teaser) {
          window.location.assign('/teaser' + queryParameters + hashParameters);
          return of(false);
        }

        if (campaign.settings.common.end_page_status && campaign.settings.common.is_end) {
          return this.redirectAndReturnFalse(['end-page']);
        }

        if (campaign.open_start_comparison.less_than_now &&
          campaign.open_end_comparison.greater_than_now && campaign.settings.common.landing_page_status && state.url === '/') {
            if (campaign?.settings?.common?.landing_page_type === LandingPageTypeEnum.SETTING_LAYOUT) {
              return this.redirectAndReturnFalse();
            }
            window.location.assign('/top/' + queryParameters + hashParameters);
            return of(false);
        }
        return of(true);
      })
    );
  }
  checkBadRequest (campaign: ICampaign): boolean {
    let isBadRequest = false
    if (!campaign.parent_campaign) {
      if (campaign.access_domain_control_using) {
        campaign.access_source_domain_control = campaign.access_source_domain_control + ',' + this.env?.accessDomain
        let accessDomain = campaign.access_source_domain_control.split(',')
        if (document.referrer) {
          sessionStorage.setItem('referrer', document.referrer)
        }
        const referrer = sessionStorage.getItem('referrer') || ''
        if (!referrer || !accessDomain.find(domain => referrer.includes(domain))) {
          isBadRequest = true
        }
      }
    }
    return isBadRequest
  }
}
