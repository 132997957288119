export enum ErrorCodeEnum {
  // LINE
  LINE_NOT_YET_FRIEND_WITH_OA = 4001,
  // GIFT
  ALREADY_SENT_GIFT = 4002,
  CAMPAIGN_CLOSE = 4003,
  CAMPAIGN_LIMIT = 4004,
  USER_LIMIT = 4005,
  USER_DAY_LIMIT = 4006,
  INACTIVE_CODE = 40010,
  RECEIPT_INVALID = 40011
}
