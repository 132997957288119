import { Component } from '@angular/core';
import { FieldType, FormlyExtension, FormlyFieldConfig } from '@ngx-formly/core';
import { FieldTypeEnum } from '@app-shared/enums';
import { handleTemplateOption } from '../../functions/template-option';
import { fileValidator, patternValidator } from '../../../config/validation';

@Component({
  selector: 'formly-wrapper-file',
  template: `
    <ng-container *ngFor="let f of field.fieldGroup; let index = index; let last = last;">
      <formly-field [field]="f"></formly-field>
      <lib-control-errors
        nameForm="file-ocr"
        [fieldName]="field?.key + '.' + f.key"
      >
      </lib-control-errors>
    </ng-container>
  `
})
export class FormlyFieldFileOcrComponent extends FieldType
  implements FormlyExtension {

  prePopulate(field: FormlyFieldConfig) {
    field.fieldGroup = [
      {
        key: field?.templateOptions?.data.id,
        type: FieldTypeEnum.FILE,
        templateOptions: {
          data: {...field?.templateOptions?.data},
          ...handleTemplateOption(field?.templateOptions?.data)
        },
        validators: {
          validation: [patternValidator, fileValidator],
        },
      },
      {
        type: FieldTypeEnum.TARGET_PRODUCT,
        key: FieldTypeEnum.TARGET_PRODUCT,
        hideExpression: (model: any, formState: any, field: any) => {
          if (field.templateOptions.data) {
            return !(field?.parent?.templateOptions?.is_ocr && field.parent.formControl.value[field?.templateOptions?.data.id]?.length);
          }
          return false;
        },
      },
    ];
  }
}
