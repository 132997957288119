import { Component, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
import { QuestionService } from '@user/core/services/question/question.service';
import { FormArray } from '@angular/forms';

@Component({
  selector: 'app-formly-field-input',
  template: `
    <legend *ngIf="to.label">{{ to.label }}</legend>
    <div class="db-array-ocr" *ngFor="let field of field.fieldGroup;let i = index;">
      <formly-field [field]="field"></formly-field>
      <div class="db-icon-ocr">
        <i (click)="add()"
           [ngClass]="{'disabled': formArray.length > 15}"
           class="fa fa-plus-circle cursor-pointer text-blue-700 mx-3 fz-icon-ocr"
           aria-hidden="true"></i>
        <i (click)="remove(i)"
           [ngClass]="{'disabled': formArray.length == 1}"
           class="fa fa-trash text-red-500 cursor-pointer fz-icon-ocr"
           aria-hidden="true"></i>
      </div>

      <p class="error_s" *ngIf="field.formControl?.get('input')?.hasError('space')">{{'ERROR.FILE_OCR_SPACE' | translate}}</p>
      <p class="error_s" *ngIf="field.formControl?.get('input')?.hasError('maxlength')">{{'ERROR.INPUT_REQUIRED_100'|translate}}</p>
      <p class="error_s" *ngIf="field.formControl?.get('input')?.hasError('pattern')">{{'ERROR.FILE_OCR_PATTERN_OCR' | translate}}</p>
    </div>
  `,
})
export class FormlyFieldInputOCRComponent extends FieldArrayType implements OnInit {
  constructor(
    private questionService: QuestionService
  ) {
    super();
  }

  ngOnInit(): void {
    this.questionService.targetProduct
      .subscribe(({key, data, is_reset = false}: any) => {
        if (this.field.parent?.parent?.key === key) {
          let array = Array.from(Array(this.field?.fieldGroup?.length).keys());
          let i = this.field.fieldGroup?.length as number;

          if (is_reset) {
            array.forEach(() => {
              i = i - 1;
              this.remove(i);
            });
            return;
          }
          if (!this.field.fieldGroup?.length) {
            this.add();
          }

          if ((this.field?.fieldGroup?.length as number) > 1) {
            array.forEach(() => {
              i = i - 1;
              if (i > 0) {
                this.remove(i);
              }
            });
          }
        }
      });
  }

  get formArray() {
    return this.field.formControl as FormArray;
  }
}

