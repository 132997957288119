interface Endpoint {
  questions: string;
  confirm: string;
  update: string;
  show_data: string;
  campaign_detail: string;
  form_submit: string;
  form_confirm: string;
  ocr_image: string;
  profile: string;
  register: string;
  apply_form: string;
  lottery: string;
  mileage_serial: string;
  mileage_receipt_declaration: string;
  mileage_receipt_approval: string;
  mileage_jan_code: string;
  check_ocr: string;
  exchange_points: string;
  history_apply: string;
  history_point_request: string;
  inactive_code: string;
  upload_receipt: string;
  section_block: string;
  id_pw: string;
  user_generate_url: string;
  gift_consume: string;
  zalo_pay_check: string;
  root_campaign: string;
  [key: string]: string;
}

export const apiEndpoints: Endpoint = {
  questions: '/user/questions',
  confirm: 'form/confirm',
  update: 'form/update',
  show_data: 'form/show-data',
  form_submit: 'form/submit',
  campaign_detail: '/campaign',
  form_confirm: '/user/form/confirm',
  ocr_image: '/user/form/',
  profile: '/user/me',
  apply_form: '/user/form/submit-apply',
  register: '/user/auth/register',
  lottery: '/user/lottery',
  check_lottery: '/user/lottery/check',
  mileage_serial: '/user/mileage/serial/earn-points',
  mileage_receipt_declaration: '/user/mileage/receipt-declaration/earn-points',
  mileage_receipt_approval: '/user/mileage/receipt-approval/earn-points',
  mileage_jan_code: '/user/mileage/jan-code/earn-points',
  check_ocr: '/user/mileage/receipt-approval/check-ocr',
  exchange_points: '/user/mileage/exchange-points',
  history_apply: '/user/history/applies',
  history_point_request: '/user/mileage/history/point-requests',
  inactive_code: '/user/inactive-code',
  mail_auth:'/user/auth/email/get-link-auth',
  verify_token_mail: '/user/auth/email/verify-auth',
  upload_receipt: '/user/upload-receipt',
  section_block: '/user/mileage/section-blocks',
  id_pw: '/user/auth/id-pw',
  user_generate_url: '/user/generate-url',
  gift_consume: '/web/user/gift-consume',
  zalo_pay_check: '/user/zalo-pay/check',
  root_campaign: '/user/domain'
};
