import {
  ConfirmPageInterface, IClosePage,
  ICommonPage, ICompletePage, IError,
  ILandingPage, ILinePay, ILineSetting,
  IInputPage, IPasswordPage, ISerialPage,
  ITeaserPage,
  IReceiptPage,
  IEndPage,
  IGiftInactivatedPage,
} from '@user/core/interfaces/setting';

import {IDateComparison} from '@user/core/interfaces/date-comparison';
import {UserAuthMethodEnum, UserAuthScopeTypeEnum, UserAuthProviderTypeEnum} from '@user/core/enums';
import {GiftDisplayTypeEnum, GiftTypeEnum} from '@user/core/enums/gift';
import {IOstiariesAspSetting} from '@user/core/interfaces/setting/ostiaries-asp-setting.interface';
import {ILotteryLossPage, ILotteryPage, ILotterySetting} from '@user/core/interfaces/lottery';
import { IHistoryPage, IMyPagePage } from '@user/core/interfaces/mileage';
import { IIDPWSetting } from '@user/core/interfaces/setting/id-pw-setting.interface';
import { IZaloPay } from './setting/zalo-pay.interface';

export enum FormUsingTypeEnum {
  NO_FORM = 'no-form',
  FORM = 'form',
}

export enum CampaignTypeEnum {
  NORMAL = 'normal',
  MILEAGE = 'mileage',
  LOTTERY = 'lottery',
}

export enum ApplyAuthMethodEnum {
  NORMAL = 'normal',
  SERIAL = 'serial',
  PASSWORD = 'password',
  UNIQUE = 'unique',
  HITAP = 'hitap',
  RECEIPT = 'receipt'
}

export interface ICampaignFunction {
  [index: string]: boolean;

  gift_type: boolean;
  hitap_param_unique: boolean;
  user_auth_method: boolean;
  send_mail: boolean;
  send_mail_to_user: boolean;
  line_messaging: boolean;
}

export interface ICampaign {
  id: string;
  name: string;
  domain: string;
  apply_auth_method: ApplyAuthMethodEnum;
  form_using_type: FormUsingTypeEnum;
  functions: ICampaignFunction;
  campaign_type: CampaignTypeEnum;
  user_auth_method: UserAuthMethodEnum;
  user_auth_provider_type: UserAuthProviderTypeEnum[];
  user_auth_scope_type: UserAuthScopeTypeEnum;
  gift_type: GiftTypeEnum;
  gift_display_type: GiftDisplayTypeEnum;
  public_start_comparison: IDateComparison;
  public_end_comparison: IDateComparison;
  open_start_comparison: IDateComparison;
  open_end_comparison: IDateComparison;
  reached_submission_form_limit: boolean;
  user_day_limit?: number | null;
  user_limit?: number | null;
  settings: {
    common: ICommonPage;
    close?: IClosePage;
    landing_page?: ILandingPage;
    teaser?: ITeaserPage;
    complete?: ICompletePage;
    password?: IPasswordPage;
    serial?: ISerialPage;
    error: IError;
    input: IInputPage;
    confirm: ConfirmPageInterface;
    line?: ILineSetting;
    line_pay?: ILinePay;
    hitap_param_unique?: string;
    custom_params?: string[];
    ostiaries_asp?: IOstiariesAspSetting;
    lottery_loss_page?: ILotteryLossPage;
    lottery_setting?: ILotterySetting;
    lottery_page?: ILotteryPage;
    history_page?: IHistoryPage;
    my_page?: IMyPagePage;
    receipt?: IReceiptPage;
    id_pw?: IIDPWSetting;
    zalo_pay_confirm?: IZaloPay;
    end_page?: IEndPage;
    gift_inactivated_page?: IGiftInactivatedPage;
  };
  campaign_functions: string[];
  confirm_screen_using: boolean;
  lost_screen_using?: boolean
  status: string;
  access_domain_control_using: boolean,
  access_source_domain_control: string,
  parent_campaign: string,
  group_linkage: boolean
}
