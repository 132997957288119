<!-- Modal camera -->
<div class="modal-camera" *ngIf="isShowCamera">
  <div class="modal-overlay"></div>
  <!-- The Close Button -->
  <a
    href="javascript:;"
    class="modal-close"
    (click)="closeCamera()">
  </a>

  <!-- Modal Content (The Camera) -->
  <div class="modal-content-camera" (window:resize)="onResize($event)">
    <app-camera
      class="camera-container"
      #camera
      [imageType]="'image/jpg'"
      [imageQuality]="1"
      [width]="width"
      [height]="height"
      [videoOptions]="videoOptions"
      [trigger]="triggerObservable"
      [switchCamera]="nextCameraObservable"
      [allowCameraSwitch]="allowCameraSwitch"
      (cameraSwitched)="cameraWasSwitched($event)"
      (imageCapture)="handleImage($event)"
      (initError)="handleInitError($event)"
    ></app-camera>

    <button
      class="btn-take-photo"
      type="button"
      (click)="triggerSnapshot();">
      <span class="circle"></span>
      <span class="ring"></span>
    </button>
  </div>
</div>
