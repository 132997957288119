import {FormControl} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';

export function checkboxValidation(control: FormControl, field: FormlyFieldConfig | any): (Record<any, any> | string | null) {
  const minLength = +field?.templateOptions?.minLength;
  const maxLength = +field?.templateOptions?.maxLength;
  const options = field.templateOptions.options;

  if ((!control.value || !control.value.length)) {
    if (!field.templateOptions.required) {
      return null;
    }

    if (options?.length == 1) {
      return {required: 'ERROR.CHECKBOX_REQUIRED'};
    }

    if (minLength == maxLength && minLength == options?.length) {
      return {required: 'ERROR.CHECKBOX_REQUIRED_ALL'};
    }

    return {required: 'ERROR.CHECKBOX_REQUIRED_OTHER'};

  }

  if (minLength == maxLength && minLength == options.length && minLength !== control.value.length) {
    return {
      minlength: `ERROR.CHECKBOX_EQUAL_OPTION_LESS_MIN`,
      maxlength: `ERROR.CHECKBOX_EQUAL_OPTION_LESS_MIN`
    };
  }

  if (minLength == maxLength && minLength !== options.length && (control.value.length < minLength || control.value.length > maxLength)) {
    return {
      minlength: {
        requiredInput: {
          minlength: minLength,
        },
        message: 'ERROR.CHECKBOX_DIFF_OPTION_LESS_MIN'
      },
      maxlength: {
        requiredInput: {
          minlength: minLength,
        },
        message: 'ERROR.CHECKBOX_DIFF_OPTION_LESS_MIN'
      }
    };
  }

  if (minLength !== maxLength && (control.value.length < minLength || control.value.length > maxLength)) {
    return {
      minlength: {
        requiredInput: {
          minlength: minLength,
          maxlength: maxLength
        },
        message: 'ERROR.CHECKBOX_OPTION_BETWEEN_MIN_MAX'
      },
      maxlength: {
        requiredInput: {
          minlength: minLength,
          maxlength: maxLength
        },
        message: 'ERROR.CHECKBOX_OPTION_BETWEEN_MIN_MAX'
      },
    };
  }

  return null;
}
