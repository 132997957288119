import { AfterViewInit, Component } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { FormArray, FormControl } from '@angular/forms';
import { QuestionService } from '@user/core/services/question/question.service';
import { spaceValidator } from '@app-shared/config/validation';

@Component({
  selector: 'app-formly-field-target-product',
  template: `
    <formly-group [field]="field"></formly-group>
  `,
})
export class FormlyFieldTargetProductComponent extends FieldType implements AfterViewInit {
  targetProductData = [];

  constructor(
    private questionService: QuestionService
  ) {
    super();
  }

  prePopulate(field: FormlyFieldConfig): void {
    if (field.formControl) {
      return;
    }
    field.fieldGroup = [
      {
        key: 'checkbox',
        type: 'checkbox',
        templateOptions: {
          options: this.targetProductData,
          key: field?.parent?.key
        },
      },
      {
        key: 'input_array',
        type: 'multiple-input-ocr',
        fieldArray: {
          fieldGroup: [
            {
              key: 'input',
              type: 'input',
              templateOptions: {
                maxLength: 100,
                pattern: '^[^\\;\\<\\=\\>\\/\\[\\]\\{\\|\\}\\\\]+$',
                data: {
                  randomNumber: Math.ceil(Math.random() * 10000)
                }
              },
              validators: {
                validation: [spaceValidator],
              }
            }
          ]
        },
        templateOptions: {
          key: field?.parent?.key
        }
      },
    ];
    field.validators = {
      required_ocr: {
        expression: (control: any) => {
          const errorFileType = control?.parent?.controls[field?.parent?.key as string]?.errors ? control.parent?.controls[field?.parent?.key as string]?.errors['fileTypeValidator'] : false;
          const errorFileSize = control?.parent?.controls[field?.parent?.key as string]?.errors ? control.parent?.controls[field?.parent?.key as string]?.errors['fileSizeValidator'] : false;
          if (!field.form?.value[field?.parent?.key as number]?.length || errorFileType || errorFileSize) {
            return true;
          }
          const value = control?.value;
          const inputArray = value?.input_array ? value?.input_array?.map((v: { input: string; }) => {
            return v.input;
          }) : [];
          const _value = [...(value?.checkbox ? value?.checkbox : []), ...inputArray].filter(v => v !== undefined && v !== null && v !== '');
          return _value.length;
        },
      }
    };
  }

  ngAfterViewInit(): void {
    this.questionService.targetProduct
      .subscribe(({key, data, is_reset = false}: any) => {
        if (this.field.parent?.key === key) {
          this.targetProductData = [];
          this.checkboxControl ? this.checkboxControl.reset() : false;
          this.inputArray ? this.inputArray.reset() : false;

          if (data?.length) {
            this.targetProductData = data.map((v: string) => {
              return {
                value: v,
                label: v
              };
            });
          }
          const field = this.questionService.getField('checkbox', this.field.fieldGroup as []);
          if (field && field.templateOptions && field.templateOptions.options) {
            field.templateOptions.options = this.targetProductData;
          }
        }
      });
  }


  get checkboxControl() {
    return this.formControl?.get('checkbox') as FormControl;
  }

  get inputArray() {
    return this.formControl?.get('input_array') as FormArray;
  }
}

