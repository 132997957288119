import { Injectable } from '@angular/core';
import { ApiBase } from './api.service';
import { ICampaign, IResponse } from '@user/core/interfaces';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IZaloPay } from '../interfaces/setting/zalo-pay.interface';
import { apiEndpoints } from '@user/config/global-vars';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ZaloPayService {
  apiUrl = '';
  baseDomain = '';

  constructor(private apiBase: ApiBase, private http: HttpClient) {
    this.baseDomain = window.location.origin;
    this.apiUrl = `${this.baseDomain}`;
  }
  checkZaloPayApply(applyId: string, phone_number: string): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}${apiEndpoints.gift_consume}/${applyId}`, { phone_number });
  }

  checkApplyId(applyId: string): Observable<any> {
    return this.apiBase.post<IResponse<any>>(`${apiEndpoints.zalo_pay_check}/${applyId}`);
  }

  getRootCampaign(page: string): Observable<any> {
    return this.apiBase.get<IResponse<any>>(`${apiEndpoints.root_campaign}/${page}`);
  }
}
