<!-- Modal camera -->
<div class="modal-camera" *ngIf="isShowScanner">
  <!-- The Close Button -->
  <a
    *ngIf="hasDevices && isShowScanner"
    href="javascript:;"
    class="modal-close"
    (click)="closeScanner()">
  </a>

  <!-- Modal Content (The Camera) -->
  <div class="modal-content-camera">
    <zxing-scanner
      #scanner
      [formats]="enabledFormats"
      (permissionResponse)="onHasPermission($event)"
      (camerasFound)="onCamerasFound($event)"
      (camerasNotFound)="onCamerasNotFound($event)"
      (scanSuccess)="scanSuccessHandler($event)"
    ></zxing-scanner>
  </div>
</div>
