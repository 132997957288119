import { Component } from '@angular/core';
import { isLanguageVietNam } from '@app-shared/config/language';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-box-multiple',
  template: `
    <div
      [className]="'box ' + (field?.templateOptions?.data?.html_class || '')"
      [id]="field?.templateOptions?.data?.html_id || ''"
    >
      <div class="db-f-box-above-html" [innerHTML]="field?.templateOptions?.data?.display_before | safeHtml"></div>
      <div class="element_flex">
        <span class="option" *ngIf="!!!to?.data?.is_required && !isLanguageVietNam">{{'OPTION'|translate}}</span>
        <span class="required" *ngIf="!!to?.data?.is_required && !isLanguageVietNam">{{'REQUIRED'|translate}}</span>
        <h4 class="title_formly">{{to?.data?.title}}</h4>
        <span class="required" *ngIf="!!to?.data?.is_required && isLanguageVietNam">*</span>
      </div>
      <p class="subtitle">{{to?.data?.sub_title}}</p>
      <ng-container #fieldComponent></ng-container>
      <p class="help">{{to?.data?.annotation}}</p>
      <div class="db-f-box-below-html" [innerHTML]="field?.templateOptions?.data?.display_after | safeHtml"></div>
    </div>
  `,
})
export class BoxMultipleFormlyWrapperComponent extends FieldWrapper {
  isLanguageVietNam = isLanguageVietNam();
}
