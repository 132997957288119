import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, NavigationExtras, Router, RouterStateSnapshot } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { first, mergeMap, map } from 'rxjs/operators';
import { AuthService, CampaignDataService, SecretPathService, TokenService } from '@user/core/services';
import { ApplyAuthMethodEnum, FormUsingTypeEnum } from '@user/core/interfaces';

@Injectable({
  providedIn: 'root'
})
export class ReceiptGuard implements CanActivate {

  constructor(
    private campaignDataService: CampaignDataService,
    private router: Router,
    private secretPathService: SecretPathService,
    private authService: AuthService,
    private tokenService: TokenService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    const secretPath = this.secretPathService.isSecretPath() ? this.secretPathService.secretPath : ''
    return combineLatest([
      this.campaignDataService.campaignInitialized,
    ]).pipe(
      first(),
      mergeMap(([campaign]) => {
        // Redirect to Not Found page if campaign not exist
        if (!campaign) {
          this.router.navigate(['static', 'not-found']);
          return of(false);
        }

        if (campaign.apply_auth_method === ApplyAuthMethodEnum.RECEIPT) {
          const returnUrl = state?.url || null;
          const extras: NavigationExtras = {};
          if (returnUrl) {
            extras.queryParams = { return_url: returnUrl };
          }

          if (!this.tokenService.accessToken) {
            this.router.navigate([secretPath, 'auth'], extras);
            return of(false);
          }

          const isEndLottery =  !!(campaign.settings?.lottery_setting?.campaign_lottery_end_comparison?.less_than_now) && campaign.open_end_comparison.greater_than_or_equal_to_now
          if (isEndLottery) {
            this.router.navigate(['static', 'lottery-ended']);
            return of (false)
          }

          if (campaign.reached_submission_form_limit) {
            this.router.navigate(['static', 'closed']);
            return of (false)
          }
          return this.authService.profile().pipe(map(user => {
            if (campaign?.form_using_type === FormUsingTypeEnum.FORM && user && user.total_apply <= 0) {
              this.router.navigate([secretPath, 'form'], extras);
              return false
            }
            return true
          }))
        }
        this.router.navigate(['static', 'not-found']);
        return of (false)
      })
    );
  }
}
