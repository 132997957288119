import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout/layout.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SectionComponent } from './section/section.component';
import { ControlErrorsComponent } from './control-errors/control-errors.component';
import { PipesModule } from '../pipes/pipes.module';
import { NgxBarcodeModule } from 'ngx-barcode';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NotificationComponent } from '@app-shared/component/notification/notification.component';
import { TranslateModule } from '@ngx-translate/core';
import { OstiariesAspFormComponent } from './ostiaries-asp-form/ostiaries-asp-form.component';
import { IDPWFormComponent } from './id-pw-form/id-pw-form.component';
import { SharedModule } from '@user/shared';

const COMPONENTS = [
  LayoutComponent,
  SectionComponent,
  ControlErrorsComponent,
  NotificationComponent,
  OstiariesAspFormComponent,
  IDPWFormComponent
];

const MODULES = [NgxBarcodeModule, NgxQRCodeModule];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    PipesModule,
    TranslateModule,
    SharedModule,
    ...MODULES,
  ],
  exports: [...COMPONENTS, ...MODULES],
})
export class ComponentModule {}
