import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Core Module
import { Router } from '@angular/router';
import { CampaignDataService, ErrorModalService, NotificationService, SecretCodeService, SecretPathService } from '@user/core/services';
import { TokenService } from '@user/core/services/token.service';
import { ErrorCodeEnum } from '@user/core/enums';
import { HttpErrorCodeEnum } from '@app-shared/enums';
import { ILotteryWinPage } from '@user/core/interfaces/lottery';

interface IWinPageState {
  url?: string;
  lottery_win_layout?: ILotteryWinPage;
  is_win?: boolean;
  campaign_lottery_end_comparison?: any;
  reached_submission_form_limit?: boolean;
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    public router: Router,
    public notificationService: NotificationService,
    private tokenService: TokenService,
    private secretCodeService: SecretCodeService,
    private secretPathService: SecretPathService,
    private errorModalService: ErrorModalService
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((resp: HttpErrorResponse) => {
        if (resp.error && resp.error.error && resp.error.error.on_screen_error) {
          return throwError(resp);
        }
        if (resp.status === 0 || !navigator.onLine) {
          this.notificationService.isOpenNotification = true;
          this.notificationService.message.next([
            'ERROR.ERROR_ONLINE_TITLE',
            'ERROR.ERROR_ONLINE_CONTENT'
          ]);
        } else if (resp.status === HttpErrorCodeEnum.NOT_FOUND) {
          this.router.navigate(['static', 'not-found']);
        } else if (resp.status === HttpErrorCodeEnum.BAD_REQUEST) {
          if (this.errorModalService.isBlockRedirectErr) {
            return throwError(resp)
          }
          if (resp.error && resp.error.code) {
            switch (resp.error.code) {
              case ErrorCodeEnum.LINE_NOT_YET_FRIEND_WITH_OA:
                this.router.navigate(['static', 'error-line-add-friend']);
                break;
              case ErrorCodeEnum.ALREADY_SENT_GIFT:
                this.router.navigate(['static', 'already-sent-gift']);
                break;
              case ErrorCodeEnum.CAMPAIGN_CLOSE:
              case ErrorCodeEnum.CAMPAIGN_LIMIT:
                this.router.navigate(['static', 'closed']);
                break;
              case ErrorCodeEnum.USER_LIMIT:
                this.router.navigate(['static', 'user-reached-the-apply-limit']);
                break;
              case ErrorCodeEnum.USER_DAY_LIMIT:
                this.router.navigate(['static', 'user-reached-the-day-apply-limit']);
                break;
              case ErrorCodeEnum.RECEIPT_INVALID:
                return throwError(resp);
              default:
                this.router.navigate(['static', 'bad-request']);
                break;
            }
          } else {
            this.router.navigate(['static', 'bad-request']);
          }
        } else if (resp.status === HttpErrorCodeEnum.UNAUTHORIZED) {
          this.tokenService.clearToken();
          this.router.navigate(['static', 'expired']);
        } else if (resp.status === HttpErrorCodeEnum.CONFLICT) {
          if (resp.error && resp.error.error) {
            this.handleShowData(resp.error);
          }
        }
        return throwError(resp);
      })
    );
  }

  handleShowData(error: any): void {
    let secretPath = '';
    if (this.secretPathService.isSecretPath()) {
      secretPath = this.secretPathService.secretPath;
    }
    if(error?.error?.code === ErrorCodeEnum.INACTIVE_CODE) {
      this.router.navigate([secretPath, 'gift-inactivated'], {
        replaceUrl: true,
        state: {
          complete: true,
          error: error?.error?.message
        }
      });
    } else if (error.error.gift) {
        this.secretCodeService.saveGiftInformation(error.error.gift);
        this.router.navigate([secretPath, 'form', 'complete'], {
          replaceUrl: true,
          state: { complete: true }
        });
    } else if (error.error.lottery_result) {
      const lotteryResult: IWinPageState = error.error.lottery_result;
      if (lotteryResult.is_win) {
        this.router.navigate([secretPath, 'lottery', 'win'], {
          replaceUrl: true,
          state: {
            code: lotteryResult.url,
            layout: lotteryResult.lottery_win_layout,
          }
        });
      } else {
        this.router.navigate([secretPath, 'lottery', 'loss'], {
          replaceUrl: true,
          state: {
            allowLossPage: true
          }
        });
      }
    } else {
      this.router.navigate([secretPath, 'static', 'already-applied'], {
        replaceUrl: true,
        state: {complete: true}
      });
    }
  }
}
