import { Pipe, PipeTransform } from '@angular/core';
import { FieldTypeEnum, QuestionTypeEnum } from '@app-shared/enums';

@Pipe({
  name: 'fieldTypePipe'
})
export class FieldTypePipe implements PipeTransform {
  transform(value: number | string | unknown): string {
    let type = FieldTypeEnum.INPUT;
    switch (<number> value) {
      case QuestionTypeEnum.ADDRESS: {
        type = FieldTypeEnum.ADDRESS;
        break;
      }
      case QuestionTypeEnum.TEXTAREA: {
        type = FieldTypeEnum.TEXTAREA;
        break;
      }
      case QuestionTypeEnum.CHECKBOX: {
        type = FieldTypeEnum.CHECKBOX;
        break;
      }
      case QuestionTypeEnum.PULLDOWN: {
        type = FieldTypeEnum.SELECT;
        break;
      }
      case QuestionTypeEnum.RADIO: {
        type = FieldTypeEnum.RADIO;
        break;
      }
      case QuestionTypeEnum.SEGMENTED_CONTROL: {
        type = FieldTypeEnum.SEGMENTED;
        break;
      }
      case  QuestionTypeEnum.NAME:
      case QuestionTypeEnum.TEXTBOX: {
        type = FieldTypeEnum.MULTIPLE_INPUT;
        break;
      }
      case QuestionTypeEnum.UPLOAD_IMAGE:
      case QuestionTypeEnum.UPLOAD_FILE: {
        type = FieldTypeEnum.FILE;
        break;
      }
    }
    return type;
  }

}
