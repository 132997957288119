import { Component, OnInit, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DEFAULT_APP_LANGUAGE } from '@app-shared/config/app-language';
import { TranslateService } from '@ngx-translate/core';
import { CampaignTypeEnum } from '@user/core/interfaces'
import { CampaignDataService, SecretCodeService } from '@user/core/services';
import { MileageSecretCodeService } from '@user/core/services/mileage-secret-code.service'
import * as $ from 'jquery';
import { combineLatest } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { checkMobile } from '@user/shared';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    @Inject('env') private env: any,
    private campaignDataService: CampaignDataService,
    private secretCodeService: SecretCodeService,
    private router: Router,
    private translate: TranslateService,
    private mileageSecretCodeService: MileageSecretCodeService,
  ) {
    this.initLanguages();
  }

  title = 'dohbo-user-angular';

  ngOnInit(): void {
    this.campaignDataService.setBodyClass(DEFAULT_APP_LANGUAGE);
    combineLatest([
      this.campaignDataService.campaign,
      this.campaignDataService.applyCommonStyle(),
    ]).subscribe(([campaign]) => {
      if (campaign?.campaign_type === CampaignTypeEnum.MILEAGE) {
        this.mileageSecretCodeService.parseCodeFromLocation(campaign);
      }
      this.secretCodeService.parseCodeFromLocation(campaign);
      this.campaignDataService.setCampaignInitialized();
    });
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        tap(() => {
          $('[data-setting=close]')?.remove();
          $('[data-setting=complete]')?.remove();
          $('[data-setting=confirm]')?.remove();
          $('[data-setting=error]')?.remove();
          $('[data-setting=input]')?.remove();
          $('[data-setting=landing_page]')?.remove();
          $('[data-setting=password]')?.remove();
          $('[data-setting=serial]')?.remove();
          $('[data-setting=teaser]')?.remove();
          $('[data-setting=home]')?.remove();
          $('[data-setting=secret]')?.remove();
          $('[data-setting=lottery]')?.remove();
          $('[data-setting=lottery_win]')?.remove();
          $('[data-setting=lottery_loss]')?.remove();
          $('[data-setting=mileage]')?.remove();
          $('[data-setting=mileage_history]')?.remove();
        })
      )
      .subscribe((e: any) => {
        // if (typeof (window as any).gtag === 'function') {
        //   (window as any).gtag('event', 'page_view', {
        //     page_path: e.urlAfterRedirects
        //   });
        // }
      });
  }

  initLanguages(): void {
    this.translate.addLangs(['ja', 'vi']);
  }
}
