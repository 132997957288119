import { AfterViewInit, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { QuestionService } from '@user/core/services/question/question.service';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { DataTypeEnum, HttpErrorCodeEnum } from '@app-shared/enums';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-formly-field-file',
  template: `


    <label class="file btn-name"
           [attr.data-after-content]="to?.data?.options?.button_label">
      <input type="file" class="rq"
             [id]="$any(field?.key)"
             [name]="field?.key + '_' + to?.data?.randomNumber"
             [autocomplete]="'none'"
             [accept]="(to?.data?.options?.file_type_limit || to?.data?.options?.file_format) | fileType"
             (change)="uploadValue($event, 'uv-' +  field?.key)"
             [formlyAttributes]="field">
    </label>

    <div class="uploadValue">
      <i *ngIf="formControl.value"
         (click)="removeFile()"
         class="fa fa-trash cursor-pointer text-red-500 cursor-pointer mr-3" aria-hidden="true"></i>
      <input type="text" disabled [id]="'uv-'+ field?.key" value="{{'ERROR.FILE_NOT_SELECTED'| translate}}"/>
    </div>

    <lib-control-errors
      [nameForm]="to?.data?.type"
      [fieldName]="(field.templateOptions?.data.id == field?.key) ? field?.key : field.templateOptions?.data.id  + '.' +  field?.key"
    >
    </lib-control-errors>
  `,
})
export class FormlyFieldFileComponent extends FieldType implements AfterViewInit {

  constructor(
    private questionService: QuestionService,
    private translateService:TranslateService
  ) {
    super();
  }

  uploadValue(event: Event, id: any) {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    if (files.length) {
      this.formControl.setValue(files);
      const $id = document.getElementById(id) as HTMLInputElement;
      $id.value = files.length ? files[0].name : this.translateService.instant('ERROR.FILE_NOT_SELECTED');
      $id!.style.display = files.length ? 'block' : 'none';

      /*image ocr*/
      if (this.to.data.options.is_ocr) {
        let formData = new FormData;
        formData.append('image', files[0]);
        if (this.formControl.valid) {
          this.questionService.getOCR(formData, this.field.key)
            .pipe(
              catchError((err: HttpErrorResponse) => {
                if (err.status == HttpErrorCodeEnum.BAD_REQUEST) {
                  this.questionService.closeCampaign.next(err?.error?.error?.message);
                }
                if (err.status == HttpErrorCodeEnum.UNPROCESSABLE_CONTENT) {
                  this.formControl.setErrors({taken: true, message: err.error.errors[0].message[0]});
                }
                return throwError(err);
              })
            )
            .subscribe((resp) => {
              this.questionService.targetProduct.next({
                key: this.field.key,
                data: resp
              });
            });
        } else {
          this.questionService.targetProduct.next({
            key: this.field.key,
            data: null,
            is_reset: true
          });
        }
      }
    }
  }

  removeFile() {
    this.formControl.reset();
    this.resetInput();
  }

  resetInput() {
    const $idInput = document.getElementById(this.field?.key as string) as HTMLInputElement;
    $idInput.value = '';
    this.setTitleFile(this.translateService.instant('ERROR.FILE_NOT_SELECTED'));
    this.questionService.targetProduct.next({
      key: this.field.key,
      data: null,
      is_reset: true
    });
  }

  ngAfterViewInit(): void {
    let fileURl = '';
    if (this.model && this.model[this.field?.key as string]) {
      fileURl = typeof this.model[this.field?.key as string] == DataTypeEnum.STRING
        ? this.model[this.field?.key as string]
        : this.model[this.field?.key as string][this.field?.key as string];
    }

    this.setTitleFile(fileURl.substring(fileURl.lastIndexOf('/') + 1) || this.translateService.instant('ERROR.FILE_NOT_SELECTED'));
    this.formControl.valueChanges.subscribe(v => {
      if (!v) {
        this.resetInput();
      }
    });
  }

  setTitleFile(value: string) {
    const $id = document.getElementById('uv-' + this.field?.key) as HTMLInputElement;
    $id.value = value;
  }
}
