import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, NavigationExtras, Router, RouterStateSnapshot } from '@angular/router';
import { ICampaign } from '@user/core/interfaces';
import { SecretCodeService, SecretPathService } from '@user/core/services';
import { CampaignDataService } from '@user/core/services/campaign-data.service';
import { combineLatest, Observable, of } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';
import {UrlService} from "@user/core/services/url.service";

@Injectable({
  providedIn: 'root'
})
export class CampaignStatusGuard implements CanActivate {

  campaign!: ICampaign;

  constructor(
    private campaignDataService: CampaignDataService,
    private secretCodeService: SecretCodeService,
    private router: Router,
    private secretPathService: SecretPathService,
    private urlService: UrlService,
  ) {
  }

  redirectAndReturnFalse(urls = ['static', 'not-found'], extras = {}): Observable<boolean> {
    this.router.navigate(urls, extras);
    return of(false);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    return combineLatest([
      this.campaignDataService.campaignInitialized
    ]).pipe(
      first(),
      mergeMap(([campaign]) => {
        if (!campaign) {
          return this.redirectAndReturnFalse();
        }

        if (this.secretPathService.isSecretPath()) {
          return of (true)
        }

        this.campaign = campaign;
        if (campaign.settings.common.is_teaser && campaign?.settings.common.teaser_status) {
          const queryParameters = this.urlService.getQueryParameters();
          const hashParameters = this.urlService.getHashParameters();
          window.location.assign('/teaser' + queryParameters + hashParameters);
          return of(false);
        }

        if (campaign.settings.common.is_end && campaign?.settings.common.end_page_status) {
          return this.redirectAndReturnFalse(['end-page'])
        }

        return of(true);
      }),
    )
  }
}
