<!-- Modal_Window -->
<div class="shared-modal-wrapper" *ngIf="isShowModal">
  <a
    href="javascript:;"
    class="modal-overlay">
  </a>

  <div class="modal-window">
    <div class="modal-content text-modal-content">
      <div class="modal-cancel" [class.hidden-div]="isHidden" (click)="onCloseModal()"></div>

      <h2 class="text-message" *ngIf="message" [innerHTML]="message"></h2>

      <div class="btn-group">
        <input
        type="button"
        class="abtn"
        [value]="(messageClose ||'COMPLETE') | translate"
        (click)="onCloseModal()">
      </div>
    </div>
  </div>
</div>
<!-- //Modal_Window -->
