import { decodeEntity, encode } from 'html-entities';
import { DataTypeEnum } from '@app-shared/enums';

export function QuestionValueType(formData: any, id: any, value: any) {
  if (!isEmptyString(value) && Object.values(value).length) {
    formData.append(`question[${id}][value]`, htmlEntityEncode(value));
  }
  return formData;
}

export function QuestionMultipleValueType(formData: any, id: any, value: any) {
  const values = Object.values(value);
  if (!isEmptyArray(values)) {
    values.forEach((v: any) => {
      if(Array.isArray(v)) {
        v.forEach((_v: any) => {
          formData.append(`question[${id}][value][]`, htmlEntityEncode(_v?.trim()));
        })
      }else if (!isEmptyString(v)) {
        formData.append(`question[${id}][value][]`, htmlEntityEncode(v?.trim()));
      }
    });
  }
  return formData;
}

export function QuestionAddressType(formData: any, id: any, value: any) {
  const values = Object.values(value);
  if (!isEmptyArray(values)) {
    Object.keys(value).forEach((key: any) => {
      if (!isEmptyString(value[key])) {
        formData.append(
          `question[${id}][${key.replace(`_${id}`, '')}]${key.replace(`_${id}`, '') == 'address' ? '[]' : ''}`,
          htmlEntityEncode(value[key]));
      }
    });
  }

  return formData;
}


export function QuestionFileType(formData: any, id: any, value: any, type: string) {
  /*File*/
  const file = value[id]
    ? (typeof value[id] == DataTypeEnum.STRING ? value[id] : value[id][0])
    : '';
  if (file) {
    formData.append(`question[${id}][${type}]`, file);
    if (value['target_product'] && !Array.isArray(value['target_product'])) {
      value['target_product'].checkbox = value['target_product']?.checkbox || [];
      value['target_product'].input = (value['target_product']?.input_array?.map((v: any) => {
        return v.input;
      })) || [];
      const targetProduct = value['target_product']?.checkbox.concat(value['target_product']?.input).filter((v: string) => v);
      if (targetProduct.length) {
        targetProduct.forEach((target: string) => {
          formData.append(`question[${id}][target_product][]`, htmlEntityEncode(target));
        });
      }
    } else if (value['target_product'] && Array.isArray(value['target_product'])) {
      value['target_product'].forEach((target: string) => {
        formData.append(`question[${id}][target_product][]`, htmlEntityEncode(target));
      });
    }
  }

  return formData;
}


export function htmlEntityEncode(str: any): string {
  return encode(str);
}

export function htmlEntityDecode(str: string): string {
  return decodeEntity(str);
}

export function isEmptyString(value: any) {
  return (typeof value == 'undefined' || value == null || !value || value == 'null');
}

export function isEmptyArray(values: any) {
  return !values?.filter((v: never) => !isEmptyString(v))?.length;
}

export function isEmptyObject(values: any) {
  return !Object.values(values).filter((v: any) => !isEmptyString(v)).length;
}


