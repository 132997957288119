import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ComponentModule } from './component/component.module';
import { StaticPageModule } from './static-page/static-page.module';
import { FormlyModule } from '@ngx-formly/core';
import { FormlySharedModule } from './formly/formly-shared.module';
import { PipesModule } from './pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';

const SHARED_MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  ComponentModule,
  StaticPageModule,
  FormlyModule,
  FormlySharedModule,
  PipesModule,
  TranslateModule,
];

@NgModule({
  declarations: [
  ],
  imports: [
    ...SHARED_MODULES
  ],
  exports: [
    ...SHARED_MODULES,
  ]
})
export class AppSharedModule {
}
