import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { AddressEnum, DataTypeEnum } from '@app-shared/enums';

@Component({
  selector: 'app-formly-field-input',
  template: `
    <input style="display: none" name="address">
    <label>
      <input
        #inputBase
        [formControl]="formControl"
        [placeholder]="field.templateOptions.place_holder||field.templateOptions.placeholder || ''"
        [name]="field?.key + '_' + to?.data?.randomNumber"
        [autocomplete]="'none'"
        [formlyAttributes]="field"
        [className]="to?.class"
        [type]="isCheckType()">
    </label>
  `,
})
export class FormlyFieldInputComponent extends FieldType<FieldTypeConfig> {
  @ViewChild('inputBase') inputBase!: ElementRef;

  @HostListener('window:onChangeZipCode')
  onChangeZipCode(): void {
    if (typeof this.field?.key === DataTypeEnum.STRING && (this.field?.key as string)?.indexOf(AddressEnum.ADDRESS) > -1) {
      this.form.get(this?.field?.key as any)?.patchValue(this.inputBase?.nativeElement?.value || this.form.get(this?.field?.key as any)?.value);
    }
  }

  isCheckType(): string {
    if (typeof this.field?.key === DataTypeEnum.STRING && (this.field?.key as string)?.indexOf('zipcode_') > -1) {
      return 'tel';
    }
    return 'text';
  }
}
