import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { CampaignDataService } from '@user/core/services';
import { ISection } from '@user/core/interfaces';
import { IPageSetting } from '@user/core/interfaces/page-setting.interface';
import * as $ from 'jquery';
import { isLanguageJapanese } from '@app-shared/config/language';
import { ILotteryWinPage } from '@user/core/interfaces/lottery';
import { ActivatedRoute, Router } from '@angular/router';

interface IGiftInactivatedPageState {
  complete?: boolean;
  [key: string]: unknown;
}

@Component({
  selector: 'app-gift-inactivated-page',
  templateUrl: './gift-inactivated-page.component.html',
  styleUrls: ['./gift-inactivated-page.component.scss']
})

export class GiftInactivatedPageComponent implements OnInit, OnDestroy {
  isLanguageJapanese = isLanguageJapanese();
  isScreenDesktop = true;
  private pageKey = 'gift-inactivated';
  desktopImage?: string;
  mobileImage?: string;
  sections?: ISection[] = [];
  flgDefaultBlock: boolean = true;
  secretPath = '';

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isScreenDesktop = window.innerWidth > 767;
  }

  constructor(
    private campaignDataService: CampaignDataService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    this.onResize();
  }

  ngOnInit(): void {
    this.campaignDataService.campaignInitialized.subscribe((campaign) => {
      const giftInactivatedState: IGiftInactivatedPageState = history.state;
      this.secretPath = this.activatedRoute.snapshot.data.secretPath || '';
      if (!giftInactivatedState || (giftInactivatedState && !giftInactivatedState.complete)) {
        this.router.navigate([this.secretPath]);
        return;
      }
      this.applyStyle({
        head: campaign.settings.gift_inactivated_page?.gift_inactivated_head,
        before_body: campaign.settings.gift_inactivated_page?.gift_inactivated_before_close_body_tag,
        after_body: campaign.settings.gift_inactivated_page?.gift_inactivated_after_open_body_tag,
        desktop_image: campaign.settings.gift_inactivated_page?.gift_inactivated_desktop,
        mobile_image: campaign.settings.gift_inactivated_page?.gift_inactivated_mobile,
        sections: campaign.settings.gift_inactivated_page?.gift_inactivated_sections,
      });
    });
  }

  applyStyle(inactivatedCampaignPageSetting?: IPageSetting) {
    const commonBeforeBody = $('body div[data-setting=common_before_close_body_tag]');
    const commonAfterBody = $('body div[data-setting=common_after_open_body_tag]');

    const beforeBody = commonBeforeBody.length ? commonBeforeBody : $('body');
    const afterBody = commonAfterBody.length ? commonAfterBody : $('body');

    if (inactivatedCampaignPageSetting?.head) {
      $('head').append(`<span data-setting="${this.pageKey}">${inactivatedCampaignPageSetting?.head}</span>`);
    }

    if (inactivatedCampaignPageSetting?.after_body) {
      this.campaignDataService.appendElement(
        afterBody,
        `<div data-setting="${this.pageKey}">${inactivatedCampaignPageSetting?.after_body}</div>`,
        commonBeforeBody.length ? 'after' : 'before'
      );
    }

    if (inactivatedCampaignPageSetting?.before_body) {
      this.campaignDataService.appendElement(
        beforeBody,
        `<div data-setting="${this.pageKey}">${inactivatedCampaignPageSetting?.before_body}</div>`,
        commonBeforeBody.length ? 'before' : 'after'
      );
    }

    this.desktopImage = inactivatedCampaignPageSetting?.desktop_image;
    this.mobileImage = inactivatedCampaignPageSetting?.mobile_image;
    this.sections = inactivatedCampaignPageSetting?.sections;
    if (inactivatedCampaignPageSetting?.head || inactivatedCampaignPageSetting?.before_body
      || inactivatedCampaignPageSetting?.after_body || inactivatedCampaignPageSetting?.desktop_image
      || inactivatedCampaignPageSetting?.mobile_image || inactivatedCampaignPageSetting?.sections) {
      this.flgDefaultBlock = false;
    }
  }

  removeStyle() {
    const items = document.querySelectorAll(`[data-setting="${this.pageKey}"]`);
    items.forEach((item) => {
      item.remove();
    });
  }

  ngOnDestroy(): void {
    this.removeStyle();
  }

  handleError(e: any) {
    if (this.desktopImage === e.target.src || this.mobileImage === e.target.src) {
      e.target.src = '';
    }
  }
}
