export enum FieldTypeEnum {
  INPUT = 'input',
  ADDRESS = 'address',
  MULTIPLE_INPUT = 'multiple_input',
  FILE = 'file',
  TEXTAREA = 'textarea',
  CHECKBOX = 'checkbox',
  SELECT = 'select',
  RADIO = 'radio',
  SEGMENTED = 'segmented',
  TARGET_PRODUCT = 'target_product',
  FILE_OCR = 'file-ocr',
  EMAIL= 'email'
}
