<div id="already-applied">
  <div *ngIf="!customMessage; else elseBlock" class="db-error-section">
    <p>{{'ERROR.LOTTERY_ENDED'|translate}}</p>
  </div>
  <ng-template #elseBlock>
    <div [innerHTML]="(customMessage || '') | safeHtml" class="db-error-section">
      {{ customMessage }}
    </div>
  </ng-template>
</div>
