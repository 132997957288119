import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class ErrorModalService {
  public isBlockRedirectErr = false;
  public message: string[] = [];
  constructor() {
    // this.isOpenModal$ = this.isOpenModalSubject.asObservable();
  }
  set blockRedirect(value: boolean) {
    this.isBlockRedirectErr = value;
  }
  set messageToUser(value: string[]) {
    this.message = value
  }
  reset () {
    this.blockRedirect = false
    this.message = []
  }
}
