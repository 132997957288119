import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-field-textarea',
  template: `
    <textarea class="w-full"
              [name]="field?.key + '_' + to?.data?.randomNumber"
              [rows]="to.data.options?.default_height"
              [placeholder]="field.templateOptions.place_holder||field.templateOptions.placeholder || ''"
              [formControl]="formControl"
    ></textarea>
  `,
})
export class FormlyFieldTextareaComponent extends FieldType<FieldTypeConfig> {
}
