import { BlockButtonTypeEnum, BlockPageToLinkEnum, BlockTypeEnum } from 'projects/app-shared/src/lib/enums';

export enum BlockButtonStyleEnum {
  BUTTON = 'button',
  IMAGE = 'image',
}

export interface IBlockButton {
  block_type: BlockTypeEnum;
  id?: string;
  class?: string;
  label?: string;
  button_label?: string;
  background_color?: string;
  button_color?: string;
  url?: string;
  redirect_link?: string;
  style?: BlockButtonStyleEnum;
  url_image?: string;
  type?: string | BlockButtonTypeEnum;
  page_to_link?: BlockPageToLinkEnum;
}
