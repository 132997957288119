import {Injectable} from '@angular/core';
import {ApiBase} from '@user/core/services/api.service';
import {Observable} from 'rxjs';
import {apiEndpoints} from '@user/config/global-vars';
import {IResponse, IToken} from '@user/core/interfaces';
import { map } from 'rxjs/operators';
import { AuthService } from '@user/core/services';

type formIDPW = {
  user_id: string,
  user_pw: string,
  organization_code?: string
}

@Injectable({
  providedIn: 'root'
})
export class IDPWService {

  constructor(
    private apiBase: ApiBase,
    private authService: AuthService
  ) {
  }

  verify(params: formIDPW): Observable<IToken> {
    return this.apiBase.post<IResponse<IToken>>(apiEndpoints.id_pw, params).pipe(
      map(({data}) => {
        this.authService.currentUser = data.user;
        return data;
      }),
    );
  }
}
