import { NgModule } from '@angular/core';
import { FormlyModule } from '@ngx-formly/core';
import { CommonModule } from '@angular/common';

/*Constants*/
import { TypesComponent } from './constants/types.component';
import { WrapperComponent } from './constants/wrapper.component';

/*Wrapper*/
import { BoxSingleFormlyWrapperComponent } from './wrapper/box-single';
import { BoxMultipleFormlyWrapperComponent } from './wrapper/box-multiple';

/*Templates*/
import { FormlyFieldRadioComponent } from './templates/field-radio.component';
import { FormlyFieldCheckboxComponent } from './templates/field-checkbox.component';
import { FormlyFieldMultipleInputComponent } from './templates/field-multiple-input.component';
import { FormlyFieldSelectComponent } from './templates/field-select.component';
import { FormlyFieldTextareaComponent } from './templates/field-textarea.component';
import { FormlyFieldFileComponent } from './templates/field-file.component';
import { FormlyFieldSegmentedComponent } from './templates/field-segmented.component';
import { FormlyFieldTargetProductComponent } from './templates/file-ocr/field-target-product.component';
import { FormlyFieldFileOcrComponent } from './templates/file-ocr/field-file-ocr.component';
import { FormlyFieldInputComponent } from './templates/field-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ComponentModule } from '../component/component.module';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { PipesModule } from '../pipes/pipes.module';
import { FormlyFieldInputOCRComponent } from '@app-shared/formly/templates/file-ocr/field-input-ocr.component';
import { TranslateModule } from "@ngx-translate/core";
import { FormlyFieldEmailComponent } from './templates/field-mail.component';


const COMPONENTS_TYPE = [
  FormlyFieldInputComponent,
  FormlyFieldInputOCRComponent,
  FormlyFieldRadioComponent,
  FormlyFieldCheckboxComponent,
  FormlyFieldMultipleInputComponent,
  FormlyFieldSelectComponent,
  FormlyFieldTextareaComponent,
  FormlyFieldFileComponent,
  FormlyFieldSegmentedComponent,
  FormlyFieldTargetProductComponent,
  FormlyFieldFileOcrComponent,
  FormlyFieldEmailComponent
];

const COMPONENTS_WRAPPER = [
  BoxSingleFormlyWrapperComponent,
  BoxMultipleFormlyWrapperComponent
];

const MODULES_FORMLY = [
  FormlyModule.forChild({
    extras: {resetFieldOnHide: true, immutable: true},
    types: [
      ...TypesComponent
    ],
    wrappers: [
      ...WrapperComponent
    ]
  }),
];

@NgModule({
  declarations: [
    ...COMPONENTS_TYPE,
    ...COMPONENTS_WRAPPER
  ],
    imports: [
        CommonModule,
        ...MODULES_FORMLY,
        ReactiveFormsModule,
        ComponentModule,
        FormlySelectModule,
        PipesModule,
        TranslateModule,
    ],
  exports: [
    ...COMPONENTS_TYPE,
    ...COMPONENTS_WRAPPER
  ]
})
export class FormlySharedModule {
}
