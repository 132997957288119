import { Renderer2 } from '@angular/core';
import { FormGroup } from '@angular/forms';

export function renderExternalScript(src: string, renderer: Renderer2): HTMLScriptElement {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = src;
  script.async = true;
  script.defer = true;
  renderer.appendChild(document.body, script);
  return script;
}

export class FormHelper {
  /**
   * Marks all controls in a FormGroup as touched
   * ==> method markAllAsTouched : Available in angular 8 or 8+ version in FormGroup.
   * @param {FormGroup} formGroup - The form group to touch
   * @public
   */
  public markAllAsTouched(formGroup: FormGroup): void {
    (Object as any).values(formGroup.controls).forEach((control: any) => {
      control.markAsTouched();
      if (control.controls) {
        this.markAllAsTouched(control);
      }
    });
  }

  public convertFormData(form: any): any {
    delete form.privacy;
    const formData = new FormData();
    for (const property in form) {
      if (form[property] === '' || form[property] === 'null' || form[property] === undefined) {
        delete form[property];
      } else {
        if (!property.includes('question_id')) {
          formData.append(property, form[property]);
        } else if (form[property]) {
          formData.append(`qanda[${Number(property.slice(12)) - 1}][question_id]`, property.slice(12));
          if (Array.isArray(form[property])) {
            form[property].forEach((an: any) => {
              formData.append(`qanda[${Number(property.slice(12)) - 1}][answers][]`, an);
            });
          } else {
            formData.append(`qanda[${Number(property.slice(12)) - 1}][answers][]`, form[property]);
          }
        }
      }
    }
    return formData;
  }
}

export function dataURLtoBlob(dataUrl: string): Blob {
  const arr = dataUrl.split(',');
  const mime = arr?.[0].match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

export function checkMobile () {
  const isMobile = /iPad|iPhone|iPod|Android/i.test(window.navigator.userAgent)
  return !!isMobile
}

export function checkSafariPrivateMode () {
  const isSafari = window.navigator.userAgent.toLowerCase().indexOf('safari') > -1 && checkMobile()
  const isPrivate = !!(!localStorage.getItem('check_access_domain') && !sessionStorage.getItem('check_access_domain'))
  return !!(isSafari && isPrivate)
}
export function defaultBrowserExcludeSafari () {
  const isExcludeSafari = !(/safari/i).test(window.navigator.userAgent) && checkMobile()
  const isNewBrowser = !!(!localStorage.getItem('check_access_domain') && !sessionStorage.getItem('check_access_domain'))
  return !!(isExcludeSafari && isNewBrowser)
}
