import { Injectable } from '@angular/core';
import {ApiBase} from '@user/core/services/api.service';
import {ICampaign, IResponse} from '@user/core/interfaces';
import {apiEndpoints} from '@user/config/global-vars';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LinePayService {

  constructor(private apiBase: ApiBase) { }

  checkLinePayApply(applyId: string): Observable<boolean> {
    return this.apiBase.post<IResponse<null>>(`/user/line-pay/check/${applyId}`)
      .pipe(
        catchError(() => {
          return of(false);
        }),
        map((resp) => {
          return true;
        }),
      );
  }
}
