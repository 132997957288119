import {FormProtectionService} from '@user/core/interfaces';
import {StorageService} from '@user/core/services/storage.service';
import {Observable, of} from 'rxjs';
import {ApiBase} from '@user/core/services/api.service';
import {map} from 'rxjs/operators';
import {CustomParameterService} from '@user/core/services';

export abstract class BaseFormProtectionService implements FormProtectionService {
  abstract queryKey: string;
  abstract storeKey: string;
  abstract baseUrl: string;
  abstract parameterName: string;

  protected constructor(
    protected apiBase: ApiBase,
    protected storageService: StorageService,
    protected customParameterService: CustomParameterService,
  ) {}

  public saveToken(value: string): void {
    this.storageService.save(this.storeKey, value);
  }

  public getToken(): string | null {
    return this.storageService.get(this.storeKey);
  }

  public removeToken(): void {
    this.storageService.remove(this.storeKey);
  }

  public check(value: string, queryParams: {[key: string]: string} = {}, additionalBody = {}): Observable<boolean> {
    const body = {[this.parameterName]: value, ...additionalBody};
    let queryParamStr = '';
    if (Object.keys(queryParams).length > 0) {
      for (const key in queryParams) {
        if (queryParams.hasOwnProperty(key)) {
          queryParamStr += (queryParamStr === '' ? '?' : '&') + key + '=' + queryParams[key];
        }
      }
    }
    return this.apiBase.post(this.baseUrl + queryParamStr, body, {
      observe: 'response' as const,
    }).pipe(
      map(() => {
        return true;
      }),
    );
  }
}
