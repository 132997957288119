import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CampaignDataService } from '@user/core/services';
import { ICampaign } from '@user/core/interfaces';
import { TranslateService } from "@ngx-translate/core";
import { UserAuthMethodEnum } from '@user/core/enums';

@Component({
  selector: 'lib-setting-error-page',
  templateUrl: './setting-error-page.component.html',
  styleUrls: ['./setting-error-page.component.scss']
})
export class SettingErrorPageComponent implements OnInit {
  campaign: ICampaign | null = null;
  readonly UserAuthMethodEnum = UserAuthMethodEnum
  constructor(
    private titleService: Title,
    private campaignDataService: CampaignDataService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.translateService.instant('ERROR.REQUIRED_INFO_NOT_COMPLETED'));
    this.campaignDataService.campaignInitialized.subscribe((campaign) => {
      this.campaign = campaign;
    });
  }
}
