import { BoxSingleFormlyWrapperComponent } from '../wrapper/box-single';
import { BoxMultipleFormlyWrapperComponent } from '../wrapper/box-multiple';

export const WrapperComponent = [
  {
    name: 'box-single',
    component: BoxSingleFormlyWrapperComponent
  },
  {
    name: 'box-multiple',
    component: BoxMultipleFormlyWrapperComponent
  }
];
