import { Injectable } from '@angular/core';
import { ApiBase } from '@user/core/services/api.service';
import {BehaviorSubject, Observable, of} from 'rxjs';
import { apiEndpoints } from '@user/config/global-vars';
import {catchError, map} from 'rxjs/operators';
import {IResponse, IToken, IUser} from '@user/core/interfaces';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _currentUser = new BehaviorSubject<any>(null);
  currentUser$: Observable<any> = this._currentUser.asObservable();

  get currentUser(): IUser {
    return this._currentUser.value;
  }

  set currentUser(value: IUser) {
    this._currentUser.next(value);
  }

  constructor(
    private apiBase: ApiBase
  ) {
  }

  profile(): Observable<IUser> {
    return this.apiBase.get(apiEndpoints.profile).pipe(
      map(({data}: any) => {
        this.currentUser = data;
        return data;
      }),
    );
  }

  register(): Observable<IToken> {
    return this.apiBase.post<IResponse<IToken>>(apiEndpoints.register).pipe(
      map(({data}) => {
        this.currentUser = data.user;
        return data;
      }),
    );
  }
}
