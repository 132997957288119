<form [formGroup]="formGroup">
  <div class="auth_call_form--overseas">
    <!-- ID Setting Block -->
    <div class="auth_id_pw_form" [id]="(customizeSetting?.id_setting_block_id || '')" [class]="(customizeSetting?.id_setting_block_class || '')">
      <div class="display-html" [innerHTML]="(customizeSetting?.id_setting_above_question || '') | safeHtml"></div>
      <p>{{ customizeSetting?.id_setting_title }}</p>
      <p *ngIf="customizeSetting?.id_setting_sub_title">{{ customizeSetting?.id_setting_sub_title }}</p>
      <input class="input_id_pw" formControlName="user_id" type="text" [placeholder]="customizeSetting?.id_setting_placeholder || ''" >
      <p class="help" *ngIf="customizeSetting?.id_setting_annotation">{{ customizeSetting?.id_setting_annotation }}</p>
      <div class="display-html" [innerHTML]="(customizeSetting?.id_setting_below_question || '') | safeHtml"></div>
    </div>
    <!-- End ID Setting Block -->

    <!-- PW Setting Block -->
    <div class="auth_id_pw_form" [id]="(customizeSetting?.pw_setting_block_id || '')" [class]="(customizeSetting?.pw_setting_block_class || '')">
      <div class="display-html" [innerHTML]="(customizeSetting?.pw_setting_above_question || '') | safeHtml"></div>
      <p>{{ customizeSetting?.pw_setting_title }}</p>
      <p *ngIf="customizeSetting?.pw_setting_sub_title">{{ customizeSetting?.pw_setting_sub_title }}</p>
      <input class="input_id_pw"  formControlName="user_pw" type="password" [placeholder]="customizeSetting?.pw_setting_placeholder || ''">
      <p class="help" *ngIf="customizeSetting?.pw_setting_annotation">{{ customizeSetting?.pw_setting_annotation }}</p>
      <div class="display-html" [innerHTML]="(customizeSetting?.pw_setting_below_question || '') | safeHtml"></div>
    </div>
    <!-- End PW Setting Block -->

    <!-- OC Setting Block -->
    <div class="auth_id_pw_form" *ngIf="setting?.id_pw_method === IDPWMethod.THREE_KEYS" [id]="(customizeSetting?.oc_setting_block_id || '')" [class]="(customizeSetting?.oc_setting_block_class || '')">
      <div class="display-html" [innerHTML]="(customizeSetting?.oc_setting_above_question || '') | safeHtml"></div>
      <p>{{ customizeSetting?.oc_setting_title || ('ID_PW.ORGANIZATION_CODE' |translate) }}</p>
      <p *ngIf="customizeSetting?.pw_setting_sub_title">{{ customizeSetting?.oc_setting_sub_title }}</p>
      <input class="input_id_pw" type="text" formControlName="organization_code" [placeholder]="customizeSetting?.oc_setting_placeholder || ''">
      <p class="help" *ngIf="customizeSetting?.pw_setting_annotation">{{ customizeSetting?.oc_setting_annotation }}</p>
      <div class="display-html" [innerHTML]="(customizeSetting?.oc_setting_below_question || '') | safeHtml"></div>
    </div>
    <!--E nd OC Setting Block -->

    <input type="button"
           [disabled]="formGroup.invalid"
           (click)="submit()"
           [value]="('ID_PW.SUBMIT_FORM' | translate)"
    />
  </div>
</form>
<app-loader></app-loader>
<app-text-modal [isShowModal]="isShowModal" [messageToUser]="messageToUser" (closeModal)="isShowModal = false"></app-text-modal>
