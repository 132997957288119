<div id="wrapper">
  <ng-container *ngIf="flgDefaultBlock; else elseBlock">
    <div class="db-error-section">
      <p>{{'ERROR.INACTIVE_CODE_USED'|translate}}</p>
    </div>
  </ng-container>
  <ng-template #elseBlock>
    <div class="banner" *ngIf="mobileImage || desktopImage">
      <picture id="banner_img">
        <source media="(min-width:768px)" [srcset]="desktopImage">
        <source media="(max-width:767px)" [srcset]="mobileImage">
        <img
          (error)="handleError($event)"
          [src]="desktopImage || mobileImage">
      </picture>
    </div>

    <div class="wrap">
      <div id="gift-inactivated-page">
        <ng-template [ngIf]="'sections && sections.length > 0'">
          <ng-container *ngFor="let section of sections; let i = index">
            <lib-section [section]="section" [sectionId]="i.toString()">
            </lib-section>
          </ng-container>
        </ng-template>
      </div>
    </div>
  </ng-template>
</div>
