import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {CampaignDataService} from '@user/core/services';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent implements OnInit {
  customMessage: string | undefined = '';

  constructor(
    private titleService: Title,
    private campaignDataService: CampaignDataService,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Not found!');
    this.campaignDataService.campaignInitialized.subscribe((campaign) => {
      this.customMessage = campaign.settings?.error?.page_404_message;
    });
  }

}
