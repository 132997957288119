import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NameFileUrlPipe } from './name-file-url.pipe';
import { FieldTypePipe } from './field-type.pipe';
import { FileTypePipe } from './file-type.pipe';
import { SanitizerUrlPipe } from './sanitizer-url.pipe';
import { KeysPipe } from './keys.pipe';
import {SafeHtmlPipe} from './safe-html.pipe';
import { ReplaceNullWithTextPipe } from './replace-null-with-text.pipe';

const PIPES = [
  NameFileUrlPipe,
  FieldTypePipe,
  FileTypePipe,
  SanitizerUrlPipe,
  KeysPipe,
  SafeHtmlPipe,
  ReplaceNullWithTextPipe,
];

@NgModule({
  declarations: [
    ...PIPES,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ...PIPES,
  ]
})
export class PipesModule {
}
