import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpParams, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class ApiBase {
  baseDomain = '';
  apiUrl = '';

  constructor(@Inject('env') private env: any, private http: HttpClient) {
    this.baseDomain = window.location.origin;
    // this.baseDomain = this.env?.apiDomain;
    this.apiUrl = `${this.baseDomain}${this.env?.apiRoute}`;
  }

  getArrayBuffer(path: string, params: any | HttpParams = new HttpParams()): Observable<ArrayBuffer> {
    return this.http
      .get(`${this.apiUrl}${path}`, {params, responseType: 'arraybuffer'});
  }

  get<T>(path: string, params: any | HttpParams = new HttpParams()): Observable<T> {
    return this.http
      .get<T>(`${this.apiUrl}${path}`, {params});
  }

  put<T>(path: string, body: object = {}): Observable<T> {
    return this.http
      .put<T>(`${this.apiUrl}${path}`, body);
  }

  patch<T>(path: string, body: object = {}): Observable<T> {
    return this.http
      .patch<T>(`${this.apiUrl}${path}`, body);
  }

  post<T>(path: string, body: object = {}, options?: {}): Observable<T> {
    return this.http
      .post<T>(`${this.apiUrl}${path}`, body, {
        ...options,
        reportProgress: true
      });
  }

  delete<T>(path: string, params: any | HttpParams = new HttpParams()): Observable<T> {
    return this.http
      .delete<T>(`${this.apiUrl}${path}`, {params});
  }

  request<T>(request: HttpRequest<any>): Observable<HttpEvent<T>> {
    return this.http.request<T>(request);
  }
}
