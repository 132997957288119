import {DataTypeEnum} from '@app-shared/enums';
import {FormControl} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';

export function radioValidation(control: FormControl, field: FormlyFieldConfig | any): (Record<any, any> | string | null) {
  const options = field.templateOptions?.options;
  const currentValue = control?.value;

  if (typeof field?.key === DataTypeEnum.STRING && (field?.key as string)?.indexOf('prefecture') > -1) {
    if (!currentValue) {
      return {required: 'ERROR.RADIO_REQUIRED'};
    }
  }

  if (!currentValue && options.length == 1) {
    return {required: 'ERROR.RADIO_OPTION_LEN_1'};
  }

  if (!currentValue && options.length > 1) {
    return {required: 'ERROR.RADIO_OPTION_THAN_1'};
  }

  return null;
}
