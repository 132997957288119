export const ERROR_MESSAGE: Record<string, any> = {
  input: {
    required: (par: any) => `${par}`,
    pattern: () => `ERROR.INPUT_PATTERN`,
    minlength: (par: any) => `${par}`,
    maxlength: (par: any) => `${par}`,
    space: () => `ERROR.INPUT_SPACE`,
  },

  multiple_input: {
    required: (par: any) => `${par}`,
    pattern: () => `ERROR.MULTIPLE_INPUT_PATTERN`,
    minlength: (par: any) => `ERROR.INPUT_MIN_LEN`,
    maxlength: (par: any) => `${par}`,
    space: () => `ERROR.MULTIPLE_INPUT_SPACE`,
  },

  textarea: {
    required: (par: any) => `${par}`,
    pattern: () => `ERROR.TEXTAREA_PATTERN`,
    minlength: (par: any) => `${par}`,
    maxlength: (par: any) => `${par}`,
    space: () => `ERROR.TEXTAREA_SPACE`,
  },

  address: {
    required: () => `ERROR.ADDRESS_REQUIRED`,
    pattern: () => `ERROR.ADDRESS_PATTERN`,
    requiredTrue: () => `ERROR.ADDRESS_REQUIRED_TRUE`,
    minlength: (par: any) => `${par}`,
    maxlength: (par: any) => `${par}`,
    space: () => `ERROR.ADDRESS_SPACE`,
  },

  file: {
    required: () => `ERROR.FILE_REQUIRED`,
    pattern: () => `ERROR.FILE_REQUIRED`,
    fileSizeValidator: (par: any) => `${par}`,
    fileTypeValidator: (par: any) => `${par}`,
  },

  'file-ocr': {
    required: () => `ERROR.FILE_OCR_REQUIRED`,
    pattern: () => `ERROR.FILE_OCR_PATTERN`,
    required_ocr: () => `ERROR.FILE_OCR_REQUIRED_OCR`,
    pattern_ocr: () => `ERROR.FILE_OCR_PATTERN_OCR`,
    fileSizeValidator: (par: any) => `${par}`,
    fileTypeValidator: (par: any) => `${par}`,
    space: () => `ERROR.FILE_OCR_SPACE`,
  },

  checkbox: {
    required: (par: any) => `${par}`,
    pattern: () => `ERROR.CHECKBOX_PATTERN`,
    minlength: (par: any) => `${par}`,
    maxlength: (par: any) => `${par}`,
    space: () => `ERROR.CHECKBOX_SPACE`,
  },

  select: {
    required: (par: any) => `${par}`,
    space: () => `ERROR.SELECT_SPACE`,
  },

  radio: {
    required: (par: any) => `${par}`,
    space: () => `ERROR.RADIO_SPACE`,
  },

  segmented: {
    required: (par: any) => `${par}`,
    space: () => `ERROR.SEGMENTED_SPACE`,
  },

  quetion_regex: {
    required: () => `ERROR.QUESTION_REGEX_REQUIRED`,
    pattern: () => `ERROR.QUESTION_REGEX_PATTERN`,
  },
};
