import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { Prefectures } from 'projects/app-shared/src/lib/enums';
import { PROVINCES } from '@app-shared/config/provinces';
import { isLanguageVietNam } from '@app-shared/config/language';

@Component({
  selector: 'app-formly-field-select',
  template: `
    <select
      #selectBase
      class="{{!formControl.value ? 'placeholder' : ''}}"
      [name]="field?.key + '_' + to?.data?.randomNumber"
      [formControl]="formControl"
    >
      <option value="null" disabled selected hidden>{{to.placeholder}}</option>
      <ng-container *ngIf="to.type_address; else noAddress">
        <option *ngFor="let item of prefectureList; let idx = index;" [value]="provinceList[item]">
          {{ provinceList[item] }}
        </option>
      </ng-container>
      <ng-template #noAddress>
        <option
          *ngFor="let option of to?.options| formlySelectOptions:field| async"
          [value]="option.value">
          {{option.label}}
        </option>
      </ng-template>
    </select>
  `,
  styles: [
    `
      select.placeholder {
        color: gray;
      }

    `
  ]
})
export class FormlyFieldSelectComponent extends FieldType<FieldTypeConfig> implements OnInit {
  @ViewChild('selectBase') inputBase!: ElementRef;

  @HostListener('window:onChangeZipCode')
  onChangeZipCode(): void {
    if (this.to.type_address) {
      this.form.get(this?.field?.key as any)?.patchValue(this.inputBase.nativeElement.value);
    }
  }

  provinceList: any = Prefectures;
  prefectureList!: string[];

  ngOnInit(): void {
    if (isLanguageVietNam()) {
      this.provinceList = Object.assign({}, PROVINCES.map(item => {
        return item.name;
      }));
    }

    this.prefectureList = Object.keys(this.provinceList);
  }

}
