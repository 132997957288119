import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SecretPathService {
  public readonly secretPath: string;

  constructor() {
    this.secretPath = 'yM7SrnQg0p4l3N8z6qjc';
  }

  isSecretPath(): boolean {
    return window.location.pathname.startsWith('/' + this.secretPath);
  }
}
