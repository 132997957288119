import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalConfirmInterface } from '@user/core/interfaces/setting/modal-confirm-form.interface';

@Component({
  selector: 'app-message-custom-modal',
  templateUrl: './message-custom-modal.component.html',
  styleUrls: ['./message-custom-modal.component.scss']
})
export class MessageCustomModalComponent implements OnInit {
  @Input() isShowModal = false;
  @Input() message: any = '';
  @Input() isHidden = false;
  @Input() messageClose: any = 'COMPLETE';
  @Output() closeModal = new EventEmitter<boolean>();
  @Input() buttonSetting?: ModalConfirmInterface;

  constructor() { }

  ngOnInit(): void {
  }

  onCloseModal(): void {
    this.closeModal.emit(false);
  }

}
