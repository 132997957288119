import { Component, OnInit } from '@angular/core';
import { CampaignDataService } from '@user/core/services';

@Component({
  selector: 'app-already-applied-page',
  templateUrl: './lottery-coming-soon-page.component.html',
  styleUrls: ['./lottery-coming-soon-page.component.scss']
})
export class LotteryComingSoonPageComponent implements OnInit {
  customMessage: string | undefined = '';

  constructor(
    private campaignDataService: CampaignDataService,
  ) {
  }

  ngOnInit(): void {
    this.campaignDataService.campaignInitialized.subscribe((campaign) => {
      this.customMessage = campaign?.settings?.error?.page_awaiting_opening;
    });
  }

}
