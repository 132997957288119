<div class="section-wrapper" [id]="'section' + sectionId">
  <ng-container *ngFor="let block of section; let i = index">
    <!--Block Button-->
    <div *ngIf="block.block_type === BlockTypeEnum.BUTTON"
         class="block block-button"
         [id]="'block' + i"
    >
      <!--Style: Button-->
      <a [href]="block?.type === BlockButtonTypeEnum.NORMAL ? block?.redirect_link :
      block?.type === BlockButtonTypeEnum.SYSTEM ? (secretPath + buttonBlockSystemType[block?.page_to_link || 'no-link']) : block?.type === BlockButtonTypeEnum.CHIBI_JOB || block?.type === BlockButtonTypeEnum.OTHER_SITES ? block?.redirect_link : 'javascript:;'">
        <img
          *ngIf="block?.style === BlockButtonStyleEnum.IMAGE; else styleButton"
          [id]="(block?.id || '')"
          [class]="(block?.class || '')"
          [src]="block?.url_image"
          alt=""/>
        <ng-template #styleButton>
          <p [id]="(block?.id || '')"
             [style.color]="block?.button_color"
             [style.background-color]="block?.background_color"
             [class]="'btn ' + (block?.class || '')">{{ block?.label || '' }}</p>
        </ng-template>
      </a>
    </div>

    <!--Block Image-->
    <div *ngIf="block.block_type === BlockTypeEnum.IMAGE"
         class="block block-image"
         [id]="'block' + i"
         [style.background-color]="block.background_color"
    >
      <img
        *ngIf="block.url"
        [class]="block.class"
        [id]="(block.id || '')"
        [src]="(block.url || '')"
        [alt]="(block.alt || '')"
        (error)="block.url = ''"
      />

    </div>

    <!--Block HTML-->
    <div *ngIf="block.block_type === BlockTypeEnum.HTML"
         class="block block-html"
         [id]="'block' + i"
    >
      <div
        [class]="(block.class || '')"
        [id]="(block.id || '')"
        [style.background-color]="block.background_color"
      >
        <div [innerHTML]="(block.html || '') | safeHtml"></div>
      </div>
    </div>

    <!--Block Serial - Password -->
    <div *ngIf="block.block_type === BlockTypeEnum.SERIAL_PASSWORD"
         [id]="'block' + i"
         class="block block-serial-password">
      <div
        [id]="(block.id || '')"
        [class]="(block.class || '')">
        <h4>{{ block.label || defaultGateInputLabel }}</h4>
        <input type="text"
               [disabled]="isSubmitted"
               [placeholder]="block.label || '例：g7dsy2tg6'"
               [(ngModel)]="secretCode"
               (ngModelChange)="onSecretCodeChange()"
        />
        <p *ngIf="errorMessage.length > 0" class="error_s">
          {{ errorMessage }}
        </p>

        <!--button-->
        <input type="button"
               [disabled]="!secretCode || secretCode.length === 0 || secretCode.trim().length === 0 || isSubmitted"
               [style.background-color]="block.button_background_color"
               [style.color]="block.button_color"
               [value]=" block.button_title || defaultGateButtonTitle "
               (click)="onSubmitGateForm()"/>
      </div>
    </div>

    <!--Block Gift-->
    <div
      *ngIf="block.block_type === BlockTypeEnum.GIFT
        && giftCode
        && giftCode.length > 0"
      class="block block-gift"
    >
      <div [id]="(block.id || '')"
           [class]="'block-gift-content ' + (block.class || '')">
        <div [class.gift]="giftDisplayType !== GiftDisplayTypeEnum.BUTTON"
          [class.paypay]="!!giftAdditionalData?.contact_number"
          [class.received-gift]="giftDisplayType == GiftDisplayTypeEnum.URL && giftAdditionalData?.issued_at && giftType && giftType === GiftTypeEnum.ZALO_PAY"
        >
          <div [hidden]="giftDisplayType === GiftDisplayTypeEnum.BUTTON"
               class="gift__header ttl">{{ block.label || getTextLabel()|translate }}</div>
          <div class="gift_content">
            <ng-container [ngSwitch]="giftDisplayType">
              <!--TYPE = URL-->
              <a *ngSwitchCase="GiftDisplayTypeEnum.URL" [href]="giftCode">{{ giftCode }}</a>

              <!--TYPE = BUTTON-->
              <a *ngSwitchCase="GiftDisplayTypeEnum.BUTTON"
                 class="btn self-center"
                 [href]="giftCode"
                [class.disabled]="!!giftAdditionalData?.issued_at && giftType && giftType === GiftTypeEnum.ZALO_PAY"
              >
                {{ block.label || getTextLabel()|translate }}
              </a>

              <!--TYPE = TEXT-->
              <ng-container *ngSwitchCase="GiftDisplayTypeEnum.TEXT">
                {{ giftCode }}
              </ng-container>

              <!--TYPE = BARCODE-->
              <ng-container *ngSwitchCase="GiftDisplayTypeEnum.BARCODE">
                <ngx-barcode
                  [bc-value]="giftCode"
                  [bc-display-value]="true"
                ></ngx-barcode>
              </ng-container>

              <!--TYPE = QRCODE-->
              <ng-container *ngSwitchCase="GiftDisplayTypeEnum.QR_CODE">
                <ngx-qrcode
                  [elementType]="qrElementType"
                  [value]="giftCode"
                ></ngx-qrcode>
              </ng-container>


              <div *ngIf="giftAdditionalData && giftType && giftType === GiftTypeEnum.GPOINT" class="gift_content__additional_data self-center">
                <p *ngIf="giftAdditionalData?.exp_date">{{'EXPIRY_DATE'|translate}} : {{ giftAdditionalData?.exp_date }}</p>
                <p *ngIf="giftAdditionalData?.gnumber">管理番号 : {{ giftAdditionalData?.gnumber }}</p>
              </div>
            </ng-container>
          </div>
        </div>
        <div *ngIf="giftAdditionalData && giftType && giftType === GiftTypeEnum.PAYPAY" [class.paypay_content]="!!giftAdditionalData?.contact_number">
          <p *ngIf="giftAdditionalData?.charge_code">{{'GIFT_CODE'|translate}} : {{ giftAdditionalData?.charge_code }}</p>
          <p *ngIf="giftAdditionalData?.exp_date">{{'EXPIRY_DATE'|translate}} : {{ giftAdditionalData?.exp_date }}</p>
          <p *ngIf="giftAdditionalData?.contact_number">{{'CONTACT_NUMBER'|translate}} : {{ giftAdditionalData?.contact_number }}</p>
        </div>
        <div *ngIf="giftAdditionalData && giftType && giftType === GiftTypeEnum.ZALO_PAY" class="zalopay_content">
          <p *ngIf="giftAdditionalData?.issued_at">{{'SUCCESS_TRANSACT_ZALO_PAY_1'|translate}}</p>
          <p *ngIf="giftAdditionalData?.issued_at">{{'SUCCESS_TRANSACT_ZALO_PAY_2'|translate}}</p>
        </div>
      </div>
    </div>

    <!--Block Button Agree-->
    <div class="block block-line-pay-agree-button"
         [id]="'block' + i" *ngIf="block.block_type === BlockTypeEnum.LINE_PAY_AGREE_BUTTON"
    >
      <div [id]="(block.id || '')"
           [class]="(block?.class || '')">
        <input type="button"
               [style.color]="block.button_color"
               [style.background-color]="block.background_color"
               [value]="block.label || 'Agree'"
               (click)="onSubmitAgreeLinePayMemberProvide()"/>
      </div>
    </div>
    <div *ngIf="block.block_type === BlockTypeEnum.OSTIARIES_ASP_FORM"
         class="block block-call-auth"
         [id]="'block' + i">
      <lib-ostiaries-asp-form
          [customizeSetting]="block"
          [setting]="ostiariesAspSetting"
      ></lib-ostiaries-asp-form>
    </div>

    <!--Block Lottery Button-->
    <div *ngIf="block.block_type === BlockTypeEnum.LOTTERY"
         class="block block-lottery"
         [id]="'block' + i"
    >
      <!--Style: Button-->
      <a href="javascript:" (click)="onSubmitLottery()">
        <img
            *ngIf="block?.style === BlockButtonStyleEnum.IMAGE; else styleButton"
            [id]="(block?.id || '')"
            [class]="(block?.class || '')"
            [src]="block?.url_image"
            alt=""/>
        <ng-template #styleButton>
          <p [id]="(block?.id || '')"
             [style.color]="block?.button_color"
             [style.background-color]="block?.background_color"
             [class]="'btn ' + (block?.class || '')">{{ block?.label || 'LOTTERY.LOTTERY_BUTTON' | translate }}</p>
        </ng-template>
      </a>
    </div>

    <!--Block lottery result-->
    <div
        *ngIf="block.block_type === BlockTypeEnum.LOTTERY_RESULT
        && lotteryResult
        && lotteryResult.length > 0"
        class="block block-gift block-lottery-result"
    >
      <div [id]="(block.id || '')"
           [class]="'block-gift-content ' + (block.class || '')">
        <div [class.gift]="block.type !== LotteryResultBlockTypeEnum.BUTTON">
          <div [hidden]="block.type === LotteryResultBlockTypeEnum.BUTTON"
               class="gift__header ttl">{{ block.label || 'RECEIVING_GIFT' | translate }}</div>
          <div class="gift_content">
            <ng-container [ngSwitch]="block.type">
              <!--TYPE = URL-->
              <a *ngSwitchCase="LotteryResultBlockTypeEnum.URL" [href]="lotteryResult">{{ lotteryResult }}</a>

              <!--TYPE = BUTTON-->
              <a *ngSwitchCase="LotteryResultBlockTypeEnum.BUTTON"
                 class="btn self-center"
                 [href]="lotteryResult"
              >
                {{ block.label || 'RECEIVING_GIFT' | translate }}
              </a>

              <!--TYPE = TEXT-->
              <ng-container *ngSwitchCase="LotteryResultBlockTypeEnum.TEXT">
                {{ lotteryResult }}
              </ng-container>

              <!--TYPE = BARCODE-->
              <ng-container *ngSwitchCase="LotteryResultBlockTypeEnum.BARCODE">
                <ngx-barcode
                    [bc-value]="lotteryResult"
                    [bc-display-value]="true"
                ></ngx-barcode>
              </ng-container>

              <!--TYPE = QRCODE-->
              <ng-container *ngSwitchCase="LotteryResultBlockTypeEnum.QR_CODE">
                <ngx-qrcode
                    [elementType]="qrElementType"
                    [value]="lotteryResult"
                ></ngx-qrcode>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <!--Block Display Point-->
    <div
      *ngIf="block.block_type === BlockTypeEnum.DISPLAY_POINT"
      class="block block-display-point"
      [id]="'block' + i">
      <div
        [class]="(block.class || '')"
        [id]="(block.id || '')"
        [style.background-color]="block.background_color">
        <div [innerHTML]="(replaceTextInString(block.html) || '') | safeHtml"></div>
      </div>
    </div>

    <!--Block Request Point Serial-->
    <div
      *ngIf="block?.block_type === BlockTypeEnum.REQUEST_POINT_SERIAL &&
      campaign?.settings?.common?.mileage_give_point_method === GivePointMethodTypeEnum.SERIAL"
      class="block block-request-point request-serial"
      [id]="'block' + i">
      <div
        [id]="(block?.id || '')"
        [class]="(block?.class || '')">
        <h4>{{ block?.label || ('MILEAGE.LABEL_SERIAL_DEFAULT' | translate) }}</h4>

        <button type="button" class="btn btn-qr" [class.disabled-btn]="!block?.is_active" [disabled]="!block?.is_active" (click)="block?.is_active && enableScanner()">
          <span>{{ block?.qr_scan_label || ('MILEAGE.LABEL_QR_CODE_SCAN' | translate) }}</span>
        </button>
        <p *ngIf="errorCamera && errorCamera?.mediaStreamError && errorCamera?.mediaStreamError?.name" class="error_s">
          {{ errorMessagesCamera[errorCamera?.mediaStreamError?.name || 'Default'] | translate }}
        </p>

        <input
          [class.disabled-input]="!block?.is_active"
          type="text"
          [placeholder]="block?.placeholder || '0000000000'"
          [formControl]="requestCode"
        />

        <div *ngIf="requestCode.invalid && (requestCode.dirty || requestCode.touched)">
          <p class="error_s" *ngIf="requestCode.hasError('required'); else errorPattern">{{ 'MILEAGE.ERROR_FORM.REQUIRED' | translate }}</p>
          <ng-template #errorPattern>
            <p class="error_s" *ngIf="requestCode.hasError('pattern'); else errorMaxLength">{{ 'MILEAGE.ERROR_FORM.PATTERN_SPECIAL_CHARACTERS' | translate }}</p>
          </ng-template>
          <ng-template #errorMaxLength>
            <p class="error_s" *ngIf="requestCode.hasError('maxlength')">{{ 'MILEAGE.ERROR_FORM.MAX_LENGTH' | translate: { maxlength: 1000 } }}</p>
          </ng-template>
        </div>

        <!--button-->
        <input
          type="button"
          [disabled]="!block?.is_active || (requestCode.invalid || isSubmitted)"
          [style.background-color]="block?.button_background_color"
          [style.color]="block?.button_color"
          [value]="block?.button_title || ('MILEAGE.LABEL_REQUEST_POINT' | translate)"
          (click)="block?.is_active && onSubmitMileage(GivePointMethodTypeEnum.SERIAL)"/>
      </div>
    </div>

    <!--Block Request Point Receipt Declaration-->
    <div
      *ngIf="block?.block_type === BlockTypeEnum.REQUEST_POINT_RECEIPT_DECLARATION &&
      campaign?.settings?.common?.mileage_give_point_method === GivePointMethodTypeEnum.RECEIPT_DECLARATION"
      class="block block-request-point request-receipt-declaration"
      [id]="'block' + i">
      <div
        [id]="(block?.id || '')"
        [class]="(block?.class || '')">
        <h4>{{ block?.label || ('MILEAGE.LABEL_RECEIPT_DECLARATION_DEFAULT' | translate) }}</h4>

        <button type="button" class="btn btn-camera" [class.disabled-btn]="!block?.is_active" [disabled]="!block?.is_active" (click)="block?.is_active && enableCamera()">
          <span>{{ 'MILEAGE.LABEL_CAMERA_SCAN' | translate }}</span>
        </button>

        <p *ngIf="errorCamera && errorCamera?.mediaStreamError && errorCamera?.mediaStreamError?.name" class="error_s">
          {{ errorMessagesCamera[errorCamera?.mediaStreamError?.name || 'Default'] | translate }}
        </p>

        <div class="snapshot" *ngIf="cameraImage">
          <img alt="Receipt" [src]="cameraImage?.imageAsDataUrl" />
        </div>

        <p *ngIf="errorFile && errorFile?.message" class="error_s">
          {{ errorFile?.message | translate }}
        </p>

        <input
          [class.disabled-input]="!block?.is_active"
          type="tel"
          *ngIf="block?.request_point_field"
          [placeholder]="block?.placeholder || '00000'"
          [formControl]="requestPoints"
        />

        <div *ngIf="requestPoints.invalid && (requestPoints.dirty || requestPoints.touched)">
          <p class="error_s" *ngIf="requestPoints.hasError('required'); else errorPattern">{{ 'MILEAGE.ERROR_FORM.REQUIRED' | translate }}</p>
          <ng-template #errorPattern>
            <p class="error_s" *ngIf="requestPoints.hasError('pattern'); else errorMaxLength">{{ 'MILEAGE.ERROR_FORM.PATTERN_NUMBER_HALF_WIDTH' | translate }}</p>
          </ng-template>
          <ng-template #errorMaxLength>
            <p class="error_s" *ngIf="requestPoints.hasError('maxlength')">{{ 'MILEAGE.ERROR_FORM.MAX_LENGTH' | translate: { maxlength: 5 } }}</p>
          </ng-template>
        </div>

        <!--button-->
        <input
          type="button"
          [disabled]="!block?.is_active || requestPoints.invalid || isSubmitted || !cameraImage?.imageAsDataUrl"
          [style.background-color]="block?.button_background_color"
          [style.color]="block?.button_color"
          [value]="block?.button_title || ('MILEAGE.LABEL_REQUEST_POINT' | translate)"
          (click)="block?.is_active && onSubmitMileage(GivePointMethodTypeEnum.RECEIPT_DECLARATION)"/>
      </div>
    </div>

    <!--Block Request Point Receipt Approval-->
    <div
      *ngIf="block?.block_type === BlockTypeEnum.REQUEST_POINT_RECEIPT_APPROVAL &&
      campaign?.settings?.common?.mileage_give_point_method === GivePointMethodTypeEnum.RECEIPT_APPROVAL"
      class="block block-request-point request-receipt-approval"
      [id]="'block' + i">
      <div
        [id]="(block?.id || '')"
        [class]="(block?.class || '')">
        <h4>{{ block?.label || ('MILEAGE.LABEL_RECEIPT_APPROVAL_DEFAULT' | translate) }}</h4>

        <button type="button" class="btn btn-camera" [class.disabled-btn]="!block?.is_active" [disabled]="!block?.is_active" (click)="block?.is_active && enableCamera()">
          <span>{{ 'MILEAGE.LABEL_CAMERA_SCAN' | translate }}</span>
        </button>

        <p *ngIf="errorCamera && errorCamera?.mediaStreamError && errorCamera?.mediaStreamError?.name" class="error_s">
          {{ errorMessagesCamera[errorCamera?.mediaStreamError?.name || 'Default'] | translate }}
        </p>

        <div class="snapshot" *ngIf="cameraImage">
          <img alt="Receipt" [src]="cameraImage?.imageAsDataUrl" />
        </div>

        <p *ngIf="errorFile && errorFile?.message" class="error_s">
          {{ errorFile?.message | translate }}
        </p>

        <input
          [class.disabled-input]="!block?.is_active"
          type="tel"
          *ngIf="block?.request_point_field"
          [placeholder]="block?.placeholder ||
            (campaign?.settings?.common?.mileage_ocr_using ? ('MILEAGE.PHL_REQUEST_POINT' | translate) : '00000')"
          [formControl]="requestPoints"
        />

        <div *ngIf="requestPoints.invalid && (requestPoints.dirty || requestPoints.touched)">
          <p class="error_s" *ngIf="requestPoints.hasError('pattern'); else errorMaxLength">{{ 'MILEAGE.ERROR_FORM.PATTERN_NUMBER_HALF_WIDTH' | translate }}</p>
          <ng-template #errorMaxLength>
            <p class="error_s" *ngIf="requestPoints.hasError('maxlength')">{{ 'MILEAGE.ERROR_FORM.MAX_LENGTH' | translate: { maxlength: 5 } }}</p>
          </ng-template>
        </div>

        <!--button-->
        <input
          type="button"
          [disabled]="!block?.is_active || requestPoints.invalid || isSubmitted || !cameraImage?.imageAsDataUrl"
          [style.background-color]="block?.button_background_color"
          [style.color]="block?.button_color"
          [value]="block?.button_title || ('MILEAGE.LABEL_REQUEST_POINT' | translate)"
          (click)="block?.is_active && onSubmitMileage(GivePointMethodTypeEnum.RECEIPT_APPROVAL)"/>
      </div>
    </div>

    <!--Block Request Point JAN Code-->
    <div
      *ngIf="block?.block_type === BlockTypeEnum.REQUEST_POINT_JAN_CODE &&
      campaign?.settings?.common?.mileage_give_point_method === GivePointMethodTypeEnum.JAN_CODE"
      class="block block-request-point request-jan-code"
      [id]="'block' + i">
      <div
        [id]="(block?.id || '')"
        [class]="(block?.class || '')">
        <h4>{{ block?.label || ('MILEAGE.LABEL_JAN_CODE_DEFAULT' | translate) }}</h4>

        <button type="button" class="btn btn-barcode" [class.disabled-btn]="!block?.is_active" [disabled]="!block?.is_active" (click)="block?.is_active && enableScanner()">
          <span>{{ block?.barcode_scan_label || ('MILEAGE.LABEL_BARCODE_SCAN' | translate) }}</span>
        </button>

        <p *ngIf="errorCamera && errorCamera?.mediaStreamError && errorCamera?.mediaStreamError?.name" class="error_s">
          {{ errorMessagesCamera[errorCamera?.mediaStreamError?.name || 'Default'] | translate }}
        </p>

        <input
          [class.disabled-input]="!block?.is_active"
          type="text"
          [placeholder]="block?.placeholder || '0000000000'"
          [formControl]="requestCode"
        />

        <div *ngIf="requestCode.invalid && (requestCode.dirty || requestCode.touched)">
          <p class="error_s" *ngIf="requestCode.hasError('required'); else errorPattern">{{ 'MILEAGE.ERROR_FORM.REQUIRED' | translate }}</p>
          <ng-template #errorPattern>
            <p class="error_s" *ngIf="requestCode.hasError('pattern'); else errorMaxLength">{{ 'MILEAGE.ERROR_FORM.PATTERN_SPECIAL_CHARACTERS' | translate }}</p>
          </ng-template>
          <ng-template #errorMaxLength>
            <p class="error_s" *ngIf="requestCode.hasError('maxlength')">{{ 'MILEAGE.ERROR_FORM.MAX_LENGTH' | translate: { maxlength: 1000 } }}</p>
          </ng-template>
        </div>

        <!--button-->
        <input
          type="button"
          [disabled]="!block?.is_active || requestCode.invalid || isSubmitted"
          [style.background-color]="block?.button_background_color"
          [style.color]="block?.button_color"
          [value]="block?.button_title || ('MILEAGE.LABEL_REQUEST_POINT' | translate)"
          (click)="block?.is_active && onSubmitMileage(GivePointMethodTypeEnum.JAN_CODE)"/>
      </div>
    </div>

    <!--Block Display Course Block-->
    <div
      *ngIf="block?.block_type === BlockTypeEnum.DISPLAY_COURSE_BLOCK"
      class="block block-display-course"
      [id]="'block' + i">
      <!--Style: Button-->
      <a
        href="javascript:;"
        [ngClass]="(block?.exchange_points || 0) <= (profile?.available_points || 0) && ((block?.is_active && !block?.is_limit_exchange) || !getConditionDisabled(block)) ? '' : 'disabled'"
        (click)="((block?.exchange_points || 0) <= (profile?.available_points || 0) && ((block?.is_active && !block?.is_limit_exchange) || !getConditionDisabled(block))) && enableExchangeConfirm(block)">
        <img
          *ngIf="block?.style === BlockButtonStyleEnum.IMAGE; else styleButton"
          [id]="(block?.id || '')"
          [class]="(block?.class || '')"
          [src]="block?.url_image"
          alt="Image Path"/>
        <ng-template #styleButton>
          <p [id]="(block?.id || '')"
             [style.color]="block?.button_color"
             [style.background-color]="block?.background_color"
             [class]="'btn ' + (block?.class || '')"
             [ngClass]="(block?.exchange_points || 0) <= (profile?.available_points || 0) && ((block?.is_active && !block?.is_limit_exchange) || !getConditionDisabled(block)) ? '' : 'btn-disabled'">{{ block?.label }}</p>
        </ng-template>
      </a>
    </div>

    <!--Block Inactive-->
    <div
      *ngIf="block?.block_type === BlockTypeEnum.CODE_INACTIVATION"
      class="block block-inactivate"
      [id]="'block' + i">
      <!--Type: Button-->

      <ng-container *ngIf="block.type === BlockButtonStyleEnum.BUTTON; else inputCodeTemp">
        <!--Style: Button-->
        <a (click)="onInactiveCode(block?.type, false)">
          <img
            *ngIf="block?.style === BlockButtonStyleEnum.IMAGE; else styleButton"
            [id]="(block?.id || '')"
            [class]="(block?.class || '')"
            [src]="block?.url_image"
            alt=""/>
          <ng-template #styleButton>
            <p [id]="(block?.id || '')"
               [style.color]="block?.button_color"
               [style.background-color]="block?.background_color"
               [class]="'btn ' + (block?.class || '')">{{ block?.label || '' }}</p>
          </ng-template>
        </a>
      </ng-container>
      <!--Type: Input code-->
      <ng-template #inputCodeTemp>
        <div
          [id]="(block.id || '')"
          [class]="(block.class || '')">
          <input type="text"
                 [formControl]="requestInactivationCode"
                 [placeholder]="block.button_label"/>


          <p *ngIf="errorMessageInactiveCode.length > 0" class="error_s">
            {{ errorMessageInactiveCode }}
          </p>

          <!--button-->
          <input type="button"
                 [style.background-color]="block.background_color"
                 [style.color]="block.button_color"
                 (click)="onInactiveCode(block?.type, false)"
                 [value]="block.button_title"
                 [disabled]="requestInactivationCode.invalid"
          />
        </div>
      </ng-template>

      <app-message-confirm-modal
        [confirmText]="block.confirmation_text! || 'CODE_INACTIVATION.CONFIRMATION_TEXT_MODAL'"
        [labelApply]="block.agree_button_label! || 'CODE_INACTIVATION.AGREE_BUTTON_LABEL_MODAL'"
        [labelCancel]="block.cancel_button_label! || 'CODE_INACTIVATION.CANCEL_BUTTON_LABEL_MODAL'"
        [isShowModal]="isShowModalInactivate"
        (closeModal)="closeModal()"
        [isSubmitted]="isSubmitted"
        (applyModal)="onInactiveCode(block?.type, true)"
      >
      </app-message-confirm-modal>
    </div>

    <!--Block ID/PW-->
    <div *ngIf="block.block_type === BlockTypeEnum.ID_PW_FORM"
    class="block block-call-auth"
    [id]="'block' + i">
      <lib-id-pw-form
          [customizeSetting]="block"
          [setting]="idAuthSetting"
      ></lib-id-pw-form>
    </div>
  </ng-container>
</div>
