import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LotteryComingSoonPageComponent } from '@app-shared/static-page/lottery-coming-soon-page/lottery-coming-soon-page.component';
import { TranslateModule } from "@ngx-translate/core";
import { ComponentModule } from '../component/component.module';
import { PipesModule } from '../pipes/pipes.module';
import { AlreadyAppliedPageComponent } from './already-applied-page/already-applied-page.component';
import { AlreadySentGiftPageComponent } from './already-sent-gift-page/already-sent-gift-page.component';
import { BadRequestPageComponent } from './bad-request-page/bad-request-page.component';
import { CampaignClosedPageComponent } from './campaign-closed-page/campaign-closed-page.component';
import { CampaignReachedApplyLimitPageComponent } from './campaign-reached-apply-limit-page/campaign-reached-apply-limit-page.component';
import { ErrorLineAddFriendComponent } from './error-line-add-friend/error-line-add-friend.component';
import { ExpiredComponent } from './expired/expired.component';
import { LayoutComponent } from './layout/layout.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { SettingErrorPageComponent } from './setting-error-page/setting-error-page.component';
import { StaticPageRoutingModule } from './static-page-routing.module';
import { UserReachedApplyLimitPageComponent } from './user-reached-apply-limit-page/user-reached-apply-limit-page.component';
import { UserReachedDayApplyLimitPageComponent } from './user-reached-day-apply-limit-page/user-reached-day-apply-limit-page.component';
import { LotteryEndPageComponent } from './lottery-ended-page/lottery-end-page.component';
import { GiftInactivatedPageComponent } from './gift-inactivated-page/gift-inactivated-page.component';


@NgModule({
  declarations: [
    LayoutComponent,
    CampaignClosedPageComponent,
    NotFoundPageComponent,
    BadRequestPageComponent,
    AlreadyAppliedPageComponent,
    ExpiredComponent,
    ErrorLineAddFriendComponent,
    AlreadySentGiftPageComponent,
    SettingErrorPageComponent,
    CampaignReachedApplyLimitPageComponent,
    UserReachedApplyLimitPageComponent,
    UserReachedDayApplyLimitPageComponent,
    LotteryComingSoonPageComponent,
    LotteryEndPageComponent,
    GiftInactivatedPageComponent
  ],
  exports: [
    LayoutComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    StaticPageRoutingModule,
    ComponentModule,
    TranslateModule,
  ]
})
export class StaticPageModule {
}
