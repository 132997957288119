<div class="db-error-section">
  <p>{{'ERROR.REQUIRED_INFO_NOT_COMPLETED'|translate}}</p>
  <p *ngIf="campaign?.functions?.user_auth_method === false && campaign?.user_auth_method === UserAuthMethodEnum.SOCIAL_LOGIN">{{'ERROR.SETTING_INFO_SOCIAL_LOGIN'|translate}}</p>
  <p *ngIf="campaign?.functions?.user_auth_method === false && campaign?.user_auth_method === UserAuthMethodEnum.ID_PW">{{'ERROR.SETTING_INFO_ID_PW'|translate}}</p>
  <p *ngIf="campaign?.functions?.hitap_param_unique === false">{{'ERROR.SETTING_INFO_UNIQUE_CHECK_PARAM'|translate}}</p>
  <p *ngIf="campaign?.functions?.gift_type === false">{{'ERROR.SETTING_INFO_GIFT_PROVIDER'|translate}}</p>
  <p *ngIf="campaign?.functions?.send_mail === false">{{'ERROR.SETTING_INFO_EMAIL_NOTIFY_ADMIN'|translate}}</p>
  <p *ngIf="campaign?.functions?.send_mail_to_user === false">{{'ERROR.SETTING_INFO_EMAIL_NOTIFY_USER'|translate}}</p>
  <p *ngIf="campaign?.functions?.line_messaging === false">{{'ERROR.SETTING_INFO_LINE_NOTIFY_USER'|translate}}</p>
  <p *ngIf="campaign?.functions?.receipt_compare === false">{{'ERROR.SETTING_INFO_OCR'|translate}}</p>
</div>
