import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-field-radio',
  template: `
    <label *ngFor="let option of to?.options| formlySelectOptions:field| async">
      <p><input type="radio"
                [id]="'radio' + field.key + option?.value"
                [name]="to?.name + '_' + to?.data?.randomNumber"
                [autocomplete]="'none'"
                [formControl]="formControl"
                [formlyAttributes]="field"
                [value]="option.value"/><span></span>
      </p>
      <div [innerHTML]="option.label | safeHtml"></div>
      <p></p>
    </label>
  `,
})
export class FormlyFieldRadioComponent extends FieldType<FieldTypeConfig> {
}
