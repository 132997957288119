import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {combineLatest, Observable, of} from 'rxjs';
import {TokenService} from '@user/core/services/token.service';
import {AuthService} from '@user/core/services/auth.service';
import {CampaignDataService, SecretPathService} from '@user/core/services';
import {catchError, first, map, mergeMap, switchMap } from 'rxjs/operators';
import {AuthGuard} from '@user/core/guards/auth.guard';
import {SecretCodeVerifyGuard} from '@user/core/guards/secret-code-verify-guard.guard';
import {CampaignTypeEnum, FormUsingTypeEnum, ICampaign, IUser} from '@user/core/interfaces';

@Injectable({
  providedIn: 'root'
})
export class FormGuard implements CanActivate {
  constructor(
    private tokenService: TokenService,
    private authService: AuthService,
    private router: Router,
    private secretPathService: SecretPathService,
    private campaignDataService: CampaignDataService,
    private authGuard: AuthGuard,
    private secretCodeVerifyGuard: SecretCodeVerifyGuard,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return combineLatest([
      this.campaignDataService.campaignInitialized,
      this.authGuard.canActivate(route, state),
    ])
      .pipe(
        first(),
        mergeMap(([campaign, isAuthenticated]) => {
          let secretPath = '';

          if (this.secretPathService.isSecretPath()) {
            secretPath = this.secretPathService.secretPath;
          }

          // if user is authenticated and not in completion page then
          if (isAuthenticated && !state.url.includes('complete')) {
            return this.secretCodeVerifyGuard.canActivate(route, state)
              .pipe(
                switchMap(() => {
                  if (state.url === '/form' || state.url === '/' + secretPath + '/form') {
                    return this.authService.profile().pipe(
                      map((user) => {
                        return this.authNavigate(campaign, user) && !!user;
                      }),
                    );
                  }
                  return this.conditionNavigate(state, campaign);
                }),
              );
          }

          if (state.url === '/form' || state.url === '/' + secretPath + '/form') {
            if (this.tokenService.accessToken) {
              return this.authService
                .profile()
                .pipe(
                  map((user) => {
                    return this.authNavigate(campaign, user) && !!user;
                  }),
                  catchError(() => {
                    return of(false);
                  }),
                );
            }
          }

          return this.conditionNavigate(state, campaign);
        }),
      );
  }


  conditionNavigate(state: RouterStateSnapshot, campaign: ICampaign) {
    const data = this.router?.getCurrentNavigation()?.extras?.state;
    let secretPath = '';

    if (this.secretPathService.isSecretPath()) {
      secretPath = this.secretPathService.secretPath;
    }

    if (!data && (state.url.includes('confirm') || state.url.includes('complete'))) {
      return this.router.navigate([secretPath, 'form']);
    }

    if (data?.complete && state.url.includes('confirm')) {
      return this.router.navigate([secretPath, 'form']);
    }

    if (!data?.complete && state.url.includes('complete')) {
      return this.router.navigate([secretPath, 'form']);
    }
    if (campaign.campaign_type !== CampaignTypeEnum.NORMAL) {
      return this.authService
      .profile()
      .pipe(
        map((user) => {
          return this.authNavigate(campaign, user) && !!user;
        }),
        catchError(() => {
          return of(false);
        }))
    }
    return of(true);
  }

  authNavigate(campaign: ICampaign, user?: IUser): boolean {
    let secretPath = '';

    if (this.secretPathService.isSecretPath()) {
      secretPath = this.secretPathService.secretPath;
    }

    if (user
      && user.total_apply > 0) {
      switch (campaign.campaign_type) {
        case CampaignTypeEnum.LOTTERY:
          this.router.navigate([secretPath, 'lottery']);
          return false;
        case CampaignTypeEnum.MILEAGE:
          this.router.navigate([secretPath, 'mileage']);
          return false;
      }
    }

    return true;
  }

}
