import { Component, OnInit } from '@angular/core';
import {CampaignDataService} from '@user/core/services';

@Component({
  selector: 'lib-error-line-add-friend',
  templateUrl: './error-line-add-friend.component.html',
  styleUrls: ['./error-line-add-friend.component.scss']
})
export class ErrorLineAddFriendComponent implements OnInit {

  customMessage: string | undefined = '';
  constructor(
    private campaignDataService: CampaignDataService,
  ) { }

  ngOnInit(): void {
    this.campaignDataService.campaignInitialized.subscribe((campaign) => {
      this.customMessage = campaign.settings?.line?.line_error_page || '';
    });
  }

}
